import { Serializable, JsonProperty, deserialize, serialize } from 'typescript-json-serializer';
import { Driver } from './driver';
import { RecoverDetail } from './recover-detail';
import { RecoverPoint } from './recover-point';
import { Turn } from './turn';
import { User } from './user';
import { Vehicle } from './vehicle';

/**
 * Clase que administra los datos del recaudo.
 */
@Serializable()
export class Recover {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/** Id */
	@JsonProperty('id')
	public id: number;

	/** Número */
	@JsonProperty('numero')
	public number: string;

	/** Fecha */
	@JsonProperty('fecha')
	public date: string;

	/** Observación */
	@JsonProperty('observacion')
	public observation: string;

	/** Observación anulación */
	@JsonProperty('observacion_anu')
	public cancelObservation: string;

	/** Código del estado */
	@JsonProperty('cod_estado')
	public stateCode: string;

	/** Código del estado de los bonos */
	@JsonProperty('cod_estado_bonos')
	public stateVoucherCode: string;

	/** Acción a realizar */
	@JsonProperty('action')
	public action: string;

	/** Valor total */
	@JsonProperty('valor_total')
	public totalValue: number;

	/** Valor entrega */
	@JsonProperty('valor_entrega')
	public deliveryValue: number;

	/** Valor tasas de uso */
	@JsonProperty('valor_tasas_uso')
	public usageRatesValue: number;

	/** Valor peajes */
	@JsonProperty('valor_peajes')
	public tollsValue: number;

	/** Valor bonos */
	@JsonProperty('valor_bonos')
	public bondsValue: number;

	/** Valor saldo */
	@JsonProperty('valor_saldo')
	public balanceValue: number;

	/** Valor saldo a favor */
	@JsonProperty('valor_saldo_favor')
	public positiveBalanceValue: number;

	/** Conductor */
	@JsonProperty('conductor')
	public driver: Driver;

	/** Vehículo */
	@JsonProperty('vehiculo')
	public vehicle: Vehicle;

	/** Punto */
	@JsonProperty('punto')
	public point: RecoverPoint;

	/** Turno de registro */
	@JsonProperty('turno_reg')
	public createTurn: Turn;

	/** Turno de pago */
	@JsonProperty('turno_pag')
	public paymentTurn: Turn;

	/** Usuario anulación */
	@JsonProperty('usuario_anu')
	public cancelUser: User;

	/** Lista detalle */
	@JsonProperty({name: 'detalles', type: RecoverDetail})
	public details: Array<RecoverDetail>;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Convierte un mapa json en un objeto de la clase.
	 */
	public static fromJson(json: any): Recover {
		return deserialize(json, Recover);
	}

	/**
	 * Convierte el objeto de la clase en un mapa json.
	 */
	public static toJson(model: Recover): any {
		return serialize(model);
	}
}
