import { Serializable, JsonProperty, deserialize, serialize } from 'typescript-json-serializer';

/**
 * Clase que administra los datos del cliente.
 */
@Serializable()
export class Client {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/** Id */
	@JsonProperty('id')
	public id: number;

	/** Documento */
	@JsonProperty('documento')
	public document: string;

	/** Nombres */
	@JsonProperty('nombres')
	public names: string;

	/** Apellidos */
	@JsonProperty('apellidos')
	public surnames: string;

	/** Correo electrónico */
	@JsonProperty('correo_e')
	public email: string;

	/** Teléfono */
	@JsonProperty('telefono')
	public phone: string;

	/** Indica si esta activo */
	@JsonProperty('activo')
	public isActive = true;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Convierte un mapa json en un objeto de la clase.
	 */
	public static fromJson(json: any): Client {
		return deserialize(json, Client);
	}

	/**
	 * Convierte el objeto de la clase en un mapa json.
	 */
	public static toJson(model: Client): any {
		return serialize(model);
	}
}
