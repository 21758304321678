<nb-card status="success">
	<nb-card-header>
		<div class="row m-0">
			<h5 class="title col p-0">Cliente</h5>
			<nb-icon class="close col-auto p-0" icon="close" (click)="close(true)"></nb-icon>
		</div>
	</nb-card-header>
	<nb-card-body>
		<form name="clientForm" [formGroup]="clientForm" novalidate>
			<label class="label">N° Documento:</label>
			<div class="row m-0 mb-2">
				<div class="col-6 p-0">
					<input nbInput class="input" formControlName="document" type="text" fieldSize="medium" maxlength="10" [status]="getStatus('document', triedAction)" fullWidth>
					<ng-container *ngIf="isNotValid('document', triedAction)">
						<p class="caption" *ngIf="clientForm.get('document').hasError('required')">Documento es requerido!</p>
					</ng-container>
				</div>
				<div class="center-vertical" *ngIf="clientForm.get('id').value == 0">
					<nb-icon class="button-icon" icon="search" pack="font-awesome" (click)="searchClient()"></nb-icon>
				</div>
				<div class="center-vertical ml-4 p-0">
					<nb-toggle formControlName="isActive" labelPosition="start" status="success" *ngIf="clientForm.get('id').value > 0">Activo</nb-toggle>
				</div>
			</div>
			<div class="mb-2">
				<label class="label">Nombres:</label>
				<input nbInput class="input uppercase" formControlName="names" type="text" fieldSize="medium" [status]="getStatus('names', triedAction)" fullWidth>
				<ng-container *ngIf="isNotValid('names', triedAction)">
					<p class="caption" *ngIf="clientForm.get('names').hasError('required')">Nombres son requeridos!</p>
				</ng-container>
			</div>
			<div class="mb-2">
				<label class="label">Apellidos:</label>
				<input nbInput class="input uppercase" formControlName="surnames" type="text" fieldSize="medium" [status]="getStatus('surnames', triedAction)" fullWidth>
				<ng-container *ngIf="isNotValid('surnames', triedAction)">
					<p class="caption" *ngIf="clientForm.get('surnames').hasError('required')">Apellidos son requeridos!</p>
				</ng-container>
			</div>
			<div class="mb-2 p-0">
				<label class="label">Correo electrónico:</label>
				<input nbInput class="input" formControlName="email" type="email" fieldSize="medium" [status]="getStatus('email', triedAction)" fullWidth>
				<ng-container *ngIf="isNotValid('email', triedAction)">
					<p class="caption" *ngIf="clientForm.get('email').hasError('pattern')">Correo electrónico no válido!</p>
				</ng-container>
			</div>
			<div class="col-6 mb-3 p-0">
				<label class="label">Télefono:</label>
				<input nbInput class="input uppercase" formControlName="phone" type="text" fieldSize="medium" [status]="getStatus('phone', triedAction)" fullWidth>
			</div>
			<div class="row center-horizontal">
				<button nbButton class="button" type="submit" size="small" shape="round" status="primary" (click)='createClient()' *ngIf="clientForm.get('id').value == 0">
					<nb-icon icon="save"></nb-icon>Registrar
				</button>
				<button nbButton class="button" type="submit" size="small" shape="round" status="primary" (click)='updateClient()' *ngIf="clientForm.get('id').value > 0">
					<nb-icon icon="save"></nb-icon>Modificar
				</button>
			</div>
		</form>
	</nb-card-body>
</nb-card>
<ngx-loading [show]="loading"></ngx-loading>