import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Constructor
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	constructor() { }

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Propios
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	canActivate() {
		return true;
	}
}
