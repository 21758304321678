import { Serializable, JsonProperty, deserialize, serialize } from 'typescript-json-serializer';
import { BearingDetail } from './bearing-detail';
import { Company } from './company';
import { Route } from './route';
import { User } from './user';

/**
 * Clase que administra los datos del rodamiento.
 */
@Serializable()
export class Bearing {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/** Id */
	@JsonProperty('id')
	public id: number;

	/** Fecha */
	@JsonProperty('fecha')
	public date: string;

	/** Empresa */
	@JsonProperty('empresa')
	public company: Company;

	/** Ruta */
	@JsonProperty('ruta')
	public route: Route;

	/** Usuario */
	@JsonProperty('user')
	public user: User;

	/** Lista detalle */
	@JsonProperty({name: 'detalles', type: BearingDetail})
	public details: Array<BearingDetail>;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Convierte un mapa json en un objeto de la clase.
	 */
	public static fromJson(json: any): Bearing {
		return deserialize(json, Bearing);
	}

	/**
	 * Convierte el objeto de la clase en un mapa json.
	 */
	public static toJson(model: Bearing): any {
		return serialize(model);
	}
}
