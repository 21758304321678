import { Component, OnInit } from '@angular/core';
import { NbIconLibraries } from '@nebular/theme';

import { VersionService } from './services/version.service';

@Component({
	selector: 'sts-root',
	template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Constructor
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	constructor(private _iconLibraries: NbIconLibraries, private _versionService: VersionService) {
		this._iconLibraries.registerFontPack('font-awesome', {packClass: 'fa', iconClassPrefix: 'fa'});
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Propios
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	ngOnInit(): void {
		this._versionService.startVersionChecking('./version.json');
	}
}
