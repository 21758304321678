import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AuthComponent } from './pages/auth/auth.component';
import { HomeComponent } from './pages/home/home.component';

import { AuthGuard } from './guards/auth.guard';
import { HomeGuard } from './guards/home.guard';

const routes = [
	{
		path: '',
		component: HomeComponent,
		loadChildren: './pages/home/home.module#HomeModule',
		canActivate: [HomeGuard],
	},
	{
		path: 'auth',
		component: AuthComponent,
		loadChildren: './pages/auth/auth.module#AuthModule',
		canActivate: [AuthGuard],
	},
	{ path: '', redirectTo: '', pathMatch: 'full' },
	{ path: '**', redirectTo: '' },
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes)
	],
	exports: [
		RouterModule
	],
})
export class AppRoutingModule { }
