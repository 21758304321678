import { Component, Input, OnInit } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

import { DefaultEditor, ViewCell } from 'ng2-smart-table';

@Component({
	selector: 'input-editor',
	template: `
		<input nbInput class="input" type="tel" fieldSize="small" currencyMask maxlength="6" size="6" style='width:8em'
			   [(ngModel)]="cell.newValue" [name]="cell.getId()" [disabled]="!cell.isEditable()" 
			   (click)="onClick.emit($event)" (keydown.enter)="onEdited.emit($event)" (keydown.esc)="onStopEditing.emit()">
		`,
})
export class CurrencyInputEditorComponent extends DefaultEditor {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Constructor
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
  
	constructor() {
		super();
	}
}

@Component({
  template: `
    {{renderValue}}
  `,
})
export class CurrencyRenderComponent implements ViewCell, OnInit {
	renderValue: string;

	@Input() value: string | number;
	@Input() rowData: any;

	constructor(private _currencyPipe: CurrencyPipe) { }

	ngOnInit() {
		this.renderValue = this._toCurrency(+this.value);
	}

	private _toCurrency(value: number): string {
		return `$ ${this._currencyPipe.transform(value, '$', 'symbol-narrow', '1.0-0').replace('$', '').trim()}`;
	}
}