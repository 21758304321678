import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { User } from 'src/app/models/user';

import { NbToastrService } from '@nebular/theme';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
	templateUrl: 'dialog-change-password.component.html',
	styleUrls: ['dialog-change-password.component.scss'],
})
export class DialogChangePasswordComponent implements OnInit {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	public loading = false;
	public triedAction = false;
	public userForm: FormGroup;

	@Input() public user: User;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Constructor
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	constructor(private _formBuilder: FormBuilder, protected _dialogRef: NbDialogRef<DialogChangePasswordComponent>,
							private _toastrService: NbToastrService, private _authService: AuthenticationService) { }

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Funciones Propias
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	ngOnInit(): void {
		this._buildForm();
		this._fillForm();
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Principales
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Obtiene el estado del control del formulario.
	 * @param controlName Nombre del control.
	 */
	public getStatus(controlName: string, triedAction?: boolean): string {
		const control = this.userForm.get(controlName);
		if (!triedAction) {
			return control.dirty ? (control.invalid  ? 'danger' : 'basic') : 'basic';
		} else {
			return control.invalid ? 'danger' : 'basic';
		}
	}

	/**
   * Actualiza el modelo usuario.
   */
	public updateUser(): void {
		this.triedAction = true;
		if (!this.userForm.invalid) {
			this.loading = true;
			this._setDataModel();
			this._authService.changePassword(this.user).then(
				_response => {
					this.loading = false;
					this.close();
					this._handleMessage('Actualización exitosa!', 'info');
				},
				exception => this._handleFailure(exception)
			);
		}
	}

	/**
   * Cierra el diálogo.
   */
	public close(): void {
		this._dialogRef.close();
	}

	/**
	 * Indica si no es válido el control del formulario.
	 * @param controlName Nombre del control.
	 */
	public isNotValid(controlName: string, triedAction?: boolean): boolean {
		const control = this.userForm.get(controlName);
		if (!triedAction) {
			return control.dirty && control.invalid;
		} else {
			return control.invalid;
		}
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Secundarios
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Cambia los datos del modelo detalle.
	 */
	private _setDataModel(): void {
		this.user.oldPassword = this.userForm.get('oldPassword').value;
		this.user.newPassword = this.userForm.get('newPassword').value;
	}

	/**
	 * Construye el formulario.
	 */
	private _buildForm(): void {
		this.userForm = this._formBuilder.group({
			fullName: [{value: '', disabled: true}],
			oldPassword: ['', [Validators.required]],
			newPassword: ['', [Validators.required]],
			confirmPassword: ['', [Validators.required]],
		});
	}

	/**
	 * Llena el formulario.
	 */
 	private _fillForm(): void {
	 	this.userForm.patchValue({
			fullName: this.user.names + ' ' + this.user.surnames,
	 	});
	 }

	/**
	 * Maneja el mensaje que se va a mostrar.
	 * @param type Tipo de mensaje.
	 */
	private _handleMessage(message: any, type: any) {
		this._toastrService.show(message, 'Cambiar Contraseña', {
			status: type,
			duration: 3000,
		});
	}

	/**
	 * Maneja la excepción que se ha generado.
	 */
	private _handleFailure(exception: any): void {
		this.loading = false;
		this._toastrService.show(exception.error, 'Cambiar Contraseña', {
			status: 'danger',
			duration: 3000,
		});
	}
}
