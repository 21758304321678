import { Serializable, JsonProperty, deserialize, serialize } from 'typescript-json-serializer';
import { Client } from './client';
import { IssuanceDetail } from './issuance-detail';
import { RecoverPoint } from './recover-point';
import { Turn } from './turn';

/**
 * Clase que administra los datos de la emisión.
 */
@Serializable()
export class Issuance {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/** Id */
	@JsonProperty('id')
	public id: number;

	/** Número */
	@JsonProperty('numero')
	public number: string;

	/** Número de la tarjea */
	@JsonProperty('num_tarjeta')
	public cardNumber: number;

	/** Fecha y hora */
	@JsonProperty('fecha_hora')
	public dateTime: string;

	/** Fecha y hora de anulación */
	@JsonProperty('fecha_hora_anu')
	public cancelDateTime: string;

	/** Observación */
	@JsonProperty('observacion')
	public observation: string;

	/** Observación de anulación */
	@JsonProperty('observacion_anu')
	public cancelObservation: string;

	/** Valor efectivo */
	@JsonProperty('valor_efec')
	public cashValue: number;

	/** Valor total */
	@JsonProperty('valor_total')
	public totalValue: number;

	/** Cliente */
	@JsonProperty('cliente')
	public client: Client;

	/** Punto */
	@JsonProperty('punto')
	public point: RecoverPoint;

	/** Turno */
	@JsonProperty('turno')
	public turn: Turn;

	/** Turno de anulación */
	@JsonProperty('turno_anu')
	public cancelTurn: Turn;

	/** Lista detalle */
	@JsonProperty({name: 'detalles', type: IssuanceDetail})
	public details: Array<IssuanceDetail>;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Convierte un mapa json en un objeto de la clase.
	 */
	public static fromJson(json: any): Issuance {
		return deserialize(json, Issuance);
	}

	/**
	 * Convierte el objeto de la clase en un mapa json.
	 */
	public static toJson(model: Issuance): any {
		return serialize(model);
	}
}
