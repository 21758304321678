import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { LocalDataSource } from 'ng2-smart-table';
import { Fuel } from 'src/app/models/fuel';
import { FuelDetail } from 'src/app/models/fuel-detail';
import { Line } from 'src/app/models/line';
import { Turn } from 'src/app/models/turn';

import pdfMake from 'pdfmake/build/pdfmake';
import { PdfAssetsLoader } from 'pdfmake-utils';

import { CheckboxComponent } from 'src/app/components/checkbox/checkbox.component';
import { DialogDriverListComponent } from '../driver-list/dialog-driver-list.component';
import { DialogVehicleListComponent } from '../vehicle-list/dialog-vehicle-list.component';

import { NbToastrService } from '@nebular/theme';
import { DriverService } from 'src/app/services/driver.service';
import { FuelService } from 'src/app/services/fuel.service';
import { LineService } from 'src/app/services/line.service';
import { PrintService } from 'src/app/services/print.service';
import { ServiceStationService } from 'src/app/services/service-station.service';
import { VehicleService } from 'src/app/services/vehicle.service';

@Component({
	templateUrl: './dialog-fuel.component.html',
	styleUrls: ['./dialog-fuel.component.scss'],
})
export class DialogFuelComponent implements OnInit {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	private _fuel: Fuel;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	public loading = false;
	public triedAction = false;
	public settings: any;
	public source = new LocalDataSource();
	public fuelForm: FormGroup;
	public lines: Array<Line>;

	@Input() public turn: Turn;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Constructor
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	constructor(private _formBuilder: FormBuilder, private _datepipe: DatePipe, protected _dialogRef: NbDialogRef<DialogFuelComponent>,
				private _dialogService: NbDialogService, private _toastrService: NbToastrService,
				private _driverService: DriverService, private _fuelService: FuelService, private _lineService: LineService,
				private _printService: PrintService, private _stationService: ServiceStationService, private _vehicleService: VehicleService) {
		this._fuel = new Fuel();
		this._fuel.details = new Array();
		this.lines = new Array();
		this.settings = {
			columns: {
				date: {
					title: 'Fecha',
					type: 'text',
					width: '20%',
				},
				time: {
					title: 'Hora',
					type: 'text',
					width: '5%',
				},
				routeName: {
					title: 'Ruta',
					type: 'text',
				},
				active: {
					title: '',
					type: 'custom',
					width: '10%',
					renderComponent: CheckboxComponent
				},
			},
			actions: {
				columnTitle: '',
				position: 'right',
				add: false,
				edit: false,
				delete: false,
			},
			edit: {
				editButtonContent: '<i class="fa fa-pen fa-xs" title="Editar"></i>',
			},
			delete: {
				deleteButtonContent: '<i class="fa fa-trash fa-xs" title="Eliminar"></i>',
			},
			attr: {
				class: 'table table-bordered'
			},
			pager: {
				perPage: 5,
			},
			mode: 'external',
			noDataMessage: '',
			defaultStyle: false,
			hideSubHeader: true,
		};
		const assetsLoader = new PdfAssetsLoader();
		assetsLoader.registerFont({name: 'Courier', fileName: 'Courier', URL: 'assets/fonts/Courier.afm', styles: ['normal']});
		assetsLoader.registerFont({name: 'Courier', fileName: 'Courier-Bold', URL: 'assets/fonts/Courier-Bold.afm', styles: ['bold']});
		assetsLoader.load().then(() => {
			assetsLoader.configurePdfMake(pdfMake);
		}).catch((_error: any) => {
			assetsLoader.configurePdfMake(pdfMake);
		});
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Propios
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	ngOnInit(): void {
		this._buildForm();
		this.loading = true;
		this._stationService.findBy({
			point_id: this.turn.recoverPoint.id,
		}).then(
			model => {
				this.loading = false;
				this._fuel.serviceStation = model;
				this.fuelForm.patchValue({
					station: {
						id: this._fuel.serviceStation.id,
						name: this._fuel.serviceStation.name,
					},
				});
			},
			exception => this._handleFailure(exception)
		);
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Principales
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Obtiene el estado del control del formulario.
	 * @param controlName Nombre del control.
	 */
	public getStatus(controlName: string, triedAction?: boolean): string {
		const control = this.fuelForm.get(controlName);
		if (!triedAction) {
			return control.dirty ? (control.invalid  ? 'danger' : 'basic') : 'basic';
		} else {
			return control.invalid ? 'danger' : 'basic';
		}
	}

	/**
   * Crea el modelo combustible.
   */
	public createFuel(): void {
		this.triedAction = true;
		this._checkValidationCreate();
		if (!this.fuelForm.invalid) {
			this.loading = true;
			this._setDataModel();
			this.source.getAll().then(elements => {
				this._fuel.details = new Array();
				for (const element of elements) {
					const line = this.lines[this.lines.findIndex((model) => model.id === element.id)];
					if (element.active) {
						const detail = new FuelDetail();
						detail.line = line;
						this._fuel.details.push(detail);
					}
				}
				this._fuelService.create(this._fuel).then(
					response => {
						this._printService.printFuel(response, {
							point_id: this.turn.recoverPoint.id,
						}).then(
							reporte => {
								this.loading = false;
								this.triedAction = false;
								if (reporte.length > 0) {
									const documento = {
										content: {
											text: reporte,
											lineHeight: 1.5,
											preserveLeadingSpaces: true,
										},
										defaultStyle: {
											font: 'Courier',
											fontSize: 12,
											bold: true,
										},
										pageMargins: [10, 30, 0, 0],
									};
									pdfMake.createPdf(documento).open();
								}
								this.removeData();
								this._handleMessage('Registro exitoso!', 'info');
							},
							exception => this._handleFailure(exception),
						);
					},
					exception => this._handleFailure(exception),
				);
			});
		}
	}

	/**
   * Busca la lista modelo linea.
   */
	public searchLines(): void {
		this.triedAction = true;
		this.lines = new Array();
		this._checkValidationSearch();
		if (!this.fuelForm.invalid) {
			this.loading = true;
			this._lineService.findAllBy({
				vehicle_id: this._fuel.vehicle.id,
				type: 'COM',
				ruta: 'activo, exclude',
			}).then(
				models => {
					this.loading = false;
					this.lines = models;
					this.fuelForm.setControl('details', this._formBuilder.array([]));
					if (this.lines.length > 0) {
						this._loadData();
					} else {
						this._handleMessage('No existen lineas pendientes por combustible', 'info');
					}
				},
				exception => this._handleFailure(exception),
			);
		}
	}

	/**
   * Muestra el diálogo de la lista modelo conductor.
   */
	public showDialogDrivers(): void {
		this.loading = true;
		this._driverService.findActiveAll({
			this: 'activo, exclude',
		}).then(
			models => {
				this.loading = false;
				this._dialogService.open(DialogDriverListComponent, {
					context: {
						drivers: models,
					},
					closeOnEsc: true,
					closeOnBackdropClick: false,
				}).onClose.subscribe(model => {
					if (model != null) {
						this._fuel.driver = model;
						this.fuelForm.patchValue({
							driver: {
								id: this._fuel.driver.id,
								documentNumber: this._fuel.driver.documentNumber,
								fullName: this._fuel.driver.names + ' ' + this._fuel.driver.surnames,
							}
						});
					}
				});
			},
			exception => this._handleFailure(exception),
		);
	}

	/**
   * Muestra el diálogo de la lista modelo vehículo.
   */
	public showDialogVehicles(): void {
		this.loading = true;
		this._vehicleService.findActiveAll({
			this: 'activo, exclude',
			empresa: 'id, nombre, include',
		}).then(
			models => {
				this.loading = false;
				this._dialogService.open(DialogVehicleListComponent, {
					context: {
						vehicles: models,
					},
					closeOnEsc: true,
					closeOnBackdropClick: false,
				}).onClose.subscribe(model => {
					if (model != null) {
						this._fuel.vehicle = model;
						this._fuel.details = new Array();
						this.source.load(new Array());
						this.fuelForm.patchValue({
							vehicle: {
								id: this._fuel.vehicle.id,
								internalNumber: this._fuel.vehicle.internalNumber,
								plaque: this._fuel.vehicle.plaque,
								companyName: this._fuel.vehicle.company.name,
							}
						});
						this.fuelForm.setControl('details', this._formBuilder.array([]));
					}
				});
			},
			exception => this._handleFailure(exception),
		);
	}

	/**
   * Selecciona el modelo línea.
	 * @param row Fila seleccionada.
   */
	public selectLine(row: any): void {
		const index = this.lines.findIndex((model) => model.id === row.data.id);
		this.source.getAll().then(elements => {
			const line = this.lines[index];
			for (const element of elements) {
				if (line.id === element.id) {
					element.active = !element.active;
					this.source.update(element, element);
					break;
				}
			}
			this._fuel.details = new Array();
			for (const element of elements) {
				if (element.active) {
					const detail = new FuelDetail();
					this._fuel.details.push(detail);
				}
			}
			this.fuelForm.setControl('details', this._formBuilder.array(this._fuel.details.map(item => this._formBuilder.group(item))));
		});
	}

	/**
	 * Elimina los datos.
	 */
	public removeData(): void {
		this.triedAction = false;
		this.lines = new Array();
		this.fuelForm.get('driver.documentNumber').setValidators(null);
		this.fuelForm.get('driver.fullName').setValidators(null);
		this.fuelForm.get('observation').setValidators(null);
		this.fuelForm.get('details').setValidators(null);
		this.fuelForm.get('driver.documentNumber').updateValueAndValidity();
		this.fuelForm.get('driver.fullName').updateValueAndValidity();
		this.fuelForm.get('observation').updateValueAndValidity();
		this.fuelForm.get('details').updateValueAndValidity();
		this.fuelForm.updateValueAndValidity();
		this._clearForm();
	}

	/**
   * Cierra el diálogo.
   */
	public close(models?: Array<Line>): void {
		this._dialogRef.close(models);
	}

	/**
	 * Indica si no es válido el control del formulario.
	 * @param controlName Nombre del control.
	 */
	public isNotValid(controlName: string, triedAction?: boolean): boolean {
		const control = this.fuelForm.get(controlName);
		if (!triedAction) {
			return control.dirty && control.invalid;
		} else {
			return control.invalid;
		}
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Secundarios
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Cambia los datos del modelo ingreso.
	 */
	private _setDataModel(): void {
		this._fuel.observation = this.fuelForm.get('observation').value;
		this._fuel.turn = this.turn;
		if (this._fuel.observation != null) {
			if (this._fuel.observation.trim().length === 0) {
				this._fuel.observation = null;
			}
		}
	}

	/**
	 * Construye el formulario.
	 */
	private _buildForm(): void {
		this.fuelForm = this._formBuilder.group({
			id: 0,
			observation: ['', [Validators.required]],
			driver: this._formBuilder.group({
				id: 0,
				documentNumber: ['', [Validators.required]],
				fullName: ['', [Validators.required]],
			}),
			vehicle: this._formBuilder.group({
				id: 0,
				internalNumber: ['', [Validators.required]],
				plaque: ['', [Validators.required]],
				companyName: ['', [Validators.required]],
			}),
			station: this._formBuilder.group({
				id: 0,
				name: ['', [Validators.required]],
			}),
			details: this._formBuilder.array(
				[], Validators.required
			),
		});
	}

	/**
	 * Limpia el formulario.
	 */
	private _clearForm(): void {
		this._fuel.id = 0;
		this._fuel.driver = null;
		this._fuel.vehicle = null;
		this._fuel.details = new Array();
		this.source.load(new Array());
		this.fuelForm.patchValue({
			id: 0,
			observation: '',
			driver: {
				id: 0,
				documentNumber: '',
				fullName: '',
			},
			vehicle: {
				id: 0,
				internalNumber: '',
				plaque: '',
				companyName: '',
			},
		});
		this.fuelForm.setControl('details', this._formBuilder.array([]));
	}

	/**
	 * Carga la fuente de datos de la tabla.
	 */
	private _loadData(): void {
		const items = Array<any>();
		this.lines.forEach((model) => {
			items.push({
				id: model.id,
				date: this._datepipe.transform(model.date, 'MMM dd/yyyy').toUpperCase(),
				time: model.time,
				routeName: model.route.name,
				active: false,
			});
		});
		this.source.load(items);
	}

	/**
	 * Maneja el mensaje que se va a mostrar.
	 * @param type Tipo de mensaje.
	 */
	private _handleMessage(message: any, type: any) {
		this._toastrService.show(message, 'Combustible', {
			status: type,
			duration: 3000,
		});
	}

	/**
	 * Maneja la excepción que se ha generado.
	 */
	private _handleFailure(exception: any): void {
		this.loading = false;
		const mensaje = exception.status > 0 ? exception.error : exception.message;
		this._toastrService.show(mensaje, 'Combustible', {
			status: 'danger',
			duration: 5000,
		});
	}

	/**
	 * Verifica la validación del formulario.
	 */
	private _checkValidationSearch(): void {
		if (this._fuel.vehicle == null || this.lines.length === 0) {
			this.fuelForm.get('driver.documentNumber').setValidators(null);
			this.fuelForm.get('driver.fullName').setValidators(null);
			this.fuelForm.get('observation').setValidators(null);
			this.fuelForm.get('details').setValidators(null);
		}
		this.fuelForm.get('driver.documentNumber').updateValueAndValidity();
		this.fuelForm.get('driver.fullName').updateValueAndValidity();
		this.fuelForm.get('observation').updateValueAndValidity();
		this.fuelForm.get('details').updateValueAndValidity();
		this.fuelForm.updateValueAndValidity();
	}

	/**
	 * Verifica la validación del formulario.
	 */
	private _checkValidationCreate(): void {
		const inicial = new Date();
		const final = new Date();
		final.setHours(12);
		final.setMinutes(0);
		final.setSeconds(0);

		if (this._fuel.vehicle != null && this.lines.length > 0) {
			this.fuelForm.get('driver.documentNumber').setValidators([Validators.required]);
			this.fuelForm.get('driver.fullName').setValidators([Validators.required]);
			this.fuelForm.get('details').setValidators([Validators.required]);
			if (inicial.getTime() > final.getTime()) {
				this.fuelForm.get('observation').setValidators([Validators.required]);
			}
		}
		this.fuelForm.get('driver.documentNumber').updateValueAndValidity();
		this.fuelForm.get('driver.fullName').updateValueAndValidity();
		this.fuelForm.get('observation').updateValueAndValidity();
		this.fuelForm.get('details').updateValueAndValidity();
		this.fuelForm.updateValueAndValidity();
	}
}
