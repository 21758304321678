import { Component, OnInit, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
	templateUrl: 'dialog-message.component.html',
	styleUrls: ['dialog-message.component.scss'],
})
export class DialogMessageComponent implements OnInit {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	@Input() public title: string;
	@Input() public message: string;
	@Input() public textButton: string;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Constructor
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	constructor(protected _dialogRef: NbDialogRef<DialogMessageComponent>) { }

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Funciones Propias
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	ngOnInit(): void {
		if (this.textButton == null) {
			this.textButton = 'Aceptar';
		}
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Principales
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
   * Cierra el diálogo.
   */
	public close(): void {
		this._dialogRef.close();
	}
}
