export const APPLICATION_FORM = 'application/x-www-form-urlencoded';
export const APPLICATION_JSON = 'application/json; charset=utf-8';
export const AUTH_PREFIX = 'Bearer';
export const CLIENT_ID = 'conectasoft-web';
export const TEXT_PLAIN = 'text/plain; charset=utf-8';
//export const URL_HOST = 'http://localhost:8085/api/v1';
export const URL_HOST = 'http://api.conectasabana.com/conectasoft/api/v1';
//export const URL_HOST = 'http://35.243.166.133:8080/conectasoft/api/v1';
export const PREF_ACCESS_TOKEN = 'access_token';
export const PREF_REFRESH_TOKEN = 'refresh_token';
export const PREF_ROLE_ID = 'role_id';
export const PREF_USER_ID = 'user_id';

export enum TypeAction { CREATE, EDIT, VIEW }
