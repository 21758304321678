import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NbMenuItem } from '@nebular/theme';
import { User } from 'src/app/models/user';

import { PREF_USER_ID } from 'src/app';

import { takeWhile } from 'rxjs/operators';

import { DialogChangePasswordComponent } from 'src/app/dialogs/change-password/dialog-change-password.component';

import { NbDialogService, NbMenuService, NbSidebarService } from '@nebular/theme';
import { ApplicationService } from 'src/app/services/application.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UserService } from 'src/app/services/user.service';

@Component({
	selector: 'sts-header',
	styleUrls: ['./header.component.scss'],
	templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Variables
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	private _alive = true;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	public appName: string;
	public user: User;
	public userMenu: Array<NbMenuItem>;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Constructor
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	constructor(private _router: Router, private _dialogService: NbDialogService, private _menuService: NbMenuService, private _sidebarService: NbSidebarService,
							private _appService: ApplicationService, private _authService: AuthenticationService, private _userService: UserService) {
		this._appService.getAppData().then(
			response => this.appName = response.name,
		);
		if (this._authService.isAuthenticated()) {
			const idUser = +localStorage.getItem(PREF_USER_ID);
			this._userService.find(idUser, {
				rol: 'id, nombre, include',
			}).then(
				model => {
					this.user = model;
					if (!this.user.isActive) {
						this._logout();
					}
				}
			);
		}
		this.userMenu = Array();
		this.userMenu.push(
			{
				title: 'Cambiar Contraseña',
				icon: {icon: 'key', pack: 'font-awesome'},
				data: {id: 'change-password'},
			},
			{
				title: 'Cerrar Sesión',
				icon: {icon: 'door-open', pack: 'font-awesome'},
				data: {id: 'logout'},
			}
		);
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Propios
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	ngOnInit() {
		this._menuService.onItemClick()
			.pipe(takeWhile(() => this._alive))
			.subscribe(({item}) => {
				if (item.data.id === 'change-password') {
					this._changePassword();
				} else if (item.data.id === 'logout') {
					this._logout();
				}
			});
	}

	ngOnDestroy() {
		this._alive = false;
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Principales
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Alterna la barra lateral a compacta.
	 */
	public toggleSidebar(): boolean {
		this._sidebarService.toggle(true, 'sidebar');
		return false;
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Secundarios
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Cambia de contraseña del usuario.
	 */
	private _changePassword(): void {
		this._dialogService.open(DialogChangePasswordComponent, {
			context: {
				user: this.user,
			},
			closeOnEsc: true,
			closeOnBackdropClick: false,
		});
	}

	/**
	 * Cierra la sesión del usuario.
	 */
	private _logout(): void {
		this._authService.logout();
		this._router.navigate(['/auth/login'], {replaceUrl: true});
	}
}
