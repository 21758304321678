import { Serializable, JsonProperty, deserialize, serialize } from 'typescript-json-serializer';
import { Third } from './third';
import { User } from './user';

/**
 * Clase que administra los datos de la cuenta de cobro.
 */
@Serializable()
export class ChargeAccount {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/** Id */
	@JsonProperty('id')
	public id: number;

	/** Número */
	@JsonProperty('numero')
	public number: string;

	/** Número de pago */
	@JsonProperty('num_pago')
	public paymentNumber: string;

	/** Fecha y hora */
	@JsonProperty('fecha_hora')
	public dateTime: string;

	/** Fecha y hora de modificación */
	@JsonProperty('fecha_hora_mod')
	public modifyDateTime: string;

	/** Fecha y hora de autorización */
	@JsonProperty('fecha_hora_aut')
	public authDateTime: string;

	/** Código del concepto */
	@JsonProperty('cod_concepto')
	public conceptCode: string;

	/** Código del estado */
	@JsonProperty('cod_estado')
	public stateCode: string;

	/** Nombre del concepto */
	@JsonProperty('nom_concepto')
	public conceptName: string;

	/** Nombre del estado */
	@JsonProperty('nom_estado')
	public stateName: string;

	/** Descripción */
	@JsonProperty('descripcion')
	public description: string;

	/** Observación */
	@JsonProperty('observacion')
	public observation: string;

	/** Observación de autorización */
	@JsonProperty('observacion_aut')
	public authObservation: string;

	/** Valor */
	@JsonProperty('valor')
	public value: number;

	/** Valor adicional */
	@JsonProperty('valor_adic')
	public additionalValue: number;

	/** Valor descuento */
	@JsonProperty('valor_desc')
	public discountValue: number;

	/** Valor faltante */
	@JsonProperty('valor_falt')
	public missingValue: number;

	/** Valor total */
	private _totalValue: number;
	get totalValue(): number {
		this._totalValue = (this.value + this.additionalValue) - this.discountValue;
		return this._totalValue;
	}

	/** Valor total faltante */
	private _missingTotalValue: number;
	get missingTotalValue(): number {
		if (this.stateCode !== 'PAG') {
			this._missingTotalValue = this.missingValue > 0 ? this.missingValue : this.totalValue;
		} else {
			this._missingTotalValue = 0;
		}
		return this._missingTotalValue;
	}

	/** Indica si es pago parcial */
	public _isPayPartial: boolean;
	get isPayPartial(): boolean {
		return this.authDateTime != undefined && this.authDateTime != null;
	}

	/** Tercero */
	@JsonProperty('tercero')
	public third: Third;

	/** Usuario */
	@JsonProperty('usuario')
	public user: User;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Convierte un mapa json en un objeto de la clase.
	 */
	public static fromJson(json: any): ChargeAccount {
		return deserialize(json, ChargeAccount);
	}

	/**
	 * Convierte el objeto de la clase en un mapa json.
	 */
	public static toJson(model: ChargeAccount): any {
		return serialize(model);
	}
}
