import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Turn } from '../models/turn';

import { APPLICATION_JSON, URL_HOST } from '../../app';

import { AuthenticationService } from './authentication.service';

@Injectable({
	providedIn: 'root'
})
export class TurnService {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Variables
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	private _url = `${URL_HOST}`;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Constructor
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	constructor(private _httpClient: HttpClient, private _authService: AuthenticationService) { }

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Principales
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Busca el modelo turno según los parámetros.
	 * @param id Id del turno.
	 * @param params Parámetros de la solicitud.
	 */
	public find(id: number, params?: any): Promise<Turn> {
		return new Promise((resolve, reject) => {
			this._authService.getToken().then(
				token => {
					const httpHeaders = new HttpHeaders({
						'Content-Type': APPLICATION_JSON,
						Authorization: token
					});
					const httpParams = new HttpParams({
						fromObject: params
					});
					this._httpClient.get<any>(`${this._url}/turn/${id}`, {headers: httpHeaders, params: httpParams}).toPromise().then(
						response => resolve(Turn.fromJson(response)),
						exception => reject(exception)
					);
				},
				error => reject(error)
			);
		});
	}

	/**
	 * Busca el modelo turno.
	 * @param params Parámetros de la solicitud.
	 */
	public findBy(params?: any): Promise<Turn> {
		return new Promise((resolve, reject) => {
			this._authService.getToken().then(
				token => {
					const httpHeaders = new HttpHeaders({
						'Content-Type': APPLICATION_JSON,
						Authorization: token
					});
					const httpParams = new HttpParams({
						fromObject: params
					});
					this._httpClient.get<any>(`${this._url}/turn/by`, {headers: httpHeaders, params: httpParams}).toPromise().then(
						response => resolve(Turn.fromJson(response)),
						exception => reject(exception)
					);
				},
				error => reject(error)
			);
		});
	}

	/**
   * Crea el modelo turno.
   * @param model Modelo turno.
   */
	public create(model: Turn): Promise<number> {
		return new Promise((resolve, reject) => {
			this._authService.getToken().then(
				token => {
					const httpHeaders = new HttpHeaders({
						'Content-Type': APPLICATION_JSON,
						Authorization: token
					});
					this._httpClient.post<any>(`${this._url}/turn`, Turn.toJson(model), {headers: httpHeaders}).toPromise().then(
						response => resolve(response),
						exception => reject(exception)
					);
				},
				error => reject(error)
			);
		});
	}

	/**
   * Actualiza el modelo turno.
   * @param model Modelo turno.
   */
	public update(model: Turn): Promise<number> {
		return new Promise((resolve, reject) => {
			this._authService.getToken().then(
				token => {
					const httpHeaders = new HttpHeaders({
						'Content-Type': APPLICATION_JSON,
						Authorization: token
					});
					this._httpClient.put<any>(`${this._url}/turn`, Turn.toJson(model), {headers: httpHeaders}).toPromise().then(
						response => resolve(response),
						exception => reject(exception)
					);
				},
				error => reject(error)
			);
		});
	}
}
