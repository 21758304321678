import { Serializable, JsonProperty, deserialize, serialize } from 'typescript-json-serializer';
import { RecoverPoint } from './recover-point';

/**
 * Clase que administra los datos de la estación de servicio.
 */
@Serializable()
export class ServiceStation {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/** Id */
	@JsonProperty('id')
	public id: number;

	/** Nit */
	@JsonProperty('nit')
	public nit: string;

	/** Digito de verificación */
	@JsonProperty('digito')
	public digit: number;

	/** Nombre */
	@JsonProperty('nombre')
	public name: string;

	/** Valor tablero */
	@JsonProperty('valor_tablero')
	public boardValue: number;

	/** Valor descuento */
	@JsonProperty('valor_descuento')
	public discountValue: number;

	/** Indica si esta activo */
	@JsonProperty('activo')
	public isActive = true;

	/** Punto de recaudo */
	@JsonProperty('punto_rec')
	public recoverPoint: RecoverPoint;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Convierte un mapa json en un objeto de la clase.
	 */
	public static fromJson(json: any): ServiceStation {
		return deserialize(json, ServiceStation);
	}

	/**
	 * Convierte el objeto de la clase en un mapa json.
	 */
	public static toJson(model: ServiceStation): any {
		return serialize(model);
	}
}
