import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Module } from '../models/module';

import { APPLICATION_JSON, URL_HOST } from '../../app';

import { AuthenticationService } from './authentication.service';

@Injectable({
	providedIn: 'root'
})
export class ModuleService {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Variables
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	private _url = `${URL_HOST}`;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Constructor
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	constructor(private _httpClient: HttpClient, private _authService: AuthenticationService) { }

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Principales
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
   * Busca el modelo módulo según los parámetros.
	 * @param id Id del módulo.
   */
	public find(id: number, params?: any): Promise<Module> {
		return new Promise((resolve, reject) => {
			this._authService.getToken().then(
				token => {
					const httpHeaders = new HttpHeaders({
						'Content-Type': APPLICATION_JSON,
						Authorization: token,
					});
					const httpParams = new HttpParams({
						fromObject: params
					});
					this._httpClient.get<Array<any>>(`${this._url}/module/${id}`, {headers: httpHeaders, params: httpParams}).toPromise().then(
						response => resolve(Module.fromJson(response)),
						exception => reject(exception)
					);
				},
				error => reject(error)
			);
		});
	}

	/**
   * Busca la lista modelo módulo.
	 * @param params Parámetros de la solicitud.
   */
	public findAll(params?: any): Promise<Array<Module>> {
		return new Promise((resolve, reject) => {
			this._authService.getToken().then(
				token => {
					const httpHeaders = new HttpHeaders({
						'Content-Type': APPLICATION_JSON,
						Authorization: token,
					});
					const httpParams = new HttpParams({
						fromObject: params
					});
					this._httpClient.get<Array<any>>(`${this._url}/modules`, {headers: httpHeaders, params: httpParams}).toPromise().then(
						response => resolve(response.map(item => Module.fromJson(item))),
						exception => reject(exception)
					);
				},
				error => reject(error)
			);
		});
	}

	/**
   * Busca la lista modelo módulo.
	 * @param params Parámetros de la solicitud.
   */
	public findAllBy(params?: any): Promise<Array<Module>> {
		return new Promise((resolve, reject) => {
			this._authService.getToken().then(
				token => {
					const httpHeaders = new HttpHeaders({
						'Content-Type': APPLICATION_JSON,
						Authorization: token,
					});
					const httpParams = new HttpParams({
						fromObject: params
					});
					this._httpClient.get<Array<any>>(`${this._url}/modules/by`, {headers: httpHeaders, params: httpParams}).toPromise().then(
						response => resolve(response.map(item => Module.fromJson(item))),
						exception => reject(exception)
					);
				},
				error => reject(error)
			);
		});
	}

	/**
   * Actualiza el modelo módulo.
   * @param model Modelo módulo.
	 * @param params Parámetros de la solicitud.
   */
	public update(model: Module, params?: any): Promise<number> {
		return new Promise((resolve, reject) => {
			this._authService.getToken().then(
				token => {
					const httpHeaders = new HttpHeaders({
						'Content-Type': APPLICATION_JSON,
						Authorization: token
					});
					const httpParams = new HttpParams({
						fromObject: params
					});
					this._httpClient.put<any>(`${this._url}/module`, Module.toJson(model), {headers: httpHeaders, params: httpParams}).toPromise().then(
						response => resolve(response),
						exception => reject(exception)
					);
				},
				error => reject(error)
			);
		});
	}

	/**
   * Actualiza la lista modelo módulo.
   * @param models Lista modelo módulo.
	 * @param params Parámetros de la solicitud.
   */
	public updateAll(models: Array<Module>, params?: any): Promise<number> {
		return new Promise((resolve, reject) => {
			this._authService.getToken().then(
				token => {
					const httpHeaders = new HttpHeaders({
						'Content-Type': APPLICATION_JSON,
						Authorization: token
					});
					const httpParams = new HttpParams({
						fromObject: params
					});
					this._httpClient.put<any>(`${this._url}/modules`, models.map(model => Module.toJson(model)), {headers: httpHeaders, params: httpParams}).toPromise().then(
						response => resolve(response),
						exception => reject(exception)
					);
				},
				error => reject(error)
			);
		});
	}
}
