<nb-card status="success">
	<nb-card-header>
		<div class="row m-0">
			<h5 class="title col p-0">Ingreso</h5>
			<nb-icon class="close col-auto p-0" icon="close" (click)="close(true)"></nb-icon>
		</div>
	</nb-card-header>
	<nb-card-body>
		<form name="ingressForm" [formGroup]="ingressForm" novalidate>
			<div class="col-8 mb-2 p-0">
				<label class="label">Concepto:</label>
				<nb-select formControlName="conceptCode" [status]="getStatus('conceptCode', triedAction)" (selectedChange)="onSelectConcept()" fullWidth>
					<nb-option [value]="concept.code" [disabled]="isConceptNotValid(concept.code)" *ngFor="let concept of concepts">{{concept.name}}</nb-option>
				</nb-select>
				<ng-container *ngIf="isNotValid('conceptCode', triedAction)">
					<p class="caption" *ngIf="ingressForm.get('conceptCode').hasError('required')">Concepto es requerido!</p>
				</ng-container>
			</div>
			<div class="mb-2" formGroupName="third">
				<label class="label">Tercero:</label>
				<div class="row m-0">
					<div class="col-10 p-0">
						<input nbInput class="input uppercase" formControlName="fullName" type="text" fieldSize="medium" [attr.disabled]="true" fullWidth>
					</div>
					<div class="center-vertical" *ngIf="!ingressForm.get('accountNumber').value && ingressForm.get('conceptCode').value">
						<nb-icon class="button-icon" icon="search" pack="font-awesome" (click)="showDialogThirds()"></nb-icon>
					</div>
				</div>
				<ng-container *ngIf="isNotValid('third.fullName', triedAction)">
					<p class="caption" *ngIf="ingressForm.get('third.fullName').hasError('required')">Tercero es requerido!</p>
				</ng-container>
			</div>
			<div class="mb-2">
				<label class="label">Descripción:</label>
				<textarea nbInput class="textarea" formControlName="description" type="text" [status]="getStatus('description', triedAction)" fullWidth></textarea>
				<ng-container *ngIf="isNotValid('description', triedAction)">
					<p class="caption" *ngIf="ingressForm.get('description').hasError('required')">Descripción es requerida!</p>
				</ng-container>
			</div>
			<div class="col-5 mb-3 p-0" *ngIf="!ingressForm.get('isPayPartial').value">
				<label class="label">Valor:</label>
				<input nbInput class="input" formControlName="value" type="tel" fieldSize="medium" currencyMask fullWidth>
			</div>
			<div class="row m-0 mb-3" *ngIf="ingressForm.get('isPayPartial').value">
				<div class="col-6 p-0 pr-1">
					<label class="label">Valor a Pagar:</label>
					<input nbInput class="input" formControlName="payValue" type="tel" fieldSize="medium" currencyMask fullWidth (keyup)="calculateTotalValue()">
				</div>
				<div class="col-6 p-0 pl-1">
					<label class="label">Valor Pendiente:</label>
					<input nbInput class="input" formControlName="totalValue" type="tel" fieldSize="medium" currencyMask fullWidth>
				</div>
			</div>
			<div class="center-horizontal" *ngIf="ingressForm.get('id').value == 0">
				<button nbButton class="button" type="submit" size="small" shape="round" status="primary" (click)='createIngress()'>
					<nb-icon icon="save"></nb-icon>Registrar
				</button>
			</div>
		</form>
	</nb-card-body>
</nb-card>
<ngx-loading [show]="loading"></ngx-loading>