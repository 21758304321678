import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../services/authentication.service';

@Injectable({
	providedIn: 'root'
})
export class HomeGuard implements CanActivate {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Constructor
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	constructor(private _router: Router, private _authService: AuthenticationService) { }

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Propios
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	canActivate(_next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		if (!this._authService.isAuthenticated()) {
			this._router.navigate(['/auth/login'], {replaceUrl: true});
			return false;
		}
		if (this._authService.isTokenRefreshExpired()) {
			this._authService.logout();
			this._router.navigate(['/auth/login'], {replaceUrl: true});
			return false;
		}
		if (state.url !== '/') {
			this._router.navigate([''], {replaceUrl: true});
			return false;
		}
		return true;
	}
}
