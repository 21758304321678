import { Serializable, JsonProperty, deserialize, serialize } from 'typescript-json-serializer';
import { Company } from './company';

/**
 * Clase que administra los datos del conductor.
 */
@Serializable()
export class Driver {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/** Id */
	@JsonProperty('id')
	public id: number;

	/** Número de documento */
	@JsonProperty('num_documento')
	public documentNumber: string;

	/** Tipo de documento */
	@JsonProperty('tipo_documento')
	public documentType: string;

	/** Nombres */
	@JsonProperty('nombres')
	public names: string;

	/** Apellidos */
	@JsonProperty('apellidos')
	public surnames: string;

	/** Télefono */
	@JsonProperty('telefono')
	public phone: string;

	/** Indica si esta activo */
	@JsonProperty('activo')
	public isActive = true;

	/** Empresa */
	@JsonProperty('empresa')
	public company: Company;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Convierte un mapa json en un objeto de la clase.
	 */
	public static fromJson(json: any): Driver {
		return deserialize(json, Driver);
	}

	/**
	 * Convierte el objeto de la clase en un mapa json.
	 */
	public static toJson(model: Driver): any {
		return serialize(model);
	}
}
