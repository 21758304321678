<nb-card status="success">
	<nb-card-header>
		<div class="row m-0">
			<h5 class="title col p-0">Cambiar Contraseña</h5>
			<nb-icon class="close col-auto p-0" icon="close" (click)="close()"></nb-icon>
		</div>
	</nb-card-header>
	<nb-card-body>
		<form name="userForm" [formGroup]="userForm" novalidate>
			<div class="mb-2">
				<label class="label">Usuario:</label>
				<input nbInput class="input" formControlName="fullName" type="text" fieldSize="medium" shape="round" fullWidth>
			</div>
			<div class="mb-2">
				<label class="label">Contraseña Anterior:</label>
				<input nbInput class="input" formControlName="oldPassword" type="password" fieldSize="medium" shape="round" [status]="getStatus('oldPassword', triedAction)" fullWidth>
				<ng-container *ngIf="isNotValid('oldPassword', triedAction)">
					<p class="caption" *ngIf="userForm.get('oldPassword').hasError('required')">Contraseña anterior es requerida!</p>
				</ng-container>
			</div>
			<div class="mb-2">
				<label class="label">Contraseña Nueva:</label>
				<input nbInput class="input" formControlName="newPassword" type="password" fieldSize="medium" shape="round" [status]="getStatus('newPassword', triedAction)" fullWidth>
				<ng-container *ngIf="isNotValid('newPassword', triedAction)">
					<p class="caption" *ngIf="userForm.get('newPassword').hasError('required')">Contraseña nueva es requerida!</p>
				</ng-container>
			</div>
			<div class="mb-3">
				<label class="label">Confirmar Contraseña:</label>
				<input nbInput class="input" formControlName="confirmPassword" type="password" fieldSize="medium" shape="round" [status]="getStatus('confirmPassword', triedAction)" fullWidth>
				<ng-container *ngIf="isNotValid('confirmPassword', triedAction)">
					<p class="caption" *ngIf="userForm.get('confirmPassword').hasError('required')">Confirmación de la contraseña es requerida!</p>
				</ng-container>
				<ng-container *ngIf="!isNotValid('newPassword', triedAction) && !isNotValid('confirmPassword', triedAction)">
					<p class="caption" *ngIf="userForm.get('newPassword').value != userForm.get('confirmPassword').value">Las contraseñas no son iguales!</p>
				</ng-container>
			</div>
			<div class="center-horizontal">
				<button nbButton class="button" type="submit" size="small" shape="round" status="primary" (click)='updateUser()'>
					<nb-icon icon="save"></nb-icon>Cambiar
				</button>
			</div>
		</form>
	</nb-card-body>
</nb-card>
<ngx-loading [show]="loading"></ngx-loading>