import { Component } from '@angular/core';
import { DefaultEditor } from 'ng2-smart-table';

@Component({
	selector: 'input-editor',
	template: `
		<input nbInput class="input" type="tel" fieldSize="small" maxlength="3" size="3" style='width:5em'
			   [(ngModel)]="cell.newValue" [name]="cell.getId()" [disabled]="!cell.isEditable()" 
			   (click)="onClick.emit($event)" (keypress)="numberOnly($event)"
			   (keydown.enter)="onEdited.emit($event)" (keydown.esc)="onStopEditing.emit()">
		`,
})
export class AmountInputEditorComponent extends DefaultEditor {

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Constructor
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
  
	constructor() {
		super();
	}

	numberOnly(event) {
		if (event.key.length === 1 && (event.which < 48 || event.which > 57)) {
            event.preventDefault();
        }
	}
}
