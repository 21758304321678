import { Serializable, JsonProperty, deserialize, serialize } from 'typescript-json-serializer';
import { RecoverPoint } from './recover-point';
import { User } from './user';

/**
 * Clase que administra los datos del turno.
 */
@Serializable()
export class Turn {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/** Id */
	@JsonProperty('id')
	public id: number;

	/** Fecha y hora de apertura */
	@JsonProperty('fecha_hora_ape')
	public openDateTime: string;

	/** Fecha y hora de cierre */
	@JsonProperty('fecha_hora_cie')
	public closeDateTime: string;

	/** Cantidad de tarjetas de apertura */
	@JsonProperty('cant_tarj_ape')
	public openCardsAmount: number;

	/** Cantidad de tarjetas de cierre */
	@JsonProperty('cant_tarj_cie')
	public closeCardsAmount: number;

	/** Valor base */
	@JsonProperty('valor_base')
	public baseValue: number;

	/** Valor efectivo */
	@JsonProperty('valor_efectivo')
	public effectiveValue: number;

	/** Valor saldo */
	@JsonProperty('valor_saldo')
	public balanceValue: number;

	/** Indica si se intentó el cierre */
	@JsonProperty('intento_cie')
	public isAttemptedClosure: boolean;

	/** Punto de recaudo */
	@JsonProperty('punto_rec')
	public recoverPoint: RecoverPoint;

	/** Usuario */
	@JsonProperty('usuario')
	public user: User;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Convierte un mapa json en un objeto de la clase.
	 */
	public static fromJson(json: any): Turn {
		return deserialize(json, Turn);
	}

	/**
	 * Convierte el objeto de la clase en un mapa json.
	 */
	public static toJson(model: Turn): any {
		return serialize(model);
	}
}
