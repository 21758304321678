import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { Recover } from 'src/app/models/recover';

@Component({
	templateUrl: 'dialog-recover.component.html',
	styleUrls: ['dialog-recover.component.scss'],
})
export class DialogRecoverComponent implements OnInit {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	public loading = false;
	public triedAction = true;
	public response = '';
	public recoverForm: FormGroup;

	@Input() public recover: Recover;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Constructor
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	constructor(private _formBuilder: FormBuilder, protected _dialogRef: NbDialogRef<DialogRecoverComponent>) { }

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Funciones Propias
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	ngOnInit(): void {
		this._buildForm();
		this._fillForm();
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Principales
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Obtiene el estado del control del formulario.
	 * @param controlName Nombre del control.
	 */
	public getStatus(controlName: string, triedAction?: boolean): string {
		const control = this.recoverForm.get(controlName);
		if (!triedAction) {
			return control.dirty ? (control.invalid  ? 'danger' : 'basic') : 'basic';
		} else {
			return control.invalid ? 'danger' : 'basic';
		}
	}

	/**
   * Genera el modelo recaudo.
   */
	public generateRecover(): void {
		this._setDataModel();
		this.calculateBalanceValue();
		if (!this.recoverForm.invalid) {
			this.close(this.recover);
		}
	}

	/**
	 * Calcula el valor total a pagar.
	 */
	public calculateTotalValuePay(): void {
		const usageRatesValue = this.recoverForm.get('usageRatesValue').value;
		const tollsValue = this.recoverForm.get('tollsValue').value;
		let totalValuePay = this.recover.totalValue - this.recover.positiveBalanceValue - this.recover.bondsValue - usageRatesValue - tollsValue;
		this.recoverForm.patchValue({
			totalValuePay: totalValuePay,
		});
		this.calculateBalanceValue();
		this._checkValidation();
	}

	/**
	 * Calcula el valor del saldo pendiente.
	 */
	 public calculateBalanceValue(): void {
		const totalValuePay = this.recoverForm.get('totalValuePay').value;
		const deliveryValue = this.recoverForm.get('deliveryValue').value;
		this.recover.balanceValue = totalValuePay - deliveryValue;
		this.recoverForm.patchValue({
			balanceValue: this.recover.balanceValue,
		});
		this._checkValidation();
	}

	/**
	 * Cierra el diálogo.
	 */
	public close(model?: Recover): void {
		this._dialogRef.close(model);
	}

	/**
	 * Indica si no es válido el control del formulario.
	 * @param controlName Nombre del control.
	 */
	public isNotValid(controlName: string, triedAction?: boolean): boolean {
		const control = this.recoverForm.get(controlName);
		if (!triedAction) {
			return control.dirty && control.invalid;
		} else {
			return control.invalid;
		}
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Secundarios
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Cambia los datos del modelo detalle.
	 */
	private _setDataModel(): void {
		this.recover.bondsValue = this.recoverForm.get('bondsValue').value;
		this.recover.usageRatesValue = this.recoverForm.get('usageRatesValue').value;
		this.recover.tollsValue = this.recoverForm.get('tollsValue').value;
		this.recover.deliveryValue = this.recoverForm.get('deliveryValue').value;
		this.recover.observation = this.recoverForm.get('observation').value;
		if (this.recover.observation != null) {
			if (this.recover.observation.trim().length === 0) {
				this.recover.observation = null;
			}
		}
	}

	/**
	 * Construye el formulario.
	 */
	private _buildForm(): void {
		this.recoverForm = this._formBuilder.group({
			observation: '',
			totalValue: {value: 0, disabled: true},
			positiveBalanceValue: {value: 0, disabled: true},
			bondsValue: {value: 0, disabled: true},
			usageRatesValue: 0,
			tollsValue: 0,
			totalValuePay: {value: 0, disabled: true},
			deliveryValue: 0,
			balanceValue: {value: 0, disabled: true},
		});
	}

	/**
	 * Llena el formulario.
	 */
	private _fillForm(): void {
		let totalValuePay = this.recover.totalValue - this.recover.positiveBalanceValue - this.recover.bondsValue - this.recover.usageRatesValue - this.recover.tollsValue;
		this.recover.balanceValue = totalValuePay - this.recover.deliveryValue;
		this.recoverForm.patchValue({
			observation: this.recover.observation,
			totalValue: this.recover.totalValue,
			positiveBalanceValue: this.recover.positiveBalanceValue,
			bondsValue: this.recover.bondsValue,
			usageRatesValue: this.recover.usageRatesValue,
			tollsValue: this.recover.tollsValue,
			totalValuePay: totalValuePay,
			deliveryValue: this.recover.deliveryValue,
			balanceValue: this.recover.balanceValue,
		});
	}

	/**
	 * Verifica la validación del formulario.
	 */
	private _checkValidation(): void {
		if (this.recoverForm.get('balanceValue').value !== 0) {
			this.recoverForm.get('observation').setValidators([Validators.required]);
		} else {
			this.recoverForm.get('observation').setValidators(null);
		}
		this.recoverForm.get('observation').updateValueAndValidity();
		this.recoverForm.updateValueAndValidity();
	}
}
