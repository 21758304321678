<form class="form" name="calculatorForm" [formGroup]="calculatorForm" novalidate>
	<div class="row m-0">
		<div class="col-6 p-0 pr-1">
			<div class="row m-0 mb-2">
				<input nbInput class="input text-right col-4" formControlName="cienMilValue" type="tel" fieldSize="medium" currencyMask>
				<input nbInput class="input text-center col-3" formControlName="cienMilAmount" type="number" fieldSize="medium" (keyup)="calculate()">
				<input nbInput class="input text-right col-5" formControlName="cienMilTotal" type="tel" fieldSize="medium" currencyMask>
			</div>
			<div class="row m-0 mb-2">
				<input nbInput class="input text-right col-4" formControlName="cincuentaMilValue" type="tel" fieldSize="medium" currencyMask>
				<input nbInput class="input text-center col-3" formControlName="cincuentaMilAmount" type="number" fieldSize="medium" (keyup)="calculate()">
				<input nbInput class="input text-right col-5" formControlName="cincuentaMilTotal" type="tel" fieldSize="medium" currencyMask>
			</div>
			<div class="row m-0 mb-2">
				<input nbInput class="input text-right col-4" formControlName="veinteMilValue" type="tel" fieldSize="medium" currencyMask>
				<input nbInput class="input text-center col-3" formControlName="veinteMilAmount" type="number" fieldSize="medium" (keyup)="calculate()">
				<input nbInput class="input text-right col-5" formControlName="veinteMilTotal" type="tel" fieldSize="medium" currencyMask>
			</div>
			<div class="row m-0 mb-2">
				<input nbInput class="input text-right col-4" formControlName="diezMilValue" type="tel" fieldSize="medium" currencyMask>
				<input nbInput class="input text-center col-3" formControlName="diezMilAmount" type="number" fieldSize="medium" (keyup)="calculate()">
				<input nbInput class="input text-right col-5" formControlName="diezMilTotal" type="tel" fieldSize="medium" currencyMask>
			</div>
			<div class="row m-0 mb-2">
				<input nbInput class="input text-right col-4" formControlName="cincoMilValue" type="tel" fieldSize="medium" currencyMask>
				<input nbInput class="input text-center col-3" formControlName="cincoMilAmount" type="number" fieldSize="medium" (keyup)="calculate()">
				<input nbInput class="input text-right col-5" formControlName="cincoMilTotal" type="tel" fieldSize="medium" currencyMask>
			</div>
			<div class="row m-0 mb-2">
				<input nbInput class="input text-right col-4" formControlName="dosMilValue" type="tel" fieldSize="medium" currencyMask>
				<input nbInput class="input text-center col-3" formControlName="dosMilAmount" type="number" fieldSize="medium" (keyup)="calculate()">
				<input nbInput class="input text-right col-5" formControlName="dosMilTotal" type="tel" fieldSize="medium" currencyMask>
			</div>
			<div class="row m-0">
				<input nbInput class="input text-right col-4" formControlName="milBilleteValue" type="tel" fieldSize="medium" currencyMask>
				<input nbInput class="input text-center col-3" formControlName="milBilleteAmount" type="number" fieldSize="medium" (keyup)="calculate()">
				<input nbInput class="input text-right col-5" formControlName="milBilleteTotal" type="tel" fieldSize="medium" currencyMask>
			</div>
		</div>
		<div class="col-6 p-0 pl-1">
			<div class="row m-0 mb-2">
				<input nbInput class="input text-right col-4" formControlName="milMonedaValue" type="tel" fieldSize="medium" currencyMask>
				<input nbInput class="input text-center col-3" formControlName="milMonedaAmount" type="number" fieldSize="medium" (keyup)="calculate()">
				<input nbInput class="input text-right col-5" formControlName="milMonedaTotal" type="tel" fieldSize="medium" currencyMask>
			</div>
			<div class="row m-0 mb-2">
				<input nbInput class="input text-right col-4" formControlName="quinientosValue" type="tel" fieldSize="medium" currencyMask>
				<input nbInput class="input text-center col-3" formControlName="quinientosAmount" type="number" fieldSize="medium" (keyup)="calculate()">
				<input nbInput class="input text-right col-5" formControlName="quinientosTotal" type="tel" fieldSize="medium" currencyMask>
			</div>
			<div class="row m-0 mb-2">
				<input nbInput class="input text-right col-4" formControlName="docientosValue" type="tel" fieldSize="medium" currencyMask>
				<input nbInput class="input text-center col-3" formControlName="docientosAmount" type="number" fieldSize="medium" (keyup)="calculate()">
				<input nbInput class="input text-right col-5" formControlName="docientosTotal" type="tel" fieldSize="medium" currencyMask>
			</div>
			<div class="row m-0 mb-2">
				<input nbInput class="input text-right col-4" formControlName="cienValue" type="tel" fieldSize="medium" currencyMask>
				<input nbInput class="input text-center col-3" formControlName="cienAmount" type="number" fieldSize="medium" (keyup)="calculate()">
				<input nbInput class="input text-right col-5" formControlName="cienTotal" type="tel" fieldSize="medium" currencyMask>
			</div>
			<div class="row m-0 mb-3">
				<input nbInput class="input text-right col-4" formControlName="cincuentaValue" type="tel" fieldSize="medium" currencyMask>
				<input nbInput class="input text-center col-3" formControlName="cincuentaAmount" type="number" fieldSize="medium" (keyup)="calculate()">
				<input nbInput class="input text-right col-5" formControlName="cincuentaTotal" type="tel" fieldSize="medium" currencyMask>
			</div>
			<div class="mx-3">
				<label class="label mr-2">Total Efectivo:</label>
				<input nbInput class="input text-right" formControlName="totalValue" type="tel" fieldSize="medium" currencyMask fullWidth>
			</div>
		</div>
	</div>
</form>