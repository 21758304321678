<nb-card status="success">
	<nb-card-header>
		<div class="row m-0">
			<h5 class="title col p-0">Líneas</h5>
			<nb-icon class="close col-auto p-0" icon="close" (click)="close()"></nb-icon>
		</div>
	</nb-card-header>
	<nb-card-body>
		<div class="row mb-3">
			<div class="col">
				<input nbInput class="input" type="text" fieldSize="medium" shape="round" placeholder="Buscar..." (keyup)="filterLines(search.value)" fullWidth #search>
			</div>
			<div class="col-auto">
				<button nbButton class="button" type="submit" size="small" shape="round" status="primary" (click)="addLines()">
					<nb-icon icon="plus"></nb-icon>Aceptar
				</button>
			</div>
		</div>
		<div class="mb-2">
			<label class="label">Disponibles:</label>
			<ng2-smart-table [source]="sourcePrimary" [settings]="settingsPrimary" (userRowSelect)="selectLine($event)"></ng2-smart-table>
		</div>
		<div>
			<label class="label">Pendientes:</label>
			<ng2-smart-table [source]="sourceSecondary" [settings]="settingsSecondary"></ng2-smart-table>
		</div>
	</nb-card-body>
</nb-card>