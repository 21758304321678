<nb-card status="success">
	<nb-card-header>
		<div class="row m-0">
			<h5 class="title col p-0">Egreso</h5>
			<nb-icon class="close col-auto p-0" icon="close" (click)="close(true)"></nb-icon>
		</div>
	</nb-card-header>
	<nb-card-body>
		<form name="egressForm" [formGroup]="egressForm" novalidate>
			<div class="col-7 mb-2 p-0">
				<label class="label">Concepto:</label>
				<nb-select formControlName="conceptCode" [status]="getStatus('conceptCode', triedAction)" (selectedChange)="onSelectConcept()" fullWidth>
					<nb-option [value]="concept.code" *ngFor="let concept of concepts">{{concept.name}}</nb-option>
				</nb-select>
				<ng-container *ngIf="isNotValid('conceptCode', triedAction)">
					<p class="caption" *ngIf="egressForm.get('conceptCode').hasError('required')">Concepto es requerido!</p>
				</ng-container>
			</div>
			<div class="mb-2" formGroupName="third">
				<label class="label">Tercero:</label>
				<div class="row m-0">
					<div class="col-10 p-0">
						<input nbInput class="input uppercase" formControlName="fullName" type="text" fieldSize="medium" [attr.disabled]="true" fullWidth>
					</div>
					<div class="center-vertical" *ngIf="egressForm.get('conceptCode').value">
						<nb-icon class="button-icon" icon="search" pack="font-awesome" (click)="showDialogThirds()"></nb-icon>
					</div>
				</div>
				<ng-container *ngIf="isNotValid('third.fullName', triedAction)">
					<p class="caption" *ngIf="egressForm.get('third.fullName').hasError('required')">Tercero es requerido!</p>
				</ng-container>
			</div>
			<div class="mb-2">
				<label class="label">Descripción:</label>
				<textarea nbInput class="textarea" formControlName="description" type="text" [status]="getStatus('description', triedAction)" fullWidth></textarea>
				<ng-container *ngIf="isNotValid('description', triedAction)">
					<p class="caption" *ngIf="egressForm.get('description').hasError('required')">Descripción es requerida!</p>
				</ng-container>
			</div>
			<div class="col-5 mb-3 p-0">
				<label class="label">Valor:</label>
				<input nbInput class="input" formControlName="value" type="tel" fieldSize="medium" currencyMask fullWidth>
			</div>
			<div class="center-horizontal" *ngIf="egressForm.get('id').value == 0">
				<button nbButton class="button" type="submit" size="small" shape="round" status="primary" (click)='createEgress()'>
					<nb-icon icon="save"></nb-icon>Registrar
				</button>
			</div>
		</form>
	</nb-card-body>
</nb-card>
<ngx-loading [show]="loading"></ngx-loading>