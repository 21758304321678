<nb-card status="success">
	<nb-card-header>
		<div class="row m-0">
			<h5 class="title col p-0">Abrir Turno</h5>
			<nb-icon class="close col-auto p-0" icon="close" (click)="close()"></nb-icon>
		</div>
	</nb-card-header>
	<nb-card-body>
		<form name="turnForm" [formGroup]="turnForm" novalidate>
			<div class="mb-2">
				<label class="label">Punto de {{type === 'CL' ? 'Recaudo' : 'Emisión'}}:</label>
				<nb-select formControlName="pointCode" [status]="getStatus('pointCode', triedAction)" (selectedChange)="onSelectPoint($event)" fullWidth>
					<nb-option [value]="point.code" *ngFor="let point of points">{{point.name}}</nb-option>
				</nb-select>
				<ng-container *ngIf="isNotValid('pointCode', triedAction)">
					<p class="caption" *ngIf="turnForm.get('pointCode').hasError('required')">Punto de recaudo es requerido!</p>
				</ng-container>
			</div>
			<div class="mb-2" *ngIf="type === 'CE'">
				<label class="label">Tarjetas:</label>
				<input nbInput class="input" formControlName="cardsAmount" type="number" fieldSize="medium" fullWidth>
			</div>
			<div class="mb-3">
				<label class="label">Base:</label>
				<input nbInput class="input" formControlName="baseValue" type="tel" fieldSize="medium" currencyMask fullWidth>
			</div>
			<div class="center-horizontal">
				<button nbButton class="button" type="submit" size="small" shape="round" status="primary" (click)='createTurn()'>
					<nb-icon icon="save"></nb-icon>Abrir
				</button>
			</div>
		</form>
	</nb-card-body>
</nb-card>
<ngx-loading [show]="loading"></ngx-loading>