<nb-card status="success">
	<nb-card-header>
		<div class="row m-0">
			<div class="center-vertical col p-0">
				<div class="row m-0">
					<nb-icon class="close center-vertical" icon="close" (click)="close()"></nb-icon>
					<h5 class="title"></h5>
				</div>
			</div>
			<div class="right col-auto p-0">
				<button nbButton class="button" type="submit" size="small" shape="round" status="primary" (click)="printRecover()" *ngIf="false">
					<nb-icon icon="printer"></nb-icon>Imprimir
				</button>
			</div>
		</div>
	</nb-card-header>
	<nb-card-body>
		<div id="reporte" class="report">{{report}}</div>
	</nb-card-body>
</nb-card>