<nb-card status="success">
	<nb-card-header>
		<div class="row m-0">
			<h5 class="title col p-0">Combustibles</h5>
			<nb-icon class="close col-auto p-0" icon="close" (click)="close()"></nb-icon>
		</div>
	</nb-card-header>
	<nb-card-body>
		<input nbInput class="input mb-3" type="text" fieldSize="medium" shape="round" placeholder="Buscar..." fullWidth (keyup)="filterFuels(search.value)" #search>
		<ng2-smart-table [source]="source" [settings]="settings" (userRowSelect)="selectFuel($event)"></ng2-smart-table>
	</nb-card-body>
</nb-card>