import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { Turn } from 'src/app/models/turn';

import { DialogQuestionComponent } from '../question/dialog-question.component';

import { NbDialogService, NbToastrService } from '@nebular/theme';
import { PrintService } from 'src/app/services/print.service';
import { TurnService } from 'src/app/services/turn.service';

@Component({
	templateUrl: 'dialog-turn-close.component.html',
	styleUrls: ['dialog-turn-close.component.scss'],
})
export class DialogTurnCloseComponent implements OnInit {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	public loading = false;
	public turnForm: FormGroup;

	@Input() public turn: Turn;
	@Input() public type: string;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Constructor
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	constructor(private _formBuilder: FormBuilder, protected _dialogRef: NbDialogRef<DialogTurnCloseComponent>,
				private _dialogService: NbDialogService, private _toastrService: NbToastrService,
				private _printService: PrintService, private _turnService: TurnService) { }

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Funciones Propias
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	ngOnInit(): void {
		this._buildForm();
		this._fillForm();
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Principales
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
   * Cierra el modelo turno.
   */
	public closeTurn(): void {
		if (!this.turn.isAttemptedClosure) {
			this._showDialogQuestion('Confirmación', '¿Esta seguro que desea cerrar turno?\n\nTenga en cuenta que el efectivo no se podrá modificar.', (result) => {
				if (result) {
					this._updateTurn();
				}
			});
		} else {
			this._updateTurn();
		}
	}

	/**
	 * Cierra el diálogo.
	 */
	public close(result?: any): void {
		this._dialogRef.close(result);
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Secundarios
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Cambia los datos del modelo detalle.
	 */
	private _setDataModel(): void {
		this.turn.closeCardsAmount = this.turnForm.get('cardsAmount').value;
		this.turn.effectiveValue = this.turnForm.get('effectiveValue').value;
	}

	/**
	 * Construye el formulario.
	 */
	private _buildForm(): void {
		this.turnForm = this._formBuilder.group({
			cardsAmount: [0, [Validators.required]],
			baseValue: [{value: 0, disabled: true}, [Validators.required]],
			effectiveValue: [0, [Validators.required]],
			balanceValue: [{value: 0, disabled: true}],
		});
	}

	/**
	 * Llena el formulario.
	 */
 	private _fillForm(): void {
	 	this.turnForm.patchValue({
			cardsAmount: this.turn.closeCardsAmount !== null ? this.turn.closeCardsAmount : 0,
			baseValue: this.turn.baseValue,
			effectiveValue: this.turn.effectiveValue,
			balanceValue: this.turn.balanceValue,
		});
		if (this.turn.isAttemptedClosure) {
			this.turnForm.get('cardsAmount').disable();
			this.turnForm.get('effectiveValue').disable();
		}
	}

	/**
	 * Actualiza el modelo turno.
	 */
	 private _updateTurn(): void {
		this.loading = true;
		this._setDataModel();
		this._turnService.update(this.turn).then(
			_response => {
				this._printService.printTurn(this.turn.id, {
					point_id: this.turn.recoverPoint.id,
					type: this.type,
				}).then(
					reporte => {
						this.loading = false;
						this.close(reporte);
					},
					exception => this._handleFailure(exception),
				);
			},
			exception => {
				if (exception.status === 406) {
					this.loading = false;
					this.close(+exception.error);
				} else {
					this._handleFailure(exception);
				}
			}
		);
	}

	 /**
	 * Muestra el diálogo de la pregunta.
	 * @param title Titulo del mensaje.
	 * @param content Contenido del mensaje.
	 * @param textButtonAccept Texto del botón aceptar.
	 * @param textButtonCancel Texto del botón cancelar.
	 */
	private _showDialogQuestion(title: string, content: string, callback: (result: boolean) => void, textButtonAccept?: string, textButtonCancel?: string): void {
		this._dialogService.open(DialogQuestionComponent, {
			context: {
				title: title,
				message: content,
				textButtonAccept: textButtonAccept,
				textButtonCancel: textButtonCancel,
			},
			autoFocus: false,
			closeOnEsc: true,
			closeOnBackdropClick: false,
		}).onClose.subscribe(result => callback(result));
	}

	/**
	 * Maneja el mensaje que se va a mostrar.
	 * @param type Tipo de mensaje.
	 */
	private _handleMessage(message: any, type: any) {
		this._toastrService.show(message, 'Turno', {
			status: type,
			duration: 3000,
		});
	}

	/**
	 * Maneja la excepción que se ha generado.
	 */
	private _handleFailure(exception: any): void {
		this.loading = false;
		this._toastrService.show(exception.error, 'Turno', {
			status: 'danger',
			duration: 3000,
		});
	}
}
