<div class="header-container">
    <div class="logo-container">
		<a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
			<nb-icon icon="menu-2"></nb-icon>
		</a>
        <a class="logo" href="#" [routerLink]="['/dashboard']">{{appName}}</a>
	</div>
</div>
<div class="header-container">
    <nb-actions size="small" *ngIf="user">
        <nb-action class="control-item" icon="email" *ngIf="false"></nb-action>
		<nb-action class="control-item" icon="bell" *ngIf="false"></nb-action>
		<nb-action class="user-action">
			<nb-user size="medium" picture="./assets/images/avatar.jpg" [name]="user ? user.names + ' ' + user.surnames : ''" [title]="user ? user.role.name : ''" [nbContextMenu]="userMenu"></nb-user>
			<nb-icon icon="arrow-down"></nb-icon>
		</nb-action>
    </nb-actions>
</div>