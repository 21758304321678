<nb-card status="success">
	<nb-card-header>
		<div class="row m-0">
			<h5 class="title col p-0">Autorizar Combustible</h5>
			<nb-icon class="close col-auto p-0" icon="close" (click)="close()"></nb-icon>
		</div>
	</nb-card-header>
	<nb-card-body>
		<form name="fuelForm" [formGroup]="fuelAuthorizeForm" novalidate>
			<div class="row m-0 mb-2">
				<div class="col-6 p-0 pr-2" formGroupName="vehicle">
					<label class="label">Vehículo:</label>
					<div class="row m-0 mb-2">
						<div class="col p-0 pr-1">
							<input nbInput class="input uppercase" formControlName="internalNumber" type="text" fieldSize="medium" [attr.disabled]="true" fullWidth>
						</div>
						<div class="col p-0 pl-1">
							<input nbInput class="input uppercase" formControlName="plaque" type="text" fieldSize="medium" [attr.disabled]="true" fullWidth>
						</div>
						<div class="center-vertical">
							<nb-icon class="button-icon" icon="search" pack="font-awesome" (click)="showDialogVehicles()"></nb-icon>
						</div>
					</div>
					<input nbInput class="input uppercase" formControlName="companyName" type="text" fieldSize="medium" [attr.disabled]="true" fullWidth>
					<ng-container *ngIf="isNotValid('vehicle.plaque', triedAction)">
						<p class="caption" *ngIf="fuelAuthorizeForm.get('vehicle.plaque').hasError('required')">Vehículo es requerido!</p>
					</ng-container>
				</div>
				<div class="col-6 p-0 pr-2" formGroupName="driver">
					<label class="label">Conductor:</label>
					<div class="row m-0 mb-2">
						<div class="col p-0">
							<input nbInput class="input uppercase" formControlName="documentNumber" type="text" fieldSize="medium" [attr.disabled]="true" fullWidth>
						</div>
						<div class="center-vertical" *ngIf="lines.length > 0">
							<nb-icon class="button-icon" icon="search" pack="font-awesome" (click)="showDialogDrivers()"></nb-icon>
						</div>
					</div>
					<div class="p-0">
						<input nbInput class="input uppercase" formControlName="fullName" type="text" fieldSize="medium" [attr.disabled]="true" fullWidth>
					</div>
					<ng-container *ngIf="isNotValid('driver.fullName', triedAction)">
						<p class="caption" *ngIf="fuelAuthorizeForm.get('driver.fullName').hasError('required')">Conductor es requerido!</p>
					</ng-container>
				</div>
			</div>
			<div class="mb-2">
				<label class="label">Lineas:</label>
				<ng2-smart-table [source]="source" [settings]="settings" (userRowSelect)="selectLine($event)"></ng2-smart-table>
				<ng-container *ngIf="isNotValid('details', triedAction)">
					<p class="caption" *ngIf="fuelAuthorizeForm.get('details').hasError('required')">Lineas son requeridas!</p>
				</ng-container>
			</div>
			<div class="mb-2">
				<label class="label">Observación:</label>
				<textarea nbInput class="input" formControlName="observation" type="text" [status]="getStatus('observation', triedAction)" fullWidth></textarea>
				<ng-container *ngIf="isNotValid('observation', triedAction)">
					<p class="caption" *ngIf="fuelAuthorizeForm.get('observation').hasError('required')">Observación es requerida!</p>
				</ng-container>
			</div>
			<div class="center-horizontal">
				<button nbButton class="button" type="submit" size="small" shape="round" status="primary" (click)="createFuelAuthorize()" *ngIf="lines.length > 0">
					<nb-icon icon="save"></nb-icon>Autorizar
				</button>
				<button nbButton class="button" type="submit" size="small" shape="round" status="primary" (click)='searchLines()'>
					<nb-icon icon="search"></nb-icon>Buscar
				</button>
				<button nbButton class="button" type="submit" size="small" shape="round" status="primary" (click)="removeData()">
					<nb-icon icon="trash"></nb-icon>Limpiar
				</button>
			</div>
		</form>
	</nb-card-body>
</nb-card>
<ngx-loading [show]="loading"></ngx-loading>