import { Serializable, JsonProperty, deserialize, serialize } from 'typescript-json-serializer';
import { Driver } from './driver';
import { Turn } from './turn';
import { Vehicle } from './vehicle';

/**
 * Clase que administra los datos de la venta de tarjeta.
 */
@Serializable()
export class SaleCard {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/** Id */
	@JsonProperty('id')
	public id: number;

	/** Número del recibo */
	@JsonProperty('num_recibo')
	public receiptNumber: string;

	/** Fecha y hora */
	@JsonProperty('fecha_hora')
	public dateTime: string;

	/** Observación */
	@JsonProperty('observacion')
	public observation: string;

	/** Cantidad */
	@JsonProperty('cantidad')
	public quantity: number;

	/** Valor unitario */
	@JsonProperty('valor_unitario')
	public unitValue: number;

	/** Valor total */
	@JsonProperty('valor_total')
	public totalValue: number;

	/** Vehículo */
	@JsonProperty('vehiculo')
	public vehicle: Vehicle;

	/** Conductor */
	@JsonProperty('conductor')
	public driver: Driver;

	/** Turno */
	@JsonProperty('turno')
	public turn: Turn;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Convierte un mapa json en un objeto de la clase.
	 */
	public static fromJson(json: any): SaleCard {
		return deserialize(json, SaleCard);
	}

	/**
	 * Convierte el objeto de la clase en un mapa json.
	 */
	public static toJson(model: SaleCard): any {
		return serialize(model);
	}
}
