import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { Fuel } from 'src/app/models/fuel';
import { Line } from 'src/app/models/line';
import { Turn } from 'src/app/models/turn';

import { NbToastrService } from '@nebular/theme';
import { FuelService } from 'src/app/services/fuel.service';

@Component({
	templateUrl: './dialog-fuel-cancel.component.html',
	styleUrls: ['./dialog-fuel-cancel.component.scss'],
})
export class DialogFuelCancelComponent implements OnInit {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	private _fuel: Fuel;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	public loading = false;
	public triedAction = false;
	public fuelForm: FormGroup;

	@Input() public turn: Turn;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Constructor
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	constructor(private _formBuilder: FormBuilder, private _datepipe: DatePipe, protected _dialogRef: NbDialogRef<DialogFuelCancelComponent>,
							private _toastrService: NbToastrService, private _fuelService: FuelService) {
		this._fuel = new Fuel();
		this._fuel.details = new Array();
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Propios
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	ngOnInit(): void {
		this._buildForm();
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Principales
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
   * Actualiza el modelo combustible.
   */
	public updateFuel(): void {
		this.triedAction = true;
		this._checkValidationUpdate();
		if (!this.fuelForm.invalid) {
			this.loading = true;
			this._setDataModel();
			this._fuelService.update(this._fuel).then(
				_response => {
					this.loading = false;
					this.triedAction = false;
					this.removeData();
					this._handleMessage('Actualización exitosa!', 'info');
				},
				exception => this._handleFailure(exception),
			);
		}
	}

	/**
   * Busca el modelo combustible.
   */
	public searchFuel(): void {
		this.triedAction = true;
		this._checkValidationSearch();
		if (!this.fuelForm.invalid) {
			this.loading = true;
			this._fuelService.findBy({
				number: this.fuelForm.get('number').value.toUpperCase(),
				detalles: 'linea, exclude',
			}).then(
				model => {
					this.loading = false;
					this._fuel = model;
					this._fillForm();
				},
				exception => this._handleFailure(exception),
			);
		}
	}

	/**
	 * Elimina los datos.
	 */
	public removeData(): void {
		this.triedAction = false;
		this._clearForm();
	}

	/**
   * Cierra el diálogo.
   */
	public close(models?: Array<Line>): void {
		this._dialogRef.close(models);
	}

	/**
	 * Indica si no es válido el control del formulario.
	 * @param controlName Nombre del control.
	 */
	public isNotValid(controlName: string, triedAction?: boolean): boolean {
		const control = this.fuelForm.get(controlName);
		if (!triedAction) {
			return control.dirty && control.invalid;
		} else {
			return control.invalid;
		}
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Secundarios
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Cambia los datos del modelo ingreso.
	 */
	private _setDataModel(): void {
		this._fuel.cancelObservation = this.fuelForm.get('observation').value;
		this._fuel.cancelTurn = this.turn;
	}

	/**
	 * Construye el formulario.
	 */
	private _buildForm(): void {
		this.fuelForm = this._formBuilder.group({
			id: 0,
			number: ['', [Validators.required]],
			date: {value: '', disabled: true},
			gallonsAmount: {value: 0, disabled: true},
			totalValue: {value: 0, disabled: true},
			observation: ['', [Validators.required]],
			driver: this._formBuilder.group({
				id: 0,
				documentNumber: '',
				fullName: '',
			}),
			vehicle: this._formBuilder.group({
				id: 0,
				internalNumber: '',
				plaque: '',
				companyName: '',
			}),
		});
	}

	/**
	 * Llena el formulario.
	 */
	private _fillForm(): void {
		let cantGalones = 0;
		let valorTotal = 0;

		this._fuel.details.forEach(model => {
			cantGalones += model.gallonsAmount;
			valorTotal += model.totalValue;
		});
		this.fuelForm.setValue({
			id: this._fuel.id,
			number: this._fuel.number,
			date: this._datepipe.transform(this._fuel.dateTime, 'dd MMM yyyy'),
			gallonsAmount: cantGalones,
			totalValue: valorTotal,
			observation: '',
			driver: {
				id: this._fuel.driver.id,
				documentNumber: this._fuel.driver.documentNumber,
				fullName: this._fuel.driver.names + ' ' + this._fuel.driver.surnames,
			},
			vehicle: {
				id: this._fuel.vehicle.id,
				internalNumber: this._fuel.vehicle.internalNumber,
				plaque: this._fuel.vehicle.plaque,
				companyName: this._fuel.vehicle.company.name,
			}
		});
		this.fuelForm.get('number').disable();
	}

	/**
	 * Limpia el formulario.
	 */
	private _clearForm(): void {
		this._fuel.id = 0;
		this.fuelForm.patchValue({
			id: 0,
			number: '',
			date: '',
			gallonsAmount: 0,
			totalValue: 0,
			observation: '',
			driver: {
				id: 0,
				documentNumber: '',
				fullName: '',
			},
			vehicle: {
				id: 0,
				internalNumber: '',
				plaque: '',
				companyName: '',
			},
		});
		this.fuelForm.get('number').enable();
	}

	/**
	 * Maneja el mensaje que se va a mostrar.
	 * @param type Tipo de mensaje.
	 */
	private _handleMessage(message: any, type: any) {
		this._toastrService.show(message, 'Anular Combustible', {
			status: type,
			duration: 3000,
		});
	}

	/**
	 * Maneja la excepción que se ha generado.
	 */
	private _handleFailure(exception: any): void {
		this.loading = false;
		const mensaje = exception.status > 0 ? exception.error : exception.message;
		this._toastrService.show(mensaje, 'Anular Combustible', {
			status: 'danger',
			duration: 5000,
		});
	}

	/**
	 * Verifica si la validación es requerida.
	 */
	private _checkValidationSearch(): void {
		if (this.fuelForm.get('id').value === 0) {
			this.fuelForm.get('observation').setValidators(null);
		}
		this.fuelForm.get('observation').updateValueAndValidity();
		this.fuelForm.updateValueAndValidity();
	}

	/**
	 * Verifica si la validación es requerida.
	 */
	private _checkValidationUpdate(): void {
		if (this.fuelForm.get('id').value > 0) {
			this.fuelForm.get('observation').setValidators([Validators.required]);
		}
		this.fuelForm.get('observation').updateValueAndValidity();
		this.fuelForm.updateValueAndValidity();
	}
}
