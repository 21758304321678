import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppNebularModule } from '../app-nebular.module';
import { LayoutsModule } from '../layouts/layouts.module';

import { AuthComponent } from './auth/auth.component';
import { HomeComponent } from './home/home.component';

@NgModule({
	imports: [
		AppNebularModule,
		LayoutsModule,
		RouterModule,
	],
	declarations: [
		AuthComponent,
		HomeComponent,
	],
})
export class PagesModule { }
