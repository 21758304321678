import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule , ReactiveFormsModule } from '@angular/forms';
import { NbAuthModule } from '@nebular/auth';
import { NbDateFnsDateModule } from '@nebular/date-fns';
import {
	NbDatepickerModule,
	NbDialogModule,
	NbMenuModule,
	NbSidebarModule,
	NbThemeModule,
	NbToastrModule,
	NbWindowModule
} from '@nebular/theme';
import { NbMomentDateModule } from '@nebular/moment';
import { NgxCurrencyModule } from 'ngx-currency';
import { NgxLoadingModule } from 'ngx-loading';
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { AppNebularModule } from './app-nebular.module';
import { AppRoutingModule } from './app-routing.module';
import { PagesModule } from './pages/pages.module';

import { AppComponent } from './app.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { AmountInputEditorComponent } from './components/input-editor/amount-input-editor.component';
import { CurrencyInputEditorComponent, CurrencyRenderComponent } from './components/input-editor/currency-input-editor.component';
import { DialogBearingDetailComponent } from './dialogs/bearing-detail/dialog-bearing-detail.component';
import { DialogBenefitComponent } from './dialogs/benefit/dialog-benefit.component';
import { DialogBenefitCancelComponent } from './dialogs/benefit-cancel/dialog-benefit-cancel.component';
import { DialogBenefitListComponent } from './dialogs/benefit-list/dialog-benefit-list.component';
import { DialogChangePasswordComponent } from './dialogs/change-password/dialog-change-password.component';
import { DialogChargeAccountComponent } from './dialogs/charge-account/dialog-charge-account.component';
import { DialogChargeAccountListComponent } from './dialogs/charge-account-list/dialog-charge-account-list.component';
import { DialogClientComponent } from './dialogs/client/dialog-client.component';
import { DialogClientListComponent } from './dialogs/client-list/dialog-client-list.component';
import { DialogCompanyListComponent } from './dialogs/company-list/dialog-company-list.component';
import { DialogDriverListComponent } from './dialogs/driver-list/dialog-driver-list.component';
import { DialogEgressComponent } from './dialogs/egress/dialog-egress.component';
import { DialogFuelComponent } from './dialogs/fuel/dialog-fuel.component';
import { DialogFuelAuthorizeComponent } from './dialogs/fuel-authorize/dialog-fuel-authorize.component';
import { DialogFuelCancelComponent } from './dialogs/fuel-cancel/dialog-fuel-cancel.component';
import { DialogFuelListComponent } from './dialogs/fuel-list/dialog-fuel-list.component';
import { DialogIngressComponent } from './dialogs/ingress/dialog-ingress.component';
import { DialogIssuanceCancelComponent } from './dialogs/issuance-cancel/dialog-issuance-cancel.component';
import { DialogIssuanceListComponent } from './dialogs/issuance-list/dialog-issuance-list.component';
import { DialogLineListComponent } from './dialogs/line-list/dialog-line-list.component';
import { DialogMessageComponent } from './dialogs/message/dialog-message.component';
import { DialogPrintReportComponent } from './dialogs/print-report/dialog-print-report.component';
import { DialogQuestionComponent } from './dialogs/question/dialog-question.component';
import { DialogRecoverComponent } from './dialogs/recover/dialog-recover.component';
import { DialogRecoverListComponent } from './dialogs/recover-list/dialog-recover-list.component';
import { DialogRecoverPointListComponent } from './dialogs/recover-point-list/dialog-recover-point-list.component';
import { DialogRoleListComponent } from './dialogs/role-list/dialog-role-list.component';
import { DialogRouteListComponent } from './dialogs/route-list/dialog-route-list.component';
import { DialogSaleCardComponent } from './dialogs/sale-card/dialog-sale-card.component';
import { DialogThirdListComponent } from './dialogs/third-list/dialog-third-list.component';
import { DialogTurnCloseComponent } from './dialogs/turn-close/dialog-turn-close.component';
import { DialogTurnOpenComponent, } from './dialogs/turn-open/dialog-turn-open.component';
import { DialogUserListComponent } from './dialogs/user-list/dialog-user-list.component';
import { DialogVehicleListComponent } from './dialogs/vehicle-list/dialog-vehicle-list.component';
import { DialogVoucherListComponent } from './dialogs/voucher-list/dialog-voucher-list.component';
import { WindowMoneyCalculatorComponent } from './windows/money-calculator/window-money-calculator.component';

import { LOCALE_ID } from '@angular/core';

import { CurrencyPipe } from '@angular/common';

import { registerLocaleData } from '@angular/common';
import { httpInterceptor } from 'src/environments/environment';

import locale_es from '@angular/common/locales/es';
import local_fns_es from 'date-fns/locale/es/index';

registerLocaleData(locale_es);

@NgModule({
	imports: [
		AppNebularModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		BrowserModule,
		FormsModule,
		HttpClientModule,
		NbAuthModule.forRoot(),
		NbDateFnsDateModule.forRoot({
			format: 'dd MMM. yyyy',
			formatOptions: {locale: local_fns_es},
			parseOptions: {locale: local_fns_es},
		}),
		NbDatepickerModule.forRoot(),
		NbDialogModule.forRoot(),
		NbMenuModule.forRoot(),
		NbMomentDateModule,
		NbSidebarModule.forRoot(),
		NbThemeModule.forRoot({name: 'default'}),
		NbToastrModule.forRoot(),
		NbWindowModule.forRoot(),
		NgxCurrencyModule.forRoot({
			align: 'left',
			prefix: '$ ',
			suffix: '',
			thousands: ',',
			decimal: '.',
			precision: 0,
			allowZero: true,
			allowNegative: true,
			nullable: true
		}),
		NgxLoadingModule.forRoot({}),
		NgxUiLoaderModule,
		PagesModule,
		ReactiveFormsModule,
	],
	declarations: [
		AppComponent,
		CheckboxComponent,
		AmountInputEditorComponent,
		CurrencyInputEditorComponent,
		CurrencyRenderComponent,
		DialogBearingDetailComponent,
		DialogBenefitComponent,
		DialogBenefitCancelComponent,
		DialogBenefitListComponent,
		DialogChangePasswordComponent,
		DialogChargeAccountComponent,
		DialogChargeAccountListComponent,
		DialogClientComponent,
		DialogClientListComponent,
		DialogCompanyListComponent,
		DialogDriverListComponent,
		DialogEgressComponent,
		DialogFuelComponent,
		DialogFuelAuthorizeComponent,
		DialogFuelCancelComponent,
		DialogFuelListComponent,
		DialogIngressComponent,
		DialogIssuanceCancelComponent,
		DialogIssuanceListComponent,
		DialogLineListComponent,
		DialogMessageComponent,
		DialogPrintReportComponent,
		DialogQuestionComponent,
		DialogRecoverComponent,
		DialogRecoverListComponent,
		DialogRecoverPointListComponent,
		DialogRoleListComponent,
		DialogRouteListComponent,
		DialogSaleCardComponent,
		DialogThirdListComponent,
		DialogTurnCloseComponent,
		DialogTurnOpenComponent,
		DialogUserListComponent,
		DialogVehicleListComponent,
		DialogVoucherListComponent,
		WindowMoneyCalculatorComponent,
	],
	entryComponents: [
		CheckboxComponent,
		AmountInputEditorComponent,
		CurrencyInputEditorComponent,
		CurrencyRenderComponent,
		DialogBearingDetailComponent,
		DialogBenefitComponent,
		DialogBenefitCancelComponent,
		DialogBenefitListComponent,
		DialogChangePasswordComponent,
		DialogChargeAccountComponent,
		DialogChargeAccountListComponent,
		DialogClientComponent,
		DialogClientListComponent,
		DialogCompanyListComponent,
		DialogDriverListComponent,
		DialogEgressComponent,
		DialogFuelComponent,
		DialogFuelCancelComponent,
		DialogFuelListComponent,
		DialogIngressComponent,
		DialogIssuanceCancelComponent,
		DialogIssuanceListComponent,
		DialogLineListComponent,
		DialogMessageComponent,
		DialogPrintReportComponent,
		DialogQuestionComponent,
		DialogRecoverComponent,
		DialogRecoverListComponent,
		DialogRecoverPointListComponent,
		DialogRoleListComponent,
		DialogRouteListComponent,
		DialogSaleCardComponent,
		DialogThirdListComponent,
		DialogTurnCloseComponent,
		DialogTurnOpenComponent,
		DialogUserListComponent,
		DialogVehicleListComponent,
		DialogVoucherListComponent,
		WindowMoneyCalculatorComponent,
	],
	providers: [
		CurrencyPipe,
		httpInterceptor,
		{
			provide: LOCALE_ID,
			useValue: 'es-CO'
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
