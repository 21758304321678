import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { ChargeAccount } from 'src/app/models/charge-account';
import { ConceptDetail } from 'src/app/models/concept-detail';

import { DialogThirdListComponent } from 'src/app/dialogs/third-list/dialog-third-list.component';

import { NbDialogService, NbToastrService } from '@nebular/theme';
import { BasicService } from 'src/app/services/basic.service';
import { ChargeAccountService } from 'src/app/services/charge-account.service';
import { ThirdService } from 'src/app/services/third.service';

@Component({
	templateUrl: 'dialog-charge-account.component.html',
	styleUrls: ['dialog-charge-account.component.scss'],
})
export class DialogChargeAccountComponent implements OnInit {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Variables
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	private _chargeAccount: ChargeAccount;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	public loading = false;
	public triedAction = false;
	public chargeAccountForm: FormGroup;
	public concepts: Array<ConceptDetail>;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Constructor
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	constructor(private _formBuilder: FormBuilder, protected _dialogRef: NbDialogRef<DialogChargeAccountComponent>,
							private _dialogService: NbDialogService, private _toastrService: NbToastrService,
							private _basicService: BasicService, private _chargeAccountService: ChargeAccountService, private _thirdService: ThirdService) {
		this._chargeAccount = new ChargeAccount();
		this.concepts = new Array();
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Propios
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	ngOnInit(): void {
		this._buildForm();
		this.loading = true;
		this._basicService.findAllConcepts({
			code: 'CCO',
			this: 'activo, exclude',
		}).then(
			models => {
				this.loading = false;
				this.concepts = models;
			},
			exception => this._handleFailure(exception)
		);
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Eventos
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	public onSelectConcept(): void {
		this.chargeAccountForm.patchValue({
			third: {
				id: 0,
				fullName: '',
			},
		});
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Principales
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Obtiene el estado del control del formulario.
	 * @param controlName Nombre del control.
	 */
	public getStatus(controlName: string, triedAction?: boolean): string {
		const control = this.chargeAccountForm.get(controlName);
		if (!triedAction) {
			return control.dirty ? (control.invalid  ? 'danger' : 'basic') : 'basic';
		} else {
			return control.invalid ? 'danger' : 'basic';
		}
	}

	/**
	 * Crea el modelo cuenta.
	 */
	public createChargeAccount(): void {
		this.triedAction = true;
		if (!this.chargeAccountForm.invalid) {
			this.loading = true;
			this._setDataModel();
			this._chargeAccountService.create(this._chargeAccount).then(
				_response => {
					this.loading = false;
					this.triedAction = false;
					this._chargeAccount = new ChargeAccount();
					this._resetForm();
					this._handleMessage('Registro exitoso!', 'info');
				},
				exception => this._handleFailure(exception),
			);
		}
	}

	/**
	 * Muestra el diálogo de la lista modelo tercero.
	 */
	public showDialogThirds(): void {
		let tipo = '';
		this.loading = true;
		const codConcepto = this.chargeAccountForm.get('conceptCode').value;

		if (codConcepto === 'FCA') {
			tipo = 'USU';
		} else if (codConcepto !== 'OTR') {
			tipo = 'CON';
		}
		this._thirdService.findAllBy({
			type: tipo,
			this: 'activo, exclude',
		}).then(
			models => {
				this.loading = false;
				this._dialogService.open(DialogThirdListComponent, {
					context: {
						thirds: models,
					},
					closeOnBackdropClick: false,
				}).onClose.subscribe(model => {
					if (model != null) {
						this._chargeAccount.third = model;
						this.chargeAccountForm.patchValue({
							third: {
								id: this._chargeAccount.third.id,
								fullName: this._chargeAccount.third.names + ' ' + this._chargeAccount.third.surnames,
							}
						});
					}
				});
			},
			exception => this._handleFailure(exception),
		);
	}

	/**
	 * Cierra el diálogo.
	 */
	public close(model?: ChargeAccount): void {
		this._dialogRef.close(model);
	}

	/**
	 * Indica si no es válido el control del formulario.
	 * @param controlName Nombre del control.
	 */
	public isNotValid(controlName: string, triedAction?: boolean): boolean {
		const control = this.chargeAccountForm.get(controlName);
		if (!triedAction) {
			return control.dirty && control.invalid;
		} else {
			return control.invalid;
		}
	}

	/**
	 * Indica si no es válido el concepto del ingreso.
	 */
	public isConceptNotValid(code: string) {
		return code === '';
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Secundarios
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Cambia los datos del modelo cuenta.
	 */
	private _setDataModel(): void {
		this._chargeAccount.conceptCode = this.chargeAccountForm.get('conceptCode').value;
		this._chargeAccount.description = this.chargeAccountForm.get('description').value;
		this._chargeAccount.value = this.chargeAccountForm.get('value').value;
	}

	/**
	 * Construye el formulario.
	 */
	private _buildForm(): void {
		this.chargeAccountForm = this._formBuilder.group({
			id: 0,
			conceptCode: ['', [Validators.required]],
			description: ['', [Validators.required]],
			value: 0,
			third: this._formBuilder.group({
				id: 0,
				fullName: ['', [Validators.required]],
			}),
		});
	}

	/**
	 * Reinicia el formulario.
	 */
	private _resetForm(): void {
		this.chargeAccountForm.reset({
			id: 0,
			conceptCode: '',
			description: '',
			value: '',
			third: {
				id: 0,
				fullName: '',
			},
		});
	}

	/**
	 * Maneja el mensaje que se va a mostrar.
	 * @param type Tipo de mensaje.
	 */
	private _handleMessage(message: any, type: any) {
		this._toastrService.show(message, 'Cuenta de Cobro', {
			status: type,
			duration: 3000,
		});
	}

	/**
	 * Maneja la excepción que se ha generado.
	 */
	private _handleFailure(exception: any): void {
		this.loading = false;
		const mensaje = exception.status > 0 ? exception.error : exception.message;
		this._toastrService.show(mensaje, 'Cuenta de Cobro', {
			status: 'danger',
			duration: 5000,
		});
	}
}
