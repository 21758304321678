import { Serializable, JsonProperty, deserialize, serialize } from 'typescript-json-serializer';
import { Third } from './third';
import { Turn } from './turn';

/**
 * Clase que administra los datos del egreso.
 */
@Serializable()
export class Egress {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/** Id */
	@JsonProperty('id')
	public id: number;

	/** Número */
	@JsonProperty('numero')
	public number: string;

	/** Fecha y hora */
	@JsonProperty('fecha_hora')
	public dateTime: string;

	/** Código del concepto */
	@JsonProperty('cod_concepto')
	public conceptCode: string;

	/** Nombre del concepto */
	@JsonProperty('nom_concepto')
	public conceptName: string;

	/** Descripción */
	@JsonProperty('descripcion')
	public description: string;

	/** Valor */
	@JsonProperty('valor')
	public value: number;

	/** Conductor */
	@JsonProperty('tercero')
	public third: Third;

	/** Turno */
	@JsonProperty('turno')
	public turn: Turn;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Convierte un mapa json en un objeto de la clase.
	 */
	public static fromJson(json: any): Egress {
		return deserialize(json, Egress);
	}

	/**
	 * Convierte el objeto de la clase en un mapa json.
	 */
	public static toJson(model: Egress): any {
		return serialize(model);
	}
}
