import { Serializable, JsonProperty, deserialize, serialize } from 'typescript-json-serializer';
import { Permission } from './permission';
import { Submenu } from './submenu';

/**
 * Clase que administra los datos del menú.
 */
@Serializable()
export class Menu {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/** Id */
	@JsonProperty('id')
	public id: number;

	/** Nombre */
	@JsonProperty('nombre')
	public name: string;

	/** Nombre */
	@JsonProperty('tipo')
	public type: string;

	/** Icono */
	@JsonProperty('icono')
	public icon: string;

	/** Ruta */
	@JsonProperty('ruta')
	public path: string;

	/** Indica si esta activo */
	@JsonProperty('activo')
	public isActive = false;

	/** Lista permiso */
	@JsonProperty({name: 'permisos', type: Permission})
	public permissions: Array<Permission>;

	/** Lista submenú */
	@JsonProperty({name: 'submenus', type: Submenu})
	public submenus: Array<Submenu>;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Convierte un mapa json en un objeto de la clase.
	 */
	public static fromJson(json: any): Menu {
		return deserialize(json, Menu);
	}

	/**
	 * Convierte el objeto de la clase en un mapa json.
	 */
	public static toJson(model: Menu): any {
		return serialize(model);
	}
}
