import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { interval } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class VersionService {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Vairables
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	private _interval: Subscription;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	public currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';
	public version = '{{POST_BUILD_ENTERS_VERSION_HERE}}';

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Constructor
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	constructor(private _httpClient: HttpClient) { }

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Principales
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
   * Inicia la verificación de la versión de la aplicación.
   * @param url Url para verificar la versión de la aplicación.
   * @param frequency Frecuencia en milisegundos (5 minutos).
   */
	public startVersionChecking(url: string, frequency = 300000) {
		this._interval = interval(frequency).subscribe(_value => {
			this._checkVersion(url);
		});
	}

	/**
	 * Detiene la verificación de la versión de la aplicación.
	 */
	public stopVersionChecking() {
		this._interval.unsubscribe();
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Secundarios
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Verifica la versión de la aplicación.
	 * @param url Url para validar.
	 */
	public _checkVersion(url: string): void {
		this._httpClient.get(url + '?t=' + new Date().getTime()).subscribe(
			(response: any) => {
				if (this._hasHashChanged(this.currentHash, response.hash)) {
					this.currentHash = response.hash;
					this.version = response.version;
					this.stopVersionChecking();
					window.location.reload();
				}
			},
			error => console.error(error)
		);
	}

	/**
	 * Indica si tiene un hash diferente de la versión.
	 * @param currentHash Hash actual.
	 * @param newHash Hash nuevo.
	 */
	private _hasHashChanged(currentHash: string, newHash: string): boolean {
		if (!currentHash || currentHash === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
			return false;
		}
		return currentHash !== newHash;
	}
}
