import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { Parameter } from 'src/app/models/parameter';
import { SaleCard } from 'src/app/models/sale-card';
import { Turn } from 'src/app/models/turn';

import pdfMake from 'pdfmake/build/pdfmake';
import { PdfAssetsLoader } from 'pdfmake-utils';

import { DialogDriverListComponent } from '../driver-list/dialog-driver-list.component';
import { DialogVehicleListComponent } from '../vehicle-list/dialog-vehicle-list.component';

import { NbDialogService, NbToastrService } from '@nebular/theme';
import { DriverService } from 'src/app/services/driver.service';
import { ParameterService } from 'src/app/services/parameter.service';
import { PrintService } from 'src/app/services/print.service';
import { SaleCardService } from 'src/app/services/sale-card.service';
import { VehicleService } from 'src/app/services/vehicle.service';

@Component({
	templateUrl: 'dialog-sale-card.component.html',
	styleUrls: ['dialog-sale-card.component.scss'],
})
export class DialogSaleCardComponent implements OnInit {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Variables
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	private _parameter: Parameter;
	private _saleCard: SaleCard;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	public loading = false;
	public triedAction = false;
	public saleCardForm: FormGroup;

	@Input() public turn: Turn;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Constructor
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	constructor(private _formBuilder: FormBuilder, protected _dialogRef: NbDialogRef<DialogSaleCardComponent>,
							private _dialogService: NbDialogService, private _toastrService: NbToastrService,
							private _driverService: DriverService, private _parameterService: ParameterService,
							private _printService: PrintService, private _saleCardService: SaleCardService, private _vehicleService: VehicleService) {
		this._saleCard = new SaleCard();
		const assetsLoader = new PdfAssetsLoader();
		assetsLoader.registerFont({name: 'Courier', fileName: 'Courier', URL: 'assets/fonts/Courier.afm', styles: ['normal']});
		assetsLoader.registerFont({name: 'Courier', fileName: 'Courier-Bold', URL: 'assets/fonts/Courier-Bold.afm', styles: ['bold']});
		assetsLoader.load().then(() => {
			assetsLoader.configurePdfMake(pdfMake);
		}).catch((_error: any) => {
			assetsLoader.configurePdfMake(pdfMake);
		});
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Propios
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	ngOnInit(): void {
		this._buildForm();
		this.loading = true;
		this._parameterService.findBy({
			code: 'VALTARNAR',
			this: 'nombre, exclude',
		}).then(
			model => {
				this.loading = false;
				this._parameter = model;
				this.saleCardForm.patchValue({
					unitValue: model.value,
				});
			},
			exception => this._handleFailure(exception)
		);
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Principales
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Obtiene el estado del control del formulario.
	 * @param controlName Nombre del control.
	 */
	public getStatus(controlName: string, triedAction?: boolean): string {
		const control = this.saleCardForm.get(controlName);
		if (!triedAction) {
			return control.dirty ? (control.invalid  ? 'danger' : 'basic') : 'basic';
		} else {
			return control.invalid ? 'danger' : 'basic';
		}
	}

	/**
   * Crea el modelo venta.
   */
	public createSaleCard(): void {
		this.triedAction = true;
		if (!this.saleCardForm.invalid) {
			this.loading = true;
			this._setDataModel();
			this.calculateTotalValue();
			this._saleCardService.create(this._saleCard).then(
				response => {
					this._printService.printSaleCard(response, {
						point_id: this.turn.recoverPoint.id,
					}).then(
						reporte => {
							this.loading = false;
							this.triedAction = false;
							if (reporte.length > 0) {
								const documento = {
									content: {
										text: reporte,
										lineHeight: 1.5,
										preserveLeadingSpaces: true,
									},
									defaultStyle: {
										font: 'Courier',
										fontSize: 12,
										bold: true,
									},
									pageMargins: [10, 30, 0, 0],
								};
								pdfMake.createPdf(documento).open();
							}
							this._resetForm();
							this._handleMessage('Registro exitoso!', 'info');
						},
						exception => this._handleFailure(exception),
					);
				},
				exception => this._handleFailure(exception),
			);
		}
	}

	/**
   * Muestra el diálogo de la lista modelo conductor.
   */
	public showDialogDrivers(): void {
		this.loading = true;
		this._driverService.findActiveAll({
			this: 'activo, exclude',
		}).then(
			models => {
				this.loading = false;
				this._dialogService.open(DialogDriverListComponent, {
					context: {
						drivers: models,
					},
					closeOnEsc: true,
					closeOnBackdropClick: false,
				}).onClose.subscribe(model => {
					if (model != null) {
						this._saleCard.driver = model;
						this.saleCardForm.patchValue({
							driver: {
								id: this._saleCard.driver.id,
								fullName: this._saleCard.driver.names + ' ' + this._saleCard.driver.surnames,
							}
						});
					}
				});
			},
			exception => this._handleFailure(exception),
		);
	}

	/**
   * Muestra el diálogo de la lista modelo vehículo.
   */
	public showDialogVehicles(): void {
		this.loading = true;
		this._vehicleService.findActiveAll({
			this: 'activo, exclude',
		}).then(
			models => {
				this.loading = false;
				this._dialogService.open(DialogVehicleListComponent, {
					context: {
						vehicles: models,
					},
					closeOnEsc: true,
					closeOnBackdropClick: false,
				}).onClose.subscribe(model => {
					if (model != null) {
						this._saleCard.vehicle = model;
						this.saleCardForm.patchValue({
							vehicle: {
								id: this._saleCard.vehicle.id,
								fullName: this._saleCard.vehicle.internalNumber + ' - ' + this._saleCard.vehicle.plaque +
													' (' + this._saleCard.vehicle.company.name + ')',
							}
						});
					}
				});
			},
			exception => this._handleFailure(exception),
		);
	}

	/**
	 * Calcula el valor total.
	 */
	public calculateTotalValue(): void {
		const quantity = this.saleCardForm.get('quantity').value;
		const unitValue = this.saleCardForm.get('unitValue').value;
		this._saleCard.totalValue = quantity * unitValue;
		this.saleCardForm.patchValue({
			totalValue: this._saleCard.totalValue,
		});
	}

	/**
   * Cierra el diálogo.
   */
	public close(model?: SaleCard): void {
		this._dialogRef.close(model);
	}

	/**
	 * Indica si no es válido el control del formulario.
	 * @param controlName Nombre del control.
	 */
	public isNotValid(controlName: string, triedAction?: boolean): boolean {
		const control = this.saleCardForm.get(controlName);
		if (!triedAction) {
			return control.dirty && control.invalid;
		} else {
			return control.invalid;
		}
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Secundarios
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Cambia los datos del modelo cuenta.
	 */
	private _setDataModel(): void {
		this._saleCard.quantity = this.saleCardForm.get('quantity').value;
		this._saleCard.unitValue = this.saleCardForm.get('unitValue').value;
		this._saleCard.totalValue = this.saleCardForm.get('totalValue').value;
		this._saleCard.observation = this.saleCardForm.get('observation').value;
		this._saleCard.turn = this.turn;
		if (this._saleCard.observation != null) {
			if (this._saleCard.observation.trim().length === 0) {
				this._saleCard.observation = null;
			}
		}
	}

	/**
	 * Construye el formulario.
	 */
	private _buildForm(): void {
		this.saleCardForm = this._formBuilder.group({
			id: 0,
			quantity: [0, [this._checkNumber, Validators.required]],
			unitValue: [0, [this._checkNumber]],
			totalValue: [0, [this._checkNumber]],
			observation: null,
			vehicle: this._formBuilder.group({
				id: [0, [this._checkIdSize]],
				fullName: ['', [Validators.required]],
			}),
			driver: this._formBuilder.group({
				id: [0, [this._checkIdSize]],
				fullName: ['', [Validators.required]],
			}),
		});
	}

	/**
	 * Reinicia el formulario.
	 */
	private _resetForm(): void {
		this.saleCardForm.reset({
			id: 0,
			quantity: 0,
			unitValue: this._parameter.value,
			totalValue: 0,
			observation: null,
			vehicle: {
				id: 0,
				fullName: '',
			},
			driver: {
				id: 0,
				fullName: '',
			},
		});
	}

	/**
	 * Maneja el mensaje que se va a mostrar.
	 * @param type Tipo de mensaje.
	 */
	private _handleMessage(message: any, type: any) {
		this._toastrService.show(message, 'Tarjetas', {
			status: type,
			duration: 3000,
		});
	}

	/**
	 * Maneja la excepción que se ha generado.
	 */
	private _handleFailure(exception: any): void {
		this.loading = false;
		const mensaje = exception.status > 0 ? exception.error : exception.message;
		this._toastrService.show(mensaje, 'Tarjetas', {
			status: 'danger',
			duration: 5000,
		});
	}

	/**
	 * Chequea el tamaño del id.
	 */
	private _checkIdSize(control: FormControl) {
		const value: number = control.value;
		if (!value || value === 0) {
			return {
				idSize: true
			};
		}
		return null;
	}

	/**
	 * Chequea el tamaño del número.
	 */
	private _checkNumber(control: FormControl) {
		const value: number = control.value;
		if (!value || value === 0) {
			return {
				idSize: true
			};
		}
		return null;
	}
}
