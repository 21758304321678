<nb-card status="success">
	<nb-card-header>
		<div class="row m-0">
			<h5 class="title col p-0">Detalle Rodamiento</h5>
			<nb-icon class="close col-auto p-0" icon="close" (click)="close()"></nb-icon>
		</div>
	</nb-card-header>
	<nb-card-body>
		<form name="bearingDetailForm" [formGroup]="bearingDetailForm" novalidate>
			<div class="row m-0 mb-2">
				<div class="col-3 mr-1 p-0">
					<label class="label">Hora:</label>
					<input nbInput class="input" formControlName="time" type="time" fieldSize="medium" [status]="getStatus('time', triedAction)" fullWidth>
					<ng-container *ngIf="isNotValid('time', triedAction)">
						<p class="caption" *ngIf="bearingDetailForm.get('time').hasError('required')">Hora es requerida!</p>
					</ng-container>
				</div>
				<div class="col-4 ml-1 p-0">
					<label class="label">Estado:</label>
					<nb-select formControlName="stateCode" fullWidth>
						<nb-option [value]="state.code" [disabled]="isStateNotValid(state.code)" *ngFor="let state of states">{{state.name}}</nb-option>
					</nb-select>
				</div>
			</div>
			<div class="mb-2" formGroupName="vehicle">
				<label class="label">Vehículo:</label>
				<div class="row m-0">
					<div class="col-3 mr-1 p-0">
						<input nbInput class="input uppercase" formControlName="internalNumber" type="text" fieldSize="medium" fullWidth>
					</div>
					<div class="col-4 ml-1 p-0">
						<input nbInput class="input uppercase" formControlName="plaque" type="text" fieldSize="medium" fullWidth>
					</div>
					<div class="center-vertical" *ngIf="bearingDetailForm.get('id').value > 0 && isStateValid()">
						<nb-icon class="button-icon" icon="search" pack="font-awesome" (click)="showDialogVehicles()"></nb-icon>
					</div>
				</div>
				<div class="mt-2 p-0">
					<input nbInput class="input uppercase" formControlName="companyName" type="text" fieldSize="medium" fullWidth>
				</div>
			</div>
			<div class="mb-2" formGroupName="driver">
				<label class="label">Conductor:</label>
				<div class="row m-0">
					<div class="col p-0">
						<input nbInput class="input uppercase" formControlName="fullName" type="text" fieldSize="medium" fullWidth>
					</div>
					<div class="col-auto center-vertical p-0" *ngIf="bearingDetailForm.get('id').value > 0 && isStateValid()">
						<nb-icon class="button-icon" icon="search" pack="font-awesome" (click)="showDialogDrivers()"></nb-icon>
					</div>
				</div>
			</div>
			<div *ngIf="bearingDetailForm.get('id').value > 0 && isStateValid()">
				<label class="label">Observación:</label>
				<textarea nbInput class="input" formControlName="observation" type="text" [status]="getStatus('observation', triedAction)" fullWidth></textarea>
				<ng-container *ngIf="isNotValid('observation', triedAction)">
					<p class="caption" *ngIf="bearingDetailForm.get('observation').hasError('required')">Observación es requerida!</p>
				</ng-container>
			</div>
			<div class="center-horizontal mt-2" *ngIf="bearingDetailForm.get('id').value > 0 && isStateValid()">
				<button nbButton class="button" type="submit" size="small" shape="round" status="primary" (click)='updateBearingDetail()'>
					<nb-icon icon="save"></nb-icon>Modificar
				</button>
			</div>
		</form>
	</nb-card-body>
</nb-card>
<ngx-loading [show]="loading"></ngx-loading>