import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { LocalDataSource } from 'ng2-smart-table';
import { Benefit } from 'src/app/models/benefit';
import { BenefitDetail } from 'src/app/models/benefit-detail';
import { BenefitLot } from 'src/app/models/benefit-lot';
import { Line } from 'src/app/models/line';
import { Turn } from 'src/app/models/turn';

import pdfMake from 'pdfmake/build/pdfmake';
import { PdfAssetsLoader } from 'pdfmake-utils';

import { DialogDriverListComponent } from '../driver-list/dialog-driver-list.component';
import { DialogVehicleListComponent } from '../vehicle-list/dialog-vehicle-list.component';

import { NbToastrService } from '@nebular/theme';
import { BenefitService } from 'src/app/services/benefit.service';
import { DriverService } from 'src/app/services/driver.service';
import { LineService } from 'src/app/services/line.service';
import { PrintService } from 'src/app/services/print.service';
import { VehicleService } from 'src/app/services/vehicle.service';

@Component({
	templateUrl: './dialog-benefit.component.html',
	styleUrls: ['./dialog-benefit.component.scss'],
})
export class DialogBenefitComponent implements OnInit {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	private _benefit: Benefit;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	public loading = false;
	public triedAction = false;
	public settings: any;
	public source = new LocalDataSource();
	public benefitForm: FormGroup;
	public benefitLot: BenefitLot = null;
	public lines: Array<Line>;

	@Input() public turn: Turn;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Constructor
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	constructor(private _formBuilder: FormBuilder, protected _dialogRef: NbDialogRef<DialogBenefitComponent>,
				private _dialogService: NbDialogService, private _toastrService: NbToastrService,
				private _driverService: DriverService, private _benefitService: BenefitService,
				private _lineService: LineService, private _printService: PrintService, private _vehicleService: VehicleService) {
		this._benefit = new Benefit();
		this._benefit.details = new Array();
		this.lines = new Array();
		this.settings = {
			columns: {
				time: {
					title: 'Hora',
					type: 'text',
					width: '5%',
				},
				routeName: {
					title: 'Ruta',
					type: 'text',
				},
			},
			actions: {
				columnTitle: '',
				position: 'right',
				add: false,
				edit: false,
				delete: false,
			},
			edit: {
				editButtonContent: '<i class="fa fa-pen fa-xs" title="Editar"></i>',
			},
			delete: {
				deleteButtonContent: '<i class="fa fa-trash fa-xs" title="Eliminar"></i>',
			},
			attr: {
				class: 'table table-bordered'
			},
			pager: {
				perPage: 3,
			},
			mode: 'external',
			noDataMessage: '',
			defaultStyle: false,
			hideSubHeader: true,
		};
		const assetsLoader = new PdfAssetsLoader();
		assetsLoader.registerFont({name: 'Courier', fileName: 'Courier', URL: 'assets/fonts/Courier.afm', styles: ['normal']});
		assetsLoader.registerFont({name: 'Courier', fileName: 'Courier-Bold', URL: 'assets/fonts/Courier-Bold.afm', styles: ['bold']});
		assetsLoader.load().then(() => {
			assetsLoader.configurePdfMake(pdfMake);
		}).catch((_error: any) => {
			assetsLoader.configurePdfMake(pdfMake);
		});
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Propios
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	ngOnInit(): void {
		this._buildForm();
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Principales
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Obtiene el estado del control del formulario.
	 * @param controlName Nombre del control.
	 */
	public getStatus(controlName: string, triedAction?: boolean): string {
		const control = this.benefitForm.get(controlName);
		if (!triedAction) {
			return control.dirty ? (control.invalid  ? 'danger' : 'basic') : 'basic';
		} else {
			return control.invalid ? 'danger' : 'basic';
		}
	}

	/**
	 * Crea el modelo beneficio.
	 */
	public createBenefit(): void {
		this.triedAction = true;
		this._checkValidationCreate();
		if (!this.benefitForm.invalid) {
			this.loading = true;
			this._setDataModel();
			this._benefitService.create(this._benefit).then(
				response => {
					this._printService.printBenefit(response, {}).then(
						response => {
							this.loading = false;
							this.triedAction = false;
							this.removeData();
							const data = JSON.parse(response);
							const documento = {
								content: [
									{
										text: data.report,
										lineHeight: 1.5,
										preserveLeadingSpaces: true,
									},
									{
										qr: data.number,
										alignment: 'center',
										fit: '120',
									},
									{
										text: `\nVALIDO UNICAMENTE\n${data.date}`,
										fontSize: 20,
										alignment: 'center',
										preserveLeadingSpaces: true,
									},
									{
										text: `\nVALOR ${data.value}\n\n\n\n\n\n\n`,
										fontSize: 20,
										alignment: 'center',
										preserveLeadingSpaces: true,
									},
								],
								pageSize: {
									width: 310.0,
									height: 'auto'
								},
								defaultStyle: {
									font: 'Courier',
									fontSize: 12,
									bold: true,
								},
								pageMargins: [10, 30, 0, 0],
							};
							pdfMake.createPdf(documento).open();
							this._handleMessage('Registro exitoso!', 'info');
						},
						exception => this._handleFailure(exception),
					);
				},
				exception => this._handleFailure(exception),
			);
		}
	}

	/**
   * Busca la lista modelo linea.
   */
	public searchLines(): void {
		this.triedAction = true;
		this.benefitLot = null;
		this.lines = new Array();
		this._checkValidationSearch();
		if (!this.benefitForm.invalid) {
			this.loading = true;
			this._benefitService.findBy({
				driver_id: this._benefit.driver.id,
				vehicle_id: this._benefit.vehicle.id,
			}).then(
				model => {
					if (model.id === 0) {
						this._lineService.findAllBy({
							driver_id: this._benefit.driver.id,
							vehicle_id: this._benefit.vehicle.id,
							type: 'BEN',
							ruta: 'activo, exclude',
						}).then(
							models => {
								this.loading = false;
								this.lines = models;
								this.benefitForm.setControl('details', this._formBuilder.array([]));
								if (this.lines.length > 0) {
									this._benefit.details = new Array();
									for (const line of this.lines) {
										const detail = new BenefitDetail();
										detail.line = line;
										this._benefit.details.push(detail);
									}
									this.benefitForm.setControl('details', this._formBuilder.array(this._benefit.details.map(item => this._formBuilder.group(item))));
									this._loadData();
								} else {
									this.loading = false;
									this._handleMessage('No se ha pagado el mínimo de lineas para obtener el beneficio', 'danger');
								}
							},
							exception => this._handleFailure(exception),
						);
					} else {
						this.loading = false;
						this._handleMessage('Beneficio ya ha sido entregado!', 'info');
					}
				},
				exception => this._handleFailure(exception),
			);
		}
	}

	/**
	 * Muestra el diálogo de la lista modelo conductor.
	 */
	public showDialogDrivers(): void {
		this.loading = true;
		this._driverService.findActiveAll({
			this: 'activo, exclude',
		}).then(
			models => {
				this.loading = false;
				this._dialogService.open(DialogDriverListComponent, {
					context: {
						drivers: models,
					},
					closeOnEsc: true,
					closeOnBackdropClick: false,
				}).onClose.subscribe(model => {
					if (model != null) {
						this._benefit.driver = model;
						this.benefitForm.patchValue({
							driver: {
								id: this._benefit.driver.id,
								documentNumber: this._benefit.driver.documentNumber,
								fullName: this._benefit.driver.names + ' ' + this._benefit.driver.surnames,
							}
						});
					}
				});
			},
			exception => this._handleFailure(exception),
		);
	}

	/**
	 * Muestra el diálogo de la lista modelo vehículo.
	 */
	public showDialogVehicles(): void {
		this.loading = true;
		this._vehicleService.findActiveAll({
			this: 'activo, exclude',
			empresa: 'id, nombre, include',
		}).then(
			models => {
				this.loading = false;
				this._dialogService.open(DialogVehicleListComponent, {
					context: {
						vehicles: models,
					},
					closeOnEsc: true,
					closeOnBackdropClick: false,
				}).onClose.subscribe(model => {
					if (model != null) {
						this._benefit.vehicle = model;
						this._benefit.details = new Array();
						this.source.load(new Array());
						this.benefitForm.patchValue({
							vehicle: {
								id: this._benefit.vehicle.id,
								internalNumber: this._benefit.vehicle.internalNumber,
								plaque: this._benefit.vehicle.plaque,
								companyName: this._benefit.vehicle.company.name,
							}
						});
						this.benefitForm.setControl('details', this._formBuilder.array([]));
					}
				});
			},
			exception => this._handleFailure(exception),
		);
	}

	/**
	 * Elimina los datos.
	 */
	public removeData(): void {
		this.triedAction = false;
		this.lines = new Array();
		this.benefitLot = null;
		this.benefitForm.get('driver.documentNumber').setValidators(null);
		this.benefitForm.get('driver.fullName').setValidators(null);
		this.benefitForm.get('observation').setValidators(null);
		this.benefitForm.get('details').setValidators(null);
		this.benefitForm.get('driver.documentNumber').updateValueAndValidity();
		this.benefitForm.get('driver.fullName').updateValueAndValidity();
		this.benefitForm.get('observation').updateValueAndValidity();
		this.benefitForm.get('details').updateValueAndValidity();
		this.benefitForm.updateValueAndValidity();
		this._clearForm();
	}

	/**
   * Cierra el diálogo.
   */
	public close(models?: Array<Line>): void {
		this._dialogRef.close(models);
	}

	/**
	 * Indica si no es válido el control del formulario.
	 * @param controlName Nombre del control.
	 */
	public isNotValid(controlName: string, triedAction?: boolean): boolean {
		const control = this.benefitForm.get(controlName);
		if (!triedAction) {
			return control.dirty && control.invalid;
		} else {
			return control.invalid;
		}
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Secundarios
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Cambia los datos del modelo ingreso.
	 */
	private _setDataModel(): void {
		this._benefit.observation = this.benefitForm.get('observation').value;
		this._benefit.turn = this.turn;
		if (this._benefit.observation != null) {
			if (this._benefit.observation.trim().length === 0) {
				this._benefit.observation = null;
			}
		}
	}

	/**
	 * Construye el formulario.
	 */
	private _buildForm(): void {
		this.benefitForm = this._formBuilder.group({
			id: 0,
			observation: '',
			driver: this._formBuilder.group({
				id: 0,
				documentNumber: ['', [Validators.required]],
				fullName: ['', [Validators.required]],
			}),
			vehicle: this._formBuilder.group({
				id: 0,
				internalNumber: ['', [Validators.required]],
				plaque: ['', [Validators.required]],
				companyName: ['', [Validators.required]],
			}),
			details: this._formBuilder.array(
				[], Validators.required
			),
		});
	}

	/**
	 * Limpia el formulario.
	 */
	private _clearForm(): void {
		this._benefit.id = 0;
		this._benefit.driver = null;
		this._benefit.vehicle = null;
		this._benefit.details = new Array();
		this.source.load(new Array());
		this.benefitForm.patchValue({
			id: 0,
			observation: '',
			driver: {
				id: 0,
				documentNumber: '',
				fullName: '',
			},
			vehicle: {
				id: 0,
				internalNumber: '',
				plaque: '',
				companyName: '',
			},
		});
		this.benefitForm.setControl('details', this._formBuilder.array([]));
		this.benefitForm.get('observation').enable();
	}

	/**
	 * Carga la fuente de datos de la tabla.
	 */
	private _loadData(): void {
		const items = Array<any>();
		this.lines.forEach((model) => {
			items.push({
				id: model.id,
				time: model.time,
				routeName: model.route.name,
			});
		});
		this.source.load(items);
	}

	/**
	 * Maneja el mensaje que se va a mostrar.
	 * @param type Tipo de mensaje.
	 */
	private _handleMessage(message: any, type: any) {
		this._toastrService.show(message, 'Beneficio', {
			status: type,
			duration: type !== 'info' ? 5000 : 3000,
		});
	}

	/**
	 * Maneja la excepción que se ha generado.
	 */
	private _handleFailure(exception: any): void {
		this.loading = false;
		const mensaje = exception.status > 0 ? exception.error : exception.message;
		this._toastrService.show(mensaje, 'Beneficio', {
			status: 'danger',
			duration: 5000,
		});
	}

	/**
	 * Verifica la validación del formulario.
	 */
	private _checkValidationSearch(): void {
		if (this._benefit.driver == null || this._benefit.vehicle == null || this.lines.length === 0) {
			this.benefitForm.get('details').setValidators(null);
		}
		this.benefitForm.get('details').updateValueAndValidity();
		this.benefitForm.updateValueAndValidity();
	}

	/**
	 * Verifica la validación del formulario.
	 */
	private _checkValidationCreate(): void {
		if (this._benefit.driver != null && this._benefit.vehicle != null && this.lines.length > 0) {
			this.benefitForm.get('details').setValidators([Validators.required]);
		}
		this.benefitForm.get('details').updateValueAndValidity();
		this.benefitForm.updateValueAndValidity();
	}
}
