import { Serializable, JsonProperty, deserialize, serialize } from 'typescript-json-serializer';
import { Permission } from './permission';

/**
 * Clase que administra los datos del submenú.
 */
@Serializable()
export class Submenu {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/** Id */
	@JsonProperty('id')
	public id: number;

	/** Nombre */
	@JsonProperty('nombre')
	public name: string;

	/** Ruta */
	@JsonProperty('ruta')
	public path: string;

	/** Indica si esta activo */
	@JsonProperty('activo')
	public isActive = false;

	/** Lista permiso */
	@JsonProperty({name: 'permisos', type: Permission})
	public permissions: Array<Permission>;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Convierte un mapa json en un objeto de la clase.
	 */
	public static fromJson(json: any): Submenu {
		return deserialize(json, Submenu);
	}

	/**
	 * Convierte el objeto de la clase en un mapa json.
	 */
	public static toJson(model: Submenu): any {
		return serialize(model);
	}
}
