import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Egress } from '../models/egress';

import { APPLICATION_JSON, URL_HOST } from '../../app';

import { AuthenticationService } from './authentication.service';

@Injectable({
	providedIn: 'root'
})
export class EgressService {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Variables
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	private _url = `${URL_HOST}`;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Constructor
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	constructor(private _httpClient: HttpClient, private _authService: AuthenticationService) { }

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Principales
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
   * Crea el modelo egreso.
   * @param model Modelo egreso.
   */
	public create(model: Egress): Promise<number> {
		return new Promise((resolve, reject) => {
			this._authService.getToken().then(
				token => {
					const httpHeaders = new HttpHeaders({
						'Content-Type': APPLICATION_JSON,
						Authorization: token
					});
					this._httpClient.post<any>(`${this._url}/egress`, Egress.toJson(model), {headers: httpHeaders}).toPromise().then(
						response => resolve(response),
						exception => reject(exception)
					);
				},
				error => reject(error)
			);
		});
	}
}
