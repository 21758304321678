import { Serializable, JsonProperty, deserialize, serialize } from 'typescript-json-serializer';

/**
 * Clase que administra los datos de la vuelta.
 */
@Serializable()
export class Lap {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/** Número */
	@JsonProperty('Vuelta')
	public number: number;

	/** Hora inicial */
	@JsonProperty('Inicio')
	public initialTime: string;

	/** Hora final */
	@JsonProperty('Fin')
	public finalTime: string;

	/** Cantidad de tarjetas usuario */
	@JsonProperty('Tarjetas')
	public userCardsAmount: number;

	/** Cantidad de tarjetas operador */
	@JsonProperty('Tarjetas_operador')
	public operatorCardsAmount: number;

	/** Cantidad de pasajeros */
	@JsonProperty('Pasaje')
	public passengersAmount: number;

	/** Valor tarjetas usuario */
	@JsonProperty('Monto')
	public userCardsValue: number;

	/** Valor tarjetas operador */
	@JsonProperty('Monto_operador')
	public operatorCardsValue: number;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Convierte un mapa json en un objeto de la clase.
	 */
	public static fromJson(json: any): Lap {
		return deserialize(json, Lap);
	}

	/**
	 * Convierte el objeto de la clase en un mapa json.
	 */
	public static toJson(model: Lap): any {
		return serialize(model);
	}
}
