import { Serializable, JsonProperty, deserialize, serialize } from 'typescript-json-serializer';
import { Third } from './third';
import { Turn } from './turn';

/**
 * Clase que administra los datos del ingreso.
 */
@Serializable()
export class Ingress {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/** Id */
	@JsonProperty('id')
	public id: number;

	/** Número */
	@JsonProperty('numero')
	public number: string;

	/** Número de la cuenta de cobtro */
	@JsonProperty('num_cuenta_cobro')
	public accountNumber: string;

	/** Fecha y hora */
	@JsonProperty('fecha_hora')
	public dateTime: string;

	/** Código del concepto */
	@JsonProperty('cod_concepto')
	public conceptCode: string;

	/** Nombre del concepto */
	@JsonProperty('nom_concepto')
	public conceptName: string;

	/** Descripción */
	@JsonProperty('descripcion')
	public description: string;

	/** Valor */
	@JsonProperty('valor')
	public value: number;

	/** Indica si es pago parcial */
	public isPayPartial: boolean;

	/** Conductor */
	@JsonProperty('tercero')
	public third: Third;

	/** Turno */
	@JsonProperty('turno')
	public turn: Turn;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Convierte un mapa json en un objeto de la clase.
	 */
	public static fromJson(json: any): Ingress {
		return deserialize(json, Ingress);
	}

	/**
	 * Convierte el objeto de la clase en un mapa json.
	 */
	public static toJson(model: Ingress): any {
		return serialize(model);
	}
}
