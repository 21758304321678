import { Component, OnInit, Input } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';

@Component({
	selector: 'sts-checkbox',
	templateUrl: './checkbox.component.html',
})
export class CheckboxComponent implements OnInit, ViewCell {
 	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	public checked: boolean;

	@Input() public value: any;
	@Input() public rowData: any;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Constructor
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	constructor() { }

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Propios
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	ngOnInit() {
		this.checked = this.value;
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Principales
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Cambiar el valor del check.
	 */
	public changeValue(value: boolean): void {
		this.checked = value;
		this.value = this.checked;
		this.rowData.active = this.value;
 	}
}
