import { Component, OnInit, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
	templateUrl: 'dialog-question.component.html',
	styleUrls: ['dialog-question.component.scss'],
})
export class DialogQuestionComponent implements OnInit {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	@Input() public title: string;
	@Input() public message: string;
	@Input() public textButtonAccept: string;
	@Input() public textButtonCancel: string;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Constructor
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	constructor(protected _dialogRef: NbDialogRef<DialogQuestionComponent>) { }

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Funciones Propias
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	ngOnInit(): void {
		if (this.textButtonAccept == null) {
			this.textButtonAccept = 'Aceptar';
		}
		if (this.textButtonCancel == null) {
			this.textButtonCancel = 'Cancelar';
		}
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Principales
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
   * Cierra el diálogo.
   */
	public close(confirm: boolean): void {
		this._dialogRef.close(confirm);
	}
}
