import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { Benefit } from 'src/app/models/benefit';
import { Line } from 'src/app/models/line';
import { Turn } from 'src/app/models/turn';

import { NbToastrService } from '@nebular/theme';
import { BenefitService } from 'src/app/services/benefit.service';

@Component({
	templateUrl: './dialog-benefit-cancel.component.html',
	styleUrls: ['./dialog-benefit-cancel.component.scss'],
})
export class DialogBenefitCancelComponent implements OnInit {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	private _benefit: Benefit;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	public loading = false;
	public triedAction = false;
	public benefitForm: FormGroup;

	@Input() public turn: Turn;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Constructor
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	constructor(private _formBuilder: FormBuilder, private _datepipe: DatePipe, protected _dialogRef: NbDialogRef<DialogBenefitCancelComponent>,
				private _toastrService: NbToastrService, private _benefitService: BenefitService) {
		this._benefit = new Benefit();
		this._benefit.details = new Array();
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Propios
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	ngOnInit(): void {
		this._buildForm();
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Principales
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
   * Actualiza el modelo beneficio.
   */
	public updateBenefit(): void {
		this.triedAction = true;
		this._checkValidationUpdate();
		if (!this.benefitForm.invalid) {
			this.loading = true;
			this._setDataModel();
			this._benefitService.update(this._benefit).then(
				_response => {
					this.loading = false;
					this.triedAction = false;
					this.removeData();
					this._handleMessage('Actualización exitosa!', 'info');
				},
				exception => this._handleFailure(exception),
			);
		}
	}

	/**
   * Busca el modelo beneficio.
   */
	public searchBenefit(): void {
		this.triedAction = true;
		this._checkValidationSearch();
		if (!this.benefitForm.invalid) {
			this.loading = true;
			this._benefitService.findBy({
				number: this.benefitForm.get('number').value.toUpperCase(),
				appType: 'WEB',
				detalles: 'linea, exclude',
			}).then(
				model => {
					this.loading = false;
					this._benefit = model;
					this._fillForm();
				},
				exception => this._handleFailure(exception),
			);
		}
	}

	/**
	 * Elimina los datos.
	 */
	public removeData(): void {
		this.triedAction = false;
		this._clearForm();
	}

	/**
   * Cierra el diálogo.
   */
	public close(models?: Array<Line>): void {
		this._dialogRef.close(models);
	}

	/**
	 * Indica si no es válido el control del formulario.
	 * @param controlName Nombre del control.
	 */
	public isNotValid(controlName: string, triedAction?: boolean): boolean {
		const control = this.benefitForm.get(controlName);
		if (!triedAction) {
			return control.dirty && control.invalid;
		} else {
			return control.invalid;
		}
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Secundarios
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Cambia los datos del modelo ingreso.
	 */
	private _setDataModel(): void {
		this._benefit.cancelObservation = this.benefitForm.get('observation').value;
		this._benefit.cancelTurn = this.turn;
	}

	/**
	 * Construye el formulario.
	 */
	private _buildForm(): void {
		this.benefitForm = this._formBuilder.group({
			id: 0,
			number: ['', [Validators.required]],
			observation: ['', [Validators.required]],
			driver: this._formBuilder.group({
				id: 0,
				documentNumber: '',
				fullName: '',
			}),
			vehicle: this._formBuilder.group({
				id: 0,
				internalNumber: '',
				plaque: '',
				companyName: '',
			}),
		});
	}

	/**
	 * Llena el formulario.
	 */
	private _fillForm(): void {
		this.benefitForm.setValue({
			id: this._benefit.id,
			number: `${this._benefit.prefix}-${this._benefit.number}`,
			observation: '',
			driver: {
				id: this._benefit.driver.id,
				documentNumber: this._benefit.driver.documentNumber,
				fullName: this._benefit.driver.names + ' ' + this._benefit.driver.surnames,
			},
			vehicle: {
				id: this._benefit.vehicle.id,
				internalNumber: this._benefit.vehicle.internalNumber,
				plaque: this._benefit.vehicle.plaque,
				companyName: this._benefit.vehicle.company.name,
			}
		});
		this.benefitForm.get('number').disable();
	}

	/**
	 * Limpia el formulario.
	 */
	private _clearForm(): void {
		this._benefit.id = 0;
		this.benefitForm.patchValue({
			id: 0,
			number: '',
			observation: '',
			driver: {
				id: 0,
				documentNumber: '',
				fullName: '',
			},
			vehicle: {
				id: 0,
				internalNumber: '',
				plaque: '',
				companyName: '',
			},
		});
		this.benefitForm.get('number').enable();
	}

	/**
	 * Maneja el mensaje que se va a mostrar.
	 * @param type Tipo de mensaje.
	 */
	private _handleMessage(message: any, type: any) {
		this._toastrService.show(message, 'Anular Beneficio', {
			status: type,
			duration: 3000,
		});
	}

	/**
	 * Maneja la excepción que se ha generado.
	 */
	private _handleFailure(exception: any): void {
		this.loading = false;
		const mensaje = exception.status > 0 ? exception.error : exception.message;
		this._toastrService.show(mensaje, 'Anular Beneficio', {
			status: 'danger',
			duration: 5000,
		});
	}

	/**
	 * Verifica si la validación es requerida.
	 */
	private _checkValidationSearch(): void {
		if (this.benefitForm.get('id').value === 0) {
			this.benefitForm.get('observation').setValidators(null);
		}
		this.benefitForm.get('observation').updateValueAndValidity();
		this.benefitForm.updateValueAndValidity();
	}

	/**
	 * Verifica si la validación es requerida.
	 */
	private _checkValidationUpdate(): void {
		if (this.benefitForm.get('id').value > 0) {
			this.benefitForm.get('observation').setValidators([Validators.required]);
		}
		this.benefitForm.get('observation').updateValueAndValidity();
		this.benefitForm.updateValueAndValidity();
	}
}
