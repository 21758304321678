import { Serializable, JsonProperty, deserialize, serialize } from 'typescript-json-serializer';
import { Menu } from './menu';

/**
 * Clase que administra los datos del módulo.
 */
@Serializable()
export class Module {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/** Id */
	@JsonProperty('id')
	public id: number;

	/** Nombre */
	@JsonProperty('nombre')
	public name: string;

	/** Icono */
	@JsonProperty('icono')
	public icon: string;

	/** Lista menú */
	@JsonProperty({name: 'menus', type: Menu})
	public menus: Array<Menu>;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Convierte un mapa json en un objeto de la clase.
	 */
	public static fromJson(json: any): Module {
		return deserialize(json, Module);
	}

	/**
	 * Convierte el objeto de la clase en un mapa json.
	 */
	public static toJson(model: Module): any {
		return serialize(model);
	}
}
