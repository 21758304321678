<nb-card status="success">
	<nb-card-header>
		<div class="row m-0">
			<h5 class="title col p-0">Anular Combustible</h5>
			<nb-icon class="close col-auto p-0" icon="close" (click)="close()"></nb-icon>
		</div>
	</nb-card-header>
	<nb-card-body>
		<form name="fuelForm" [formGroup]="fuelForm" novalidate>
			<div class="row m-0 mb-2">
				<div class="col-6 p-0 pr-1">
					<label class="label">N° Orden de Servicio:</label>
					<input nbInput class="input uppercase" formControlName="number" type="text" fieldSize="medium" fullWidth>
					<ng-container *ngIf="isNotValid('number', triedAction)">
						<p class="caption" *ngIf="fuelForm.get('number').hasError('required')">Número es requerido!</p>
					</ng-container>
				</div>
				<div class="col-6 p-0 pl-1">
					<label class="label">Fecha:</label>
					<input nbInput class="input uppercase" formControlName="date" type="text" fieldSize="medium" maxlength="0" fullWidth>
				</div>
			</div>
			<div class="mb-2" formGroupName="vehicle">
				<label class="label">Vehículo:</label>
				<div class="row m-0 mb-2">
					<div class="col p-0 pr-1">
						<input nbInput class="input uppercase" formControlName="internalNumber" type="text" fieldSize="medium" [attr.disabled]="true" fullWidth>
					</div>
					<div class="col p-0 pl-1">
						<input nbInput class="input uppercase" formControlName="plaque" type="text" fieldSize="medium" [attr.disabled]="true" fullWidth>
					</div>
				</div>
				<input nbInput class="input uppercase" formControlName="companyName" type="text" fieldSize="medium" [attr.disabled]="true" fullWidth>
			</div>
			<div class="mb-2" formGroupName="driver">
				<label class="label">Conductor:</label>
				<input nbInput class="input uppercase" formControlName="fullName" type="text" fieldSize="medium" [attr.disabled]="true" fullWidth>
			</div>
			<div class="row m-0 mb-2">
				<div class="col-5 p-0 pr-2">
					<label class="label">Cant. Galones:</label>
					<input nbInput class="input" formControlName="gallonsAmount" type="tel" fieldSize="medium" fullWidth>
				</div>
				<div class="col-7 p-0">
					<label class="label">Valor Total:</label>
					<input nbInput class="input" formControlName="totalValue" type="tel" fieldSize="medium" currencyMask fullWidth>
				</div>
			</div>
			<div *ngIf="fuelForm.get('id').value > 0">
				<label class="label">Motivo:</label>
				<textarea nbInput class="input" formControlName="observation" type="text" fullWidth></textarea>
				<ng-container *ngIf="isNotValid('observation', triedAction)">
					<p class="caption" *ngIf="fuelForm.get('observation').hasError('required')">Motivo es requerido!</p>
				</ng-container>
			</div>
			<div class="center-horizontal mt-3">
				<button nbButton class="button" type="submit" size="small" shape="round" status="primary" (click)="updateFuel()" *ngIf="fuelForm.get('id').value > 0">
					<nb-icon icon="save"></nb-icon>Anular
				</button>
				<button nbButton class="button" type="submit" size="small" shape="round" status="primary" (click)='searchFuel()' *ngIf="fuelForm.get('id').value == 0">
					<nb-icon icon="search"></nb-icon>Buscar
				</button>
				<button nbButton class="button" type="submit" size="small" shape="round" status="primary" (click)="removeData()">
					<nb-icon icon="trash"></nb-icon>Limpiar
				</button>
			</div>
		</form>
	</nb-card-body>
</nb-card>
<ngx-loading [show]="loading"></ngx-loading>