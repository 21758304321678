import { Serializable, JsonProperty, deserialize, serialize } from 'typescript-json-serializer';
import { Line } from './line';

/**
 * Clase que administra los datos del detalle del beneficio.
 */
@Serializable()
export class BenefitDetail {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/** Id */
	@JsonProperty('id')
	public id: number;

	/** Id del beneficio */
	@JsonProperty('id_beneficio')
	public idBenefit: number;

	/** Línea */
	@JsonProperty('linea')
	public line: Line;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Convierte un mapa json en un objeto de la clase.
	 */
	public static fromJson(json: any): BenefitDetail {
		return deserialize(json, BenefitDetail);
	}

	/**
	 * Convierte el objeto de la clase en un mapa json.
	 */
	public static toJson(model: BenefitDetail): any {
		return serialize(model);
	}
}
