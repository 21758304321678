import { Serializable, JsonProperty, deserialize, serialize } from 'typescript-json-serializer';
import { Line } from './line';

/**
 * Clase que administra los datos del detalle del combustible.
 */
@Serializable()
export class FuelDetail {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/** Id */
	@JsonProperty('id')
	public id: number;

	/** Id del combustible */
	@JsonProperty('id_combustible')
	public idFuel: number;

	/** Cantidad de galones */
	@JsonProperty('cant_galones')
	public gallonsAmount: number;

	/** Valor del galón */
	@JsonProperty('valor_galon')
	public gallonValue: number;

	/** Valor total */
	@JsonProperty('valor_total')
	public totalValue: number;

	/** Línea */
	@JsonProperty('linea')
	public line: Line;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Convierte un mapa json en un objeto de la clase.
	 */
	public static fromJson(json: any): FuelDetail {
		return deserialize(json, FuelDetail);
	}

	/**
	 * Convierte el objeto de la clase en un mapa json.
	 */
	public static toJson(model: FuelDetail): any {
		return serialize(model);
	}
}
