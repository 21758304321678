import { Serializable, JsonProperty, deserialize, serialize } from 'typescript-json-serializer';
import { Line } from './line';

/**
 * Clase que administra los datos del detalle de la autorización del combustible.
 */
@Serializable()
export class FuelAuthorizeDetail {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/** Id */
	@JsonProperty('id')
	public id: number;

	/** Id de la autorización de combustible */
	@JsonProperty('id_autoriza')
	public idAuthorizeFuel: number;

	/** Línea */
	@JsonProperty('linea')
	public line: Line;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Convierte un mapa json en un objeto de la clase.
	 */
	public static fromJson(json: any): FuelAuthorizeDetail {
		return deserialize(json, FuelAuthorizeDetail);
	}

	/**
	 * Convierte el objeto de la clase en un mapa json.
	 */
	public static toJson(model: FuelAuthorizeDetail): any {
		return serialize(model);
	}
}
