import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { Issuance } from 'src/app/models/issuance';
import { Turn } from 'src/app/models/turn';

import { NbToastrService } from '@nebular/theme';
import { IssuanceService } from 'src/app/services/issuance.service';

@Component({
	templateUrl: './dialog-issuance-cancel.component.html',
	styleUrls: ['./dialog-issuance-cancel.component.scss'],
})
export class DialogIssuanceCancelComponent implements OnInit {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	private _issuance: Issuance;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	public loading = false;
	public triedAction = false;
	public issuanceForm: FormGroup;

	@Input() public turn: Turn;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Constructor
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	constructor(private _formBuilder: FormBuilder, protected _dialogRef: NbDialogRef<DialogIssuanceCancelComponent>,
				private _toastrService: NbToastrService, private _issuanceService: IssuanceService) {
		this._issuance = new Issuance();
		this._issuance.details = new Array();
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Propios
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	ngOnInit(): void {
		this._buildForm();
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Principales
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Actualiza el modelo emisión.
	 */
	public updateIssuance(): void {
		this.triedAction = true;
		this._checkValidationUpdate();
		if (!this.issuanceForm.invalid) {
			this.loading = true;
			this._setDataModel();
			this._issuanceService.update(this._issuance).then(
				_response => {
					this.loading = false;
					this.triedAction = false;
					this.removeData();
					this._handleMessage('Actualización exitosa!', 'info');
				},
				exception => this._handleFailure(exception),
			);
		}
	}

	/**
	 * Busca el modelo emisión.
	 */
	public searchIssuance(): void {
		this.triedAction = true;
		this._checkValidationSearch();
		if (!this.issuanceForm.invalid) {
			this.loading = true;
			this._issuanceService.findBy({
				number: this.issuanceForm.get('number').value.toUpperCase(),
			}).then(
				model => {
					this.loading = false;
					this._issuance = model;
					this._fillForm();
				},
				exception => this._handleFailure(exception),
			);
		}
	}

	/**
	 * Elimina los datos.
	 */
	public removeData(): void {
		this.triedAction = false;
		this._clearForm();
	}

	/**
	 * Cierra el diálogo.
	 */
	public close(): void {
		this._dialogRef.close();
	}

	/**
	 * Indica si no es válido el control del formulario.
	 * @param controlName Nombre del control.
	 */
	public isNotValid(controlName: string, triedAction?: boolean): boolean {
		const control = this.issuanceForm.get(controlName);
		if (!triedAction) {
			return control.dirty && control.invalid;
		} else {
			return control.invalid;
		}
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Secundarios
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Cambia los datos del modelo ingreso.
	 */
	private _setDataModel(): void {
		this._issuance.cancelObservation = this.issuanceForm.get('observation').value;
		this._issuance.cancelTurn = this.turn;
	}

	/**
	 * Construye el formulario.
	 */
	private _buildForm(): void {
		this.issuanceForm = this._formBuilder.group({
			id: 0,
			number: ['', [Validators.required]],
			cardNumber: {value: '', disabled: true},
			totalValue: {value: 0, disabled: true},
			observation: ['', [Validators.required]],
			client: this._formBuilder.group({
				id: 0,
				document: '',
				fullName: '',
			}),
		});
	}

	/**
	 * Llena el formulario.
	 */
	private _fillForm(): void {
		this.issuanceForm.setValue({
			id: this._issuance.id,
			number: this._issuance.number,
			cardNumber: this._issuance.cardNumber,
			totalValue: this._issuance.totalValue,
			observation: '',
			client: {
				id: this._issuance.client.id,
				document: this._issuance.client.document,
				fullName: this._issuance.client.names + ' ' + this._issuance.client.surnames,
			},
		});
		this.issuanceForm.get('number').disable();
	}

	/**
	 * Limpia el formulario.
	 */
	private _clearForm(): void {
		this._issuance.id = 0;
		this.issuanceForm.patchValue({
			id: 0,
			number: '',
			cardNumber: '',
			totalValue: 0,
			observation: '',
			client: {
				id: 0,
				document: '',
				fullName: '',
			},
		});
		this.issuanceForm.get('number').enable();
	}

	/**
	 * Maneja el mensaje que se va a mostrar.
	 * @param type Tipo de mensaje.
	 */
	private _handleMessage(message: any, type: any) {
		this._toastrService.show(message, 'Anular Emisión', {
			status: type,
			duration: 3000,
		});
	}

	/**
	 * Maneja la excepción que se ha generado.
	 */
	private _handleFailure(exception: any): void {
		this.loading = false;
		const mensaje = exception.status > 0 ? exception.error : exception.message;
		this._toastrService.show(mensaje, 'Anular Emisión', {
			status: 'danger',
			duration: 5000,
		});
	}

	/**
	 * Verifica si la validación es requerida.
	 */
	private _checkValidationSearch(): void {
		if (this.issuanceForm.get('id').value === 0) {
			this.issuanceForm.get('observation').setValidators(null);
		}
		this.issuanceForm.get('observation').updateValueAndValidity();
		this.issuanceForm.updateValueAndValidity();
	}

	/**
	 * Verifica si la validación es requerida.
	 */
	private _checkValidationUpdate(): void {
		if (this.issuanceForm.get('id').value > 0) {
			this.issuanceForm.get('observation').setValidators([Validators.required]);
		}
		this.issuanceForm.get('observation').updateValueAndValidity();
		this.issuanceForm.updateValueAndValidity();
	}
}
