<nb-layout windowMode>
    <nb-layout-header class="header" subheader>
		<sts-header></sts-header>
	</nb-layout-header>
    <nb-sidebar class="sidebar" tag="sidebar" responsive>
		<img class="logo" width="100%" src="./assets/images/banner.png">
        <ng-content select="nb-menu"></ng-content>
    </nb-sidebar>
    <nb-layout-column>
        <ng-content select="router-outlet"></ng-content>
	</nb-layout-column>
	<nb-layout-footer class="footer" fixed>
	</nb-layout-footer>
</nb-layout>