import { Serializable, JsonProperty, deserialize, serialize } from 'typescript-json-serializer';
import { Driver } from './driver';
import { Vehicle } from './vehicle';

/**
 * Clase que administra los datos del detalle del rodamiento.
 */
@Serializable()
export class BearingDetail {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/** Id */
	@JsonProperty('id')
	public id: number;

	/** Id del rodamiento */
	@JsonProperty('id_rodamiento')
	public idBearing: number;

	/** Número del rodamiento */
	@JsonProperty('num_rodamiento')
	public bearingNumber: string;

	/** Número de la planilla */
	@JsonProperty('num_planilla')
	public dispatchNumber: string;

	/** Número del vehículo */
	@JsonProperty('num_vehiculo')
	public vehicleNumber: number;

	/** Número de documento del conductor */
	@JsonProperty('num_doc_conductor')
	public driverDocumentNumber: string;

	/** Hora */
	@JsonProperty('hora')
	public time: string;

	/** Código del estado */
	@JsonProperty('cod_estado')
	public stateCode: string;

	/** Nombre del estado */
	@JsonProperty('nom_estado')
	public stateName: string;

	/** Observación */
	@JsonProperty('observacion')
	public observation: string;

	/** Vehículo */
	@JsonProperty('vehiculo')
	public vehicle: Vehicle;

	/** Conductor */
	@JsonProperty('conductor')
	public driver: Driver;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Convierte un mapa json en un objeto de la clase.
	 */
	public static fromJson(json: any): BearingDetail {
		return deserialize(json, BearingDetail);
	}

	/**
	 * Convierte el objeto de la clase en un mapa json.
	 */
	public static toJson(model: BearingDetail): any {
		return serialize(model);
	}
}
