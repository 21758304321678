import { Serializable, JsonProperty, deserialize, serialize } from 'typescript-json-serializer';
import { Driver } from './driver';
import { Lap } from './lap';
import { Route } from './route';
import { Vehicle } from './vehicle';

/**
 * Clase que administra los datos de la línea.
 */
@Serializable()
export class Line {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/** Id */
	@JsonProperty('id')
	public id: number;

	/** Número de rodamiento */
	@JsonProperty('num_rodamiento')
	public bearingNumber: string;

	/** Fecha */
	@JsonProperty('fecha')
	public date: string;

	/** Hora */
	@JsonProperty('hora')
	public time: string;

	/** Código del estado */
	@JsonProperty('cod_estado')
	public stateCode: string;

	/** Nombre del estado */
	@JsonProperty('nom_estado')
	public stateName: string;

	/** Ruta */
	@JsonProperty('ruta')
	public route: Route;

	/** Vehículo */
	@JsonProperty('vehiculo')
	public vehicle: Vehicle;

	/** Conductor */
	@JsonProperty('conductor')
	public driver: Driver;

	/** Vuelta */
	@JsonProperty('vuelta')
	public lap: Lap;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Convierte un mapa json en un objeto de la clase.
	 */
	public static fromJson(json: any): Line {
		return deserialize(json, Line);
	}

	/**
	 * Convierte el objeto de la clase en un mapa json.
	 */
	public static toJson(model: Line): any {
		return serialize(model);
	}
}
