<nb-card status="success">
	<nb-card-header>
		<div class="row m-0">
			<h5 class="title col p-0">Generar Recaudo</h5>
			<nb-icon class="close col-auto p-0" icon="close" (click)="close()"></nb-icon>
		</div>
	</nb-card-header>
	<nb-card-body>
		<form name="recoverForm" [formGroup]="recoverForm" novalidate>
			<div class="row m-0 mb-2">
				<div class="col-6 p-0 pr-2">
					<label class="label">Total Liquidación:</label>
					<input nbInput class="input" formControlName="totalValue" type="tel" fieldSize="medium" currencyMask fullWidth>
				</div>
				<div class="col-6 p-0">
					<label class="label">Saldo a Favor:</label>
					<input nbInput class="input" formControlName="positiveBalanceValue" type="tel" fieldSize="medium" currencyMask fullWidth>
				</div>
			</div>
			<div class="row m-0 mb-2">
				<div class="col-6 p-0 pr-2">
					<label class="label">Bonos Estudiantes:</label>
					<input nbInput class="input" formControlName="bondsValue" type="tel" fieldSize="medium" currencyMask fullWidth>
				</div>
				<div class="col-6 p-0">
					<label class="label">Tasas de Uso:</label>
					<input nbInput class="input" formControlName="usageRatesValue" type="tel" fieldSize="medium" currencyMask fullWidth (keyup)="calculateTotalValuePay()">
				</div>
			</div>
			<div class="row m-0 mb-2">
				<div class="col-6 p-0 pr-2">
					<label class="label">Peajes:</label>
					<input nbInput class="input" formControlName="tollsValue" type="tel" fieldSize="medium" currencyMask fullWidth (keyup)="calculateTotalValuePay()">
				</div>
				<div class="col-6 p-0">
					<label class="label">Total a Pagar:</label>
					<input nbInput class="input" formControlName="totalValuePay" type="tel" fieldSize="medium" currencyMask fullWidth>
				</div>
			</div>
			<div class="row m-0 mb-2">
				<div class="col-6 p-0 pr-2">
					<label class="label">Entrega Conductor:</label>
					<input nbInput class="input" formControlName="deliveryValue" type="tel" fieldSize="medium" currencyMask fullWidth (keyup)="calculateBalanceValue()">
				</div>
				<div class="col-6 p-0">
					<label class="label">Saldo Pendiente:</label>
					<input nbInput class="input" formControlName="balanceValue" type="tel" fieldSize="medium" currencyMask fullWidth>
				</div>
			</div>
			<div class="mb-2">
				<label class="label">Observación:</label>
				<textarea nbInput class="input" formControlName="observation" type="text" [status]="getStatus('observation', triedAction)" fullWidth></textarea>
				<ng-container *ngIf="isNotValid('observation', triedAction)">
					<p class="caption" *ngIf="recoverForm.get('observation').hasError('required')">Observación es requerida!</p>
				</ng-container>
			</div>
			<div class="center-horizontal">
				<button nbButton class="button" type="submit" size="small" shape="round" status="primary" (click)='generateRecover()'>
					<nb-icon icon="save"></nb-icon>Generar
				</button>
			</div>
		</form>
	</nb-card-body>
</nb-card>
<ngx-loading [show]="loading"></ngx-loading>