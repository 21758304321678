import { Component, OnInit, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
	templateUrl: 'dialog-print-report.component.html',
	styleUrls: ['dialog-print-report.component.scss'],
})
export class DialogPrintReportComponent implements OnInit {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	@Input() public report: string;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Constructor
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	constructor(protected _dialogRef: NbDialogRef<DialogPrintReportComponent>) { }

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Funciones Propias
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	ngOnInit(): void { }

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Principales
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
   * Imprime el recaudo.
   */
	public printRecover(): void {
		const printContent = document.getElementById('reporte');
		const windowPrt = window.open('', '', 'left=0,top=0,width=auto,height=auto,toolbar=0,scrollbars=0,status=0');
		windowPrt.document.write(printContent.innerHTML);
		windowPrt.document.close();
		windowPrt.focus();
		windowPrt.print();
		windowPrt.close();
		this.close(true);
	}

	/**
   * Cierra el diálogo.
   */
	public close(model?: boolean): void {
		this._dialogRef.close(model);
	}
}
