import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { LocalDataSource } from 'ng2-smart-table';
import { Issuance } from 'src/app/models/issuance';

@Component({
	templateUrl: './dialog-issuance-list.component.html',
	styleUrls: ['./dialog-issuance-list.component.scss'],
})
export class DialogIssuanceListComponent implements OnInit {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	public settings: any;
	public source = new LocalDataSource();

	@Input() public issuances: Array<Issuance>;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Constructor
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	constructor(private _currencyPipe: CurrencyPipe, private _datepipe: DatePipe,
							protected _dialogRef: NbDialogRef<DialogIssuanceListComponent>) {
		this.settings = {
			columns: {
				number: {
					title: 'Número',
					type: 'text',
					width: '15%',
				},
				cardNumber: {
					title: 'Tarjeta',
					type: 'text',
					width: '15%',
				},
				time: {
					title: 'Hora',
					type: 'text',
					width: '10%',
				},
				clientFullName: {
					title: 'Cliente',
					type: 'text',
				},
				totalValue: {
					title: 'Valor',
					type: 'text',
					width: '15%',
				}
			},
			actions: {
				add: false,
				edit: false,
				delete: false,
			},
			attr: {
				class: 'table table-bordered'
			},
			pager: {
				perPage: 10,
			},
			noDataMessage: '',
			defaultStyle: false,
			hideSubHeader: true,
		};
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Propios
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	ngOnInit(): void {
		this._loadData();
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Principales
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
   * Filtra la lista modelo emisión.
   * @param text Texto a buscar.
   */
	public filterIssuances(text: string = ''): void {
		this.source.setFilter([
			{
				field: 'number',
				search: text
			},
			{
				field: 'cardNumber',
				search: text
			},
			{
				field: 'clientFullName',
				search: text
			},
		], text.length === 0);
	}

	/**
	 * Selecciona el modelo emisión según la fila.
	 * @param row Fila selecionada.
	 */
	public selectIssuance(row: any): void {
		const issuance = this.issuances.find((model) => model.id === row.data.id);
		this._dialogRef.close(issuance);
	}

	/**
	 * Cierra el diálogo.
	 */
	public close(): void {
		this._dialogRef.close();
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Secundarios
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Carga la fuente de datos de la tabla.
	 */
	private _loadData(): void {
		const items = Array<any>();
		this.issuances.forEach((model) => {
			items.push({
				id: model.id,
				number: model.number,
				cardNumber: model.cardNumber,
				time: this._datepipe.transform(model.dateTime, 'HH:mm'),
				clientFullName: model.client.names + ' ' + model.client.surnames,
				totalValue: this._toCurrency(model.totalValue),
			});
		});
		this.source.load(items);
	}

	/**
	 * Convierte un valor en texto moneda.
	 * @param value Valor a transformar.
	 */
	private _toCurrency(value: number): string {
		return `$ ${this._currencyPipe.transform(value, '$', 'symbol-narrow', '1.0-0').replace('$', '').trim()}`;
	}
}
