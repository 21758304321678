import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppData } from '../interfaces/app-data';

@Injectable({
	providedIn: 'root'
})
export class ApplicationService {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Constructor
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	constructor(private _httpClient: HttpClient) { }

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Principales
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Carga la información de la aplicación.
	 */
	public getAppData(): Promise<AppData> {
		return new Promise((resolve, reject) => {
			this._httpClient.get<AppData>('assets/data/app-data.json').toPromise().then(
				response => resolve(response),
				exception => reject(exception),
			);
		});
	}
}
