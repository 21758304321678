import { Component, OnInit, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { LocalDataSource } from 'ng2-smart-table';
import { Third } from 'src/app/models/third';

@Component({
	templateUrl: './dialog-third-list.component.html',
	styleUrls: ['./dialog-third-list.component.scss'],
})
export class DialogThirdListComponent implements OnInit {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	public settings: any;
	public source = new LocalDataSource();

	@Input() public thirds: Array<Third>;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Constructor
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	constructor(protected _dialogRef: NbDialogRef<DialogThirdListComponent>) {
		this.settings = {
			columns: {
				documentNumber: {
					title: 'N° Documento',
					type: 'text',
					width: '30%',
				},
				fullName: {
					title: 'Nombre completo',
					type: 'text',
				},
			},
			actions: {
				add: false,
				edit: false,
				delete: false,
			},
			attr: {
				class: 'table table-bordered'
			},
			pager: {
				perPage: 10,
			},
			noDataMessage: '',
			defaultStyle: false,
			hideSubHeader: true,
		};
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Propios
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	ngOnInit(): void {
		this._loadData();
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Principales
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
   * Filtra la lista modelo tercero.
   * @param text Texto a buscar.
   */
	public filterThirds(text: string = ''): void {
		this.source.setFilter([
			{
				field: 'documentNumber',
				search: text
			},
			{
				field: 'fullName',
				search: text
			},
		], text.length === 0);
	}

	/**
   * Selecciona el modelo tercero según la fila.
	 * @param row Fila selecionada.
   */
	public selectThird(row: any): void {
		const third = this.thirds.find((model) => model.id === row.data.id);
		this._dialogRef.close(third);
	}

	/**
   * Cierra el diálogo.
   */
	public close(): void {
		this._dialogRef.close();
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Secundarios
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Carga la fuente de datos de la tabla.
	 */
	private _loadData(): void {
		const items = Array<any>();
		this.thirds.forEach((model) => {
			items.push({
				id: model.id,
				documentNumber: model.documentNumber,
				fullName: model.names + ' ' + model.surnames,
			});
		});
		this.source.load(items);
	}
}
