import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { ConceptDetail } from 'src/app/models/concept-detail';
import { Ingress } from 'src/app/models/ingress';
import { Turn } from 'src/app/models/turn';

import pdfMake from 'pdfmake/build/pdfmake';
import { PdfAssetsLoader } from 'pdfmake-utils';

import { DialogThirdListComponent } from 'src/app/dialogs/third-list/dialog-third-list.component';

import { NbDialogService, NbToastrService } from '@nebular/theme';
import { BasicService } from 'src/app/services/basic.service';
import { IngressService } from 'src/app/services/ingress.service';
import { PrintService } from 'src/app/services/print.service';
import { ThirdService } from 'src/app/services/third.service';

@Component({
	templateUrl: 'dialog-ingress.component.html',
	styleUrls: ['dialog-ingress.component.scss'],
})
export class DialogIngressComponent implements OnInit {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Variables
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	private _ingress: Ingress;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	public loading = false;
	public triedAction = false;
	public ingressForm: FormGroup;
	public concepts: Array<ConceptDetail>;

	@Input() public ingress: Ingress;
	@Input() public turn: Turn;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Constructor
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	constructor(private _formBuilder: FormBuilder, protected _dialogRef: NbDialogRef<DialogIngressComponent>,
							private _dialogService: NbDialogService, private _toastrService: NbToastrService,
							private _basicService: BasicService, private _ingressService: IngressService,
							private _printService: PrintService, private _thirdService: ThirdService) {
		this._ingress = new Ingress();
		this.concepts = new Array();
		const assetsLoader = new PdfAssetsLoader();
		assetsLoader.registerFont({name: 'Courier', fileName: 'Courier', URL: 'assets/fonts/Courier.afm', styles: ['normal']});
		assetsLoader.registerFont({name: 'Courier', fileName: 'Courier-Bold', URL: 'assets/fonts/Courier-Bold.afm', styles: ['bold']});
		assetsLoader.load().then(() => {
			assetsLoader.configurePdfMake(pdfMake);
		}).catch((_error: any) => {
			assetsLoader.configurePdfMake(pdfMake);
		});
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Propios
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	ngOnInit(): void {
		this._buildForm();
		this.loading = true;
		this._basicService.findAllConcepts({
			code: 'ING',
			this: 'activo, exclude',
		}).then(
			models => {
				this.loading = false;
				this.concepts = models;
				if (this.ingress != null) {
					if (this.ingress.accountNumber !== 'N/A') {
						this._ingress.accountNumber = this.ingress.accountNumber;
						this._ingress.isPayPartial = this.ingress.isPayPartial;
						this._ingress.third = this.ingress.third;
						setTimeout(() => {
							this._fillForm();
						}, 100);
					} else {
						this.ingressForm.patchValue({
							id: 0,
							conceptCode: this.ingress.conceptCode,
						});
						this.ingressForm.get('conceptCode').disable();
					}
				}
			},
			exception => this._handleFailure(exception)
		);
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Eventos
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	public onSelectConcept(): void {
		this.ingressForm.patchValue({
			third: {
				id: 0,
				fullName: '',
			},
		});
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Principales
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Obtiene el estado del control del formulario.
	 * @param controlName Nombre del control.
	 */
	public getStatus(controlName: string, triedAction?: boolean): string {
		const control = this.ingressForm.get(controlName);
		if (!triedAction) {
			return control.dirty ? (control.invalid  ? 'danger' : 'basic') : 'basic';
		} else {
			return control.invalid ? 'danger' : 'basic';
		}
	}

	/**
   * Crea el modelo ingreso.
   */
	public createIngress(): void {
		this.triedAction = true;
		if (!this.ingressForm.invalid) {
			this.loading = true;
			this._setDataModel();
			this._ingressService.create(this._ingress).then(
				response => {
					this._printService.printIngress(response, {
						point_id: this.turn.recoverPoint.id,
					}).then(
						reporte => {
							this.loading = false;
							this.triedAction = false;
							if (reporte.length > 0) {
								const documento = {
									content: {
										text: reporte,
										lineHeight: 1.5,
										preserveLeadingSpaces: true,
									},
									defaultStyle: {
										font: 'Courier',
										fontSize: 12,
										bold: true,
									},
									pageMargins: [10, 30, 0, 0],
								};
								pdfMake.createPdf(documento).open();
							}
							if (this.ingress == null) {
								this._ingress = new Ingress();
								this._resetForm();
							} else {
								this.close(true);
							}
							this._handleMessage('Registro exitoso!', 'info');
						},
						exception => this._handleFailure(exception),
					);
				},
				exception => this._handleFailure(exception),
			);
		}
	}

	/**
   * Muestra el diálogo de la lista modelo tercero.
   */
	public showDialogThirds(): void {
		let tipo = '';
		this.loading = true;
		const codConcepto = this.ingressForm.get('conceptCode').value;

		if (codConcepto === 'SCA' || codConcepto === 'FCA') {
			tipo = 'USU';
		} else if (codConcepto !== 'OTR') {
			tipo = 'CON';
		}
		this._thirdService.findAllBy({
			type: tipo,
			this: 'activo, exclude',
		}).then(
			models => {
				this.loading = false;
				this._dialogService.open(DialogThirdListComponent, {
					context: {
						thirds: models,
					},
					closeOnBackdropClick: false,
				}).onClose.subscribe(model => {
					if (model != null) {
						this._ingress.third = model;
						this.ingressForm.patchValue({
							third: {
								id: this._ingress.third.id,
								fullName: this._ingress.third.names + ' ' + this._ingress.third.surnames,
							}
						});
					}
				});
			},
			exception => this._handleFailure(exception),
		);
	}

	/**
	 * Calcula el valor total pendiente.
	 */
	 public calculateTotalValue(): void {
		const value = this.ingressForm.get('value').value;
		const payValue = this.ingressForm.get('payValue').value;

		if (payValue === null || payValue === 0) {
			this.ingressForm.patchValue({
				payValue: value,
				totalValue: 0,
			});
		} else {
			if (payValue >= value) {
				this.ingressForm.patchValue({
					payValue: value,
					totalValue: 0,
				});
			} else {
				this.ingressForm.patchValue({
					totalValue: value - payValue,
				});
			}
		}
	}

	/**
	 * Cierra el diálogo.
	 */
	public close(result?: boolean): void {
		this._dialogRef.close(result);
	}

	/**
	 * Indica si no es válido el control del formulario.
	 * @param controlName Nombre del control.
	 */
	public isNotValid(controlName: string, triedAction?: boolean): boolean {
		const control = this.ingressForm.get(controlName);
		if (!triedAction) {
			return control.dirty && control.invalid;
		} else {
			return control.invalid;
		}
	}

	/**
	 * Indica si no es válido el concepto del ingreso.
	 */
	public isConceptNotValid(code: string) {
		return code === '';
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Secundarios
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Cambia los datos del modelo ingreso.
	 */
	private _setDataModel(): void {
		this._ingress.conceptCode = this.ingressForm.get('conceptCode').value;
		this._ingress.description = this.ingressForm.get('description').value;
		if (this._ingress.isPayPartial) {
			this._ingress.value = this.ingressForm.get('payValue').value;
		} else {
			this._ingress.value = this.ingressForm.get('value').value;
		}
		this._ingress.turn = this.turn;
	}

	/**
	 * Construye el formulario.
	 */
	private _buildForm(): void {
		this.ingressForm = this._formBuilder.group({
			id: 0,
			accountNumber: '',
			conceptCode: ['', [Validators.required]],
			description: ['', [Validators.required]],
			value: [0, [Validators.required]],
			payValue: [0, [Validators.required]],
			totalValue: [0, [Validators.required]],
			isPayPartial: false,
			third: this._formBuilder.group({
				id: [0, [this._checkIdSize]],
				fullName: ['', [Validators.required]],
			}),
		});
	}

	/**
	 * Llena el formulario.
	 */
	private _fillForm(): void {
		this.ingressForm.setValue({
			id: 0,
			accountNumber: this.ingress.accountNumber != null ? this.ingress.accountNumber : '',
			conceptCode: this.ingress.conceptCode,
			description: this.ingress.description != null ? this.ingress.description : '',
			value: this.ingress.value,
			payValue: this.ingress.value,
			totalValue: 0,
			isPayPartial: this.ingress.isPayPartial != null ? this.ingress.isPayPartial : false,
			third: {
				id: this.ingress.third != null ? this.ingress.third.id : 0,
				fullName: this.ingress.third ? this.ingress.third.names + ' ' + this.ingress.third.surnames : '',
			}
		});
		if (this.ingress.third != null) {
			this.ingressForm.get('third').disable();
		}
		this.ingressForm.get('conceptCode').disable();
		this.ingressForm.get('value').disable();
		this.ingressForm.get('totalValue').disable();
	}

	/**
	 * Reinicia el formulario.
	 */
	private _resetForm(): void {
		this.ingressForm.reset({
			id: 0,
			accountNumber: '',
			conceptCode: '',
			description: '',
			value: '',
			payValue: '',
			totalValue: '',
			isPayPartial: false,
			third: {
				id: 0,
				fullName: '',
			},
		});
	}

	/**
	 * Maneja el mensaje que se va a mostrar.
	 * @param type Tipo de mensaje.
	 */
	private _handleMessage(message: any, type: any) {
		this._toastrService.show(message, 'Ingreso', {
			status: type,
			duration: 3000,
		});
	}

	/**
	 * Maneja la excepción que se ha generado.
	 */
	private _handleFailure(exception: any): void {
		this.loading = false;
		const mensaje = exception.status > 0 ? exception.error : exception.message;
		this._toastrService.show(mensaje, 'Ingreso', {
			status: 'danger',
			duration: 5000,
		});
	}

	/**
	 * Chequea el tamaño del id.
	 */
	private _checkIdSize(control: FormControl) {
		const value: number = control.value;
		if (!value || value === 0) {
			return {
				idSize: true
			};
		}
		return null;
	}
}
