import { DatePipe } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { LocalDataSource } from 'ng2-smart-table';
import { Line } from 'src/app/models/line';

import { CheckboxComponent } from 'src/app/components/checkbox/checkbox.component';

import { NbToastrService } from '@nebular/theme';

@Component({
	templateUrl: './dialog-line-list.component.html',
	styleUrls: ['./dialog-line-list.component.scss'],
})
export class DialogLineListComponent implements OnInit {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	private _selected: Array<Line>;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	public settingsPrimary: any;
	public settingsSecondary: any;
	public sourcePrimary = new LocalDataSource();
	public sourceSecondary = new LocalDataSource();

	@Input() public linesPrimary: Array<Line>;
	@Input() public linesSecondary: Array<Line>;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Constructor
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	constructor(private _datepipe: DatePipe, protected _dialogRef: NbDialogRef<DialogLineListComponent>, private _toastrService: NbToastrService) {
		this._selected = Array();
		this.settingsPrimary = {
			columns: {
				date: {
					title: 'Fecha',
					type: 'text',
					width: '15%',
				},
				time: {
					title: 'Hora',
					type: 'text',
					width: '5%',
				},
				routeName: {
					title: 'Ruta',
					type: 'text',
				},
				vehicleNumber: {
					title: 'Vehículo',
					type: 'text',
					width: '17%',
				},
				active: {
					title: '',
					type: 'custom',
					width: '10%',
					renderComponent: CheckboxComponent
				},
			},
			actions: {
				columnTitle: '',
				position: 'right',
				add: false,
				edit: false,
				delete: false,
			},
			edit: {
				editButtonContent: '<i class="fa fa-pen fa-xs" title="Editar"></i>',
			},
			delete: {
				deleteButtonContent: '<i class="fa fa-trash fa-xs" title="Eliminar"></i>',
			},
			attr: {
				class: 'table table-bordered'
			},
			pager: {
				perPage: 5,
			},
			mode: 'external',
			noDataMessage: '',
			defaultStyle: false,
			hideSubHeader: true,
		};
		this.settingsSecondary = {
			columns: {
				date: {
					title: 'Fecha',
					type: 'text',
					width: '15%',
				},
				time: {
					title: 'Hora',
					type: 'text',
					width: '5%',
				},
				routeName: {
					title: 'Ruta',
					type: 'text',
				},
				vehicleNumber: {
					title: 'Vehículo',
					type: 'text',
					width: '17%',
				},
			},
			actions: {
				columnTitle: '',
				position: 'right',
				add: false,
				edit: false,
				delete: false,
			},
			edit: {
				editButtonContent: '<i class="fa fa-pen fa-xs" title="Editar"></i>',
			},
			delete: {
				deleteButtonContent: '<i class="fa fa-trash fa-xs" title="Eliminar"></i>',
			},
			attr: {
				class: 'table table-bordered'
			},
			pager: {
				perPage: 5,
			},
			mode: 'external',
			noDataMessage: '',
			defaultStyle: false,
			hideSubHeader: true,
		};
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Propios
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	ngOnInit(): void {
		this._loadDataPrimary();
		this._loadDataSecondary();
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Principales
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
   * Selecciona el modelo línea.
	 * @param row Fila seleccionada.
   */
	public selectLine(row: any): void {
		const index = this.linesPrimary.findIndex((model) => model.id === row.data.id);
		this.sourcePrimary.getAll().then(elements => {
			const line = this.linesPrimary[index];
			for (const element of elements) {
				if (line.id === element.id) {
					element.active = !element.active;
					this.sourcePrimary.update(element, element);
					break;
				}
			}
		});
	}

	/**
   * Agrega la lista modelo línea.
   */
	public addLines(): void {
		this.sourcePrimary.getAll().then(elements => {
			for (const element of elements) {
				const line = this.linesPrimary[this.linesPrimary.findIndex((model) => model.id === element.id)];
				if (element.active) {
					this._selected.push(line);
				}
			}
			const lines = this._selected.sort((model1, model2) => {
				return +new Date(model1.date + ' ' + model1.time) - +new Date(model2.date + ' ' + model2.time);
			});
			this.close(lines);
		});
	}

	/**
   * Filtra la lista modelo línea.
   * @param text Texto a buscar.
   */
	public filterLines(text: string = ''): void {
		this.sourcePrimary.setFilter([
			{
				field: 'date',
				search: text
			},
			{
				field: 'time',
				search: text
			},
			{
				field: 'routeName',
				search: text
			},
			{
				field: 'vehicleNumber',
				search: text
			},
		], text.length === 0);
		this.sourceSecondary.setFilter([
			{
				field: 'date',
				search: text
			},
			{
				field: 'time',
				search: text
			},
			{
				field: 'routeName',
				search: text
			},
			{
				field: 'vehicleNumber',
				search: text
			},
		], text.length === 0);
	}

	/**
   * Cierra el diálogo.
   */
	public close(models?: Array<Line>): void {
		this._dialogRef.close(models);
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Secundarios
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Carga la fuente de datos de la tabla.
	 */
	private _loadDataPrimary(): void {
		const items = Array<any>();
		this.linesPrimary.forEach((model) => {
			items.push({
				id: model.id,
				date: this._datepipe.transform(model.date, 'MMM dd/yyyy').toUpperCase(),
				time: model.time,
				routeName: model.route.name,
				vehicleNumber: model.vehicle.internalNumber + ' - ' + model.vehicle.plaque,
				active: false,
			});
		});
		this.sourcePrimary.load(items);
	}

	/**
	 * Carga la fuente de datos de la tabla.
	 */
	private _loadDataSecondary(): void {
		const items = Array<any>();
		this.linesSecondary.forEach((model) => {
			items.push({
				id: model.id,
				date: this._datepipe.transform(model.date, 'MMM dd/yyyy').toUpperCase(),
				time: model.time,
				routeName: model.route.name,
				vehicleNumber: model.vehicle.internalNumber + ' - ' + model.vehicle.plaque,
			});
		});
		this.sourceSecondary.load(items);
	}

	/**
	 * Maneja el mensaje que se va a mostrar.
	 * @param type Tipo de mensaje.
	 */
	private _handleMessage(message: any, type: any) {
		this._toastrService.show(message, 'Líneas', {
			status: type,
			duration: 3000,
		});
	}
}
