import { Serializable, JsonProperty, deserialize, serialize } from 'typescript-json-serializer';
import { Route } from './route';
import { User } from './user';

/**
 * Clase que administra los datos del punto de recaudo.
 */
@Serializable()
export class RecoverPoint {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/** Id */
	@JsonProperty('id')
	public id: number;

	/** Número interno */
	@JsonProperty('codigo')
	public code: string;

	/** Código del tipo */
	@JsonProperty('cod_tipo')
	public typeCode: string;

	/** Nombre */
	@JsonProperty('nombre')
	public name: string;

	/** Nombre del tipo */
	@JsonProperty('nom_tipo')
	public typeName: string;

	/** Indica si esta activo */
	@JsonProperty('activo')
	public isActive = true;

	/** Indica si usa bonos */
	@JsonProperty('usa_bonos')
	public useVouchers = false;

	/** Lista ruta */
	@JsonProperty({name: 'rutas', type: Route})
	public routes: Array<Route>;

	/** Lista usuario */
	@JsonProperty({name: 'usuarios', type: User})
	public users: Array<User>;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Convierte un mapa json en un objeto de la clase.
	 */
	public static fromJson(json: any): RecoverPoint {
		return deserialize(json, RecoverPoint);
	}

	/**
	 * Convierte el objeto de la clase en un mapa json.
	 */
	public static toJson(model: RecoverPoint): any {
		return serialize(model);
	}
}
