import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { Client } from 'src/app/models/client';

import { NbToastrService } from '@nebular/theme';
import { ClientService } from 'src/app/services/client.service';

@Component({
	templateUrl: 'dialog-client.component.html',
	styleUrls: ['dialog-client.component.scss'],
})
export class DialogClientComponent implements OnInit {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Variables
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	private _client: Client;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	public loading = false;
	public triedAction = false;
	public clientForm: FormGroup;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Constructor
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	constructor(private _formBuilder: FormBuilder, protected _dialogRef: NbDialogRef<DialogClientComponent>,
				private _toastrService: NbToastrService, private _clientService: ClientService) {
		this._client = new Client();
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Propios
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	ngOnInit(): void {
		this._buildForm();
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Principales
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Obtiene el estado del control del formulario.
	 * @param controlName Nombre del control.
	 */
	public getStatus(controlName: string, triedAction?: boolean): string {
		const control = this.clientForm.get(controlName);
		if (!triedAction) {
			return control.dirty ? (control.invalid  ? 'danger' : 'basic') : 'basic';
		} else {
			return control.invalid ? 'danger' : 'basic';
		}
	}

	/**
   * Crea el modelo cliente.
   */
	public createClient(): void {
		this.triedAction = true;
		if (!this.clientForm.invalid) {
			this.loading = true;
			this._setDataModel();
			this._clientService.create(this._client).then(
				_response => {
					this.loading = false;
					this.clearClient();
					this._handleMessage('Registro exitoso!', 'info');
				},
				exception => this._handleFailure(exception),
			);
		}
	}

	/**
	 * Actualiza el modelo cliente.
	 */
	public updateClient(): void {
		this.triedAction = true;
		if (!this.clientForm.invalid) {
			this.loading = true;
			this._setDataModel();
			this._clientService.update(this._client).then(
				_response => {
					this.loading = false;
					this._handleMessage('Actualización exitosa!', 'info');
				},
				exception => this._handleFailure(exception),
			);
		}
	}

	/**
	 * Busca el modelo cliente.
	 */
	public searchClient() {
		this.loading = true;
		this._clientService.findBy({
			document: this.clientForm.get('document').value
		}).then(
			model => {
				if (model !== null) {
					this.loading = false;
					this._client = model;
					this._fillForm();
					this.clientForm.get('document').disable();
				} else {
					this._handleMessage('Cliente no existe!', 'info');
				}
			},
			exception => this._handleFailure(exception),
		);
	}

	/**
	 * Limpia el modelo cliente.
	 */
	public clearClient() {
		this.triedAction = false;
		this._client = new Client();
		this._resetForm();
	}

	/**
	 * Cierra el diálogo.
	 */
	public close(result?: boolean): void {
		this._dialogRef.close(result);
	}

	/**
	 * Indica si no es válido el control del formulario.
	 * @param controlName Nombre del control.
	 */
	public isNotValid(controlName: string, triedAction?: boolean): boolean {
		const control = this.clientForm.get(controlName);
		if (!triedAction) {
			return control.dirty && control.invalid;
		} else {
			return control.invalid;
		}
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Secundarios
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Cambia los datos del modelo cliente.
	 */
	private _setDataModel(): void {
		this._client.document = this.clientForm.get('document').value;
		this._client.names = this.clientForm.get('names').value.toUpperCase();
		this._client.surnames = this.clientForm.get('surnames').value.toUpperCase();
		this._client.email = this.clientForm.get('email').value;
		this._client.phone = this.clientForm.get('phone').value;
		this._client.isActive = this.clientForm.get('isActive').value;
		if (this._client.email != null) {
			if (this._client.email.trim().length === 0) {
				this._client.email = null;
			}
		}
		if (this._client.phone != null) {
			if (this._client.phone.trim().length === 0) {
				this._client.phone = null;
			}
		}
	}

	/**
	 * Construye el formulario.
	 */
	private _buildForm(): void {
		this.clientForm = this._formBuilder.group({
			id: 0,
			document: ['', [Validators.required]],
			names: ['', [Validators.required]],
			surnames: ['', [Validators.required]],
			email: ['', [Validators.pattern('[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})')]],
			phone: '',
			isActive: true,
		});
	}

	/**
	 * Llena el formulario.
	 */
	private _fillForm(): void {
		this.clientForm.setValue({
			id: this._client.id,
			document: this._client.document,
			names: this._client.names,
			surnames: this._client.surnames,
			email: this._client.email,
			phone: this._client.phone,
			isActive: this._client.isActive,
		});
		this.clientForm.get('document').disable();
	}

	/**
	 * Reinicia el formulario.
	 */
	private _resetForm(): void {
		this.clientForm.reset({
			id: 0,
			document: '',
			names: '',
			surnames: '',
			email: '',
			phone: '',
			isActive: true,
		});
		this.clientForm.get('document').enable();
	}

	/**
	 * Maneja el mensaje que se va a mostrar.
	 * @param type Tipo de mensaje.
	 */
	private _handleMessage(message: any, type: any) {
		this._toastrService.show(message, 'Cliente', {
			status: type,
			duration: 3000,
		});
	}

	/**
	 * Maneja la excepción que se ha generado.
	 */
	private _handleFailure(exception: any): void {
		this.loading = false;
		const mensaje = exception.status > 0 ? exception.error : exception.message;
		this._toastrService.show(mensaje, 'Cliente', {
			status: 'danger',
			duration: 5000,
		});
	}
}
