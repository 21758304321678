import { Injectable, isDevMode } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { tap } from 'rxjs/operators';

@Injectable()
export class AppInterceptor implements HttpInterceptor {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Constructor
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	constructor() { }

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Propios
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
   * Intercepta las llamadas http que se realizan en la aplicación.
   * @param request Solicitud http.
   * @param next Manejador http.
   */
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const callRequest = request.clone({
			headers: request.headers.set('Authorization', 'Some-dummyCode')
		});
		if (isDevMode) {
			console.log('Api Call: ', callRequest);
		}
		return next.handle(request).pipe(
			tap(
				event => {
					if (event instanceof HttpResponse) {
						if (isDevMode) {
							console.log('Api Success: ', event);
						}
					}
				},
				error => {
					if (error instanceof HttpResponse) {
						if (isDevMode) {
							console.log('Api Error: ', error);
						}
					}
				}
			)
		);
	}
}
