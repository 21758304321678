import { Serializable, JsonProperty, deserialize, serialize } from 'typescript-json-serializer';
import { Line } from './line';

/**
 * Clase que administra los datos del detalle del recaudo.
 */
@Serializable()
export class RecoverDetail {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/** Id */
	@JsonProperty('id')
	public id: number;

	/** Id del recaudo */
	@JsonProperty('id_recaudo')
	public idRecover: number;

	/** Número de la vuelta */
	@JsonProperty('num_vuelta')
	public returnNumber: number;

	/** Motivo de descuento */
	@JsonProperty('motivo_desc')
	public discountReason: string;

	/** Observación */
	@JsonProperty('observacion')
	public observation: string;

	/** Cantidad de pasajeros tarjeta */
	@JsonProperty('cant_pax_tarj')
	public passengersCardAmount: number;

	/** Cantidad de pasajeros efectivo */
	@JsonProperty('cant_pax_efec')
	public passengersEffectiveAmount: number;

	/** Cantidad de pasajeros adicional */
	@JsonProperty('cant_pax_adic')
	public passengersAdditionalAmount: number;

	/** Cantidad de pasajeros descuento */
	@JsonProperty('cant_pax_desc')
	public passengersDiscountAmount: number;

	/** Cantidad de pasajeros barra */
	@JsonProperty('cant_pax_barra')
	public passengersBarAmount: number;

	/** Valor de pasajeros tarjeta */
	@JsonProperty('valor_pax_tarj')
	public passengersCardValue: number;

	/** Valor de pasajeros efectivo */
	@JsonProperty('valor_pax_efec')
	public passengersEffectiveValue: number;

	/** Valor de pasajeros adicional */
	@JsonProperty('valor_pax_adic')
	public passengersAdditionalValue: number;

	/** Valor de pasajeros descuento */
	@JsonProperty('valor_pax_desc')
	public passengersDiscountValue: number;

	/** Valor de descuento */
	@JsonProperty('valor_desc')
	public discountValue: number;

	/** Total efectivo */
	@JsonProperty('total_efectivo')
	public totalEffective: number;

	/** Total sistema */
	@JsonProperty('total_sistema')
	public totalSystem: number;

	/** Línea */
	@JsonProperty('linea')
	public line: Line;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Convierte un mapa json en un objeto de la clase.
	 */
	public static fromJson(json: any): RecoverDetail {
		return deserialize(json, RecoverDetail);
	}

	/**
	 * Convierte el objeto de la clase en un mapa json.
	 */
	public static toJson(model: RecoverDetail): any {
		return serialize(model);
	}
}
