import { Serializable, JsonProperty, deserialize, serialize } from 'typescript-json-serializer';
import { Driver } from './driver';
import { FuelAuthorizeDetail } from './fuel-authorize-detail';
import { User } from './user';
import { Vehicle } from './vehicle';

/**
 * Clase que administra los datos de la autorización de combustible.
 */
@Serializable()
export class FuelAuthorize {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/** Id */
	@JsonProperty('id')
	public id: number;

	/** Fecha y hora */
	@JsonProperty('fecha_hora')
	public dateTime: string;

	/** Observación */
	@JsonProperty('observacion')
	public observation: string;

	/** Vehículo */
	@JsonProperty('vehiculo')
	public vehicle: Vehicle;

	/** Conductor */
	@JsonProperty('conductor')
	public driver: Driver;

	/** Usuario */
	@JsonProperty('usuario')
	public user: User;

	/** Lista detalle */
	@JsonProperty({name: 'detalles', type: FuelAuthorizeDetail})
	public details: Array<FuelAuthorizeDetail>;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Convierte un mapa json en un objeto de la clase.
	 */
	public static fromJson(json: any): FuelAuthorize {
		return deserialize(json, FuelAuthorize);
	}

	/**
	 * Convierte el objeto de la clase en un mapa json.
	 */
	public static toJson(model: FuelAuthorize): any {
		return serialize(model);
	}
}
