import { Component, OnInit, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { LocalDataSource } from 'ng2-smart-table';
import { Company } from 'src/app/models/company';

@Component({
	templateUrl: './dialog-company-list.component.html',
	styleUrls: ['./dialog-company-list.component.scss'],
})
export class DialogCompanyListComponent implements OnInit {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	public settings: any;
	public source = new LocalDataSource();

	@Input() public companies: Array<Company>;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Constructor
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	constructor(protected _dialogRef: NbDialogRef<DialogCompanyListComponent>) {
		this.settings = {
			columns: {
				nit: {
					title: 'Nit',
					type: 'text',
					width: '25%',
				},
				name: {
					title: 'Nombre',
					type: 'text',
				},
			},
			actions: {
				add: false,
				edit: false,
				delete: false,
			},
			attr: {
				class: 'table table-bordered'
			},
			pager: {
				perPage: 10,
			},
			noDataMessage: '',
			defaultStyle: false,
			hideSubHeader: true,
		};
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Propios
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	ngOnInit(): void {
		this._loadData();
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Principales
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
   * Filtra la lista modelo empresa.
   * @param text Texto a buscar.
   */
	public filterCompanies(text: string = ''): void {
		this.source.setFilter([
			{
				field: 'nit',
				search: text
			},
			{
				field: 'name',
				search: text
			},
		], text.length === 0);
	}

	/**
   * Selecciona el modelo empresa según la fila.
	 * @param row Fila selecionada.
   */
	public selectCompany(row: any): void {
		const company = this.companies.find((model) => model.id === row.data.id);
		this._dialogRef.close(company);
	}

	/**
   * Cierra el diálogo.
   */
	public close(): void {
		this._dialogRef.close();
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Secundarios
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Carga la fuente de datos de la tabla.
	 */
	private _loadData(): void {
		const items = Array<any>();
		this.companies.forEach((model) => {
			items.push({
				id: model.id,
				nit: model.nit + '-' + model.digit,
				name: model.name,
			});
		});
		this.source.load(items);
	}
}
