import { Serializable, JsonProperty, deserialize, serialize } from 'typescript-json-serializer';

/**
 * Clase que administra los datos del parámetro.
 */
@Serializable()
export class Parameter {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/** Id */
	@JsonProperty('id')
	public id: number;

	/** Código */
	@JsonProperty('codigo')
	public code: string;

	/** Nombre */
	@JsonProperty('nombre')
	public name: string;

	/** Valor */
	@JsonProperty('valor')
	public value: string;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Convierte un mapa json en un objeto de la clase.
	 */
	public static fromJson(json: any): Parameter {
		return deserialize(json, Parameter);
	}

	/**
	 * Convierte el objeto de la clase en un mapa json.
	 */
	public static toJson(model: Parameter): any {
		return serialize(model);
	}
}
