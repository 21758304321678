import { Serializable, JsonProperty, deserialize, serialize } from 'typescript-json-serializer';
import { Company } from './company';
import { Driver } from './driver';

/**
 * Clase que administra los datos del vehículo.
 */
@Serializable()
export class Vehicle {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/** Id */
	@JsonProperty('id')
	public id: number;

	/** Número interno */
	@JsonProperty('num_interno')
	public internalNumber: number;

	/** Placa */
	@JsonProperty('placa')
	public plaque: string;

	/** Indica si esta activo */
	@JsonProperty('activo')
	public isActive = true;

	/** Empresa */
	@JsonProperty('empresa')
	public company: Company;

	/** Conductor */
	@JsonProperty('conductor')
	public driver: Driver;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Convierte un mapa json en un objeto de la clase.
	 */
	public static fromJson(json: any): Vehicle {
		return deserialize(json, Vehicle);
	}

	/**
	 * Convierte el objeto de la clase en un mapa json.
	 */
	public static toJson(model: Vehicle): any {
		return serialize(model);
	}
}
