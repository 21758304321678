import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppNebularModule } from '../app-nebular.module';

import { HeaderComponent } from '../components/header/header.component';
import { LargeLayoutComponent } from './large-layout/large-layout.component';

@NgModule({
	imports: [
		AppNebularModule,
		CommonModule,
		RouterModule,
	],
	exports: [
		HeaderComponent,
		LargeLayoutComponent,
	],
	declarations: [
		HeaderComponent,
		LargeLayoutComponent,
	],
})
export class LayoutsModule { }
