import { Serializable, JsonProperty, deserialize, serialize } from 'typescript-json-serializer';

/**
 * Clase que administra los datos del tercero.
 */
@Serializable()
export class Third {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/** Id */
	@JsonProperty('id')
	public id: number;

	/** Número de documento */
	@JsonProperty('num_documento')
	public documentNumber: string;

	/** Tipo de documento */
	@JsonProperty('tipo_documento')
	public documentType: string;

	/** Nombres */
	@JsonProperty('nombres')
	public names: string;

	/** Apellidos */
	@JsonProperty('apellidos')
	public surnames: string;

	/** Tipo */
	@JsonProperty('tipo')
	public type: string;

	/** Indica si esta activo */
	@JsonProperty('activo')
	public isActive = true;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Convierte un mapa json en un objeto de la clase.
	 */
	public static fromJson(json: any): Third {
		return deserialize(json, Third);
	}

	/**
	 * Convierte el objeto de la clase en un mapa json.
	 */
	public static toJson(model: Third): any {
		return serialize(model);
	}
}
