/*
 * Copyright (C) 2020. WillGSoft Solutions. All rights reserved.
 * Author: Joseph W. Guzman (Developer)
 * Last Modified: 2020-05-18
 */
import { Serializable, JsonProperty, deserialize, serialize } from 'typescript-json-serializer';

/**
 * Clase que administra los datos de la ruta.
 */
@Serializable()
export class Route {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/** Id */
	@JsonProperty('id')
	public id: number;

	/** Código Millenium */
	@JsonProperty('cod_millenium')
	public milleniumCode: string;

	/** Nombre */
	@JsonProperty('nombre')
	public name: string;

	/** Valor máximo */
	@JsonProperty('valor_maximo')
	public maximumValue: number;

	/** Valor promedio */
	@JsonProperty('valor_promedio')
	public averageValue: number;

	/** Indica si esta activo */
	@JsonProperty('activo')
	public isActive = true;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Convierte un mapa json en un objeto de la clase.
	 */
	public static fromJson(json: any): Route {
		return deserialize(json, Route);
	}

	/**
	 * Convierte el objeto de la clase en un mapa json.
	 */
	public static toJson(model: Route): any {
		return serialize(model);
	}
}
