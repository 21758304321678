import { NgModule } from '@angular/core';
import { NbAuthModule } from '@nebular/auth';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import {
	NbAccordionModule,
	NbActionsModule,
	NbAlertModule,
	NbBadgeModule,
	NbButtonModule,
	NbCardModule,
	NbCheckboxModule,
	NbContextMenuModule,
	NbDatepickerModule,
	NbDialogModule,
	NbIconModule,
	NbInputModule,
	NbLayoutModule,
	NbListModule,
	NbMenuModule,
	NbRadioModule,
	NbSelectModule,
	NbSidebarModule,
	NbSpinnerModule,
	NbTabsetModule,
	NbThemeModule,
	NbToastrModule,
	NbToggleModule,
	NbTooltipModule,
	NbUserModule,
	NbWindowModule,
} from '@nebular/theme';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { NgxCurrencyModule } from 'ngx-currency';
import { NgxLoadingModule } from 'ngx-loading';
import { NgxPrintModule } from 'ngx-print';

@NgModule({
	imports: [
		NbAccordionModule,
		NbActionsModule,
		NbAlertModule,
		NbAuthModule,
		NbBadgeModule,
		NbButtonModule,
		NbCardModule,
		NbCheckboxModule,
		NbContextMenuModule,
		NbDatepickerModule,
		NbDialogModule,
		NbEvaIconsModule,
		NbIconModule,
		NbLayoutModule,
		NbListModule,
		NbMenuModule,
		NbRadioModule,
		NbSelectModule,
		NbSidebarModule,
		NbSpinnerModule,
		NbTabsetModule,
		NbThemeModule,
		NbToastrModule,
		NbToggleModule,
		NbTooltipModule,
		NbUserModule,
		NbWindowModule,
		NgbModule,
		Ng2SmartTableModule,
		NgxCurrencyModule,
		NgxLoadingModule,
		NgxPrintModule,
	],
	exports: [
		NbAccordionModule,
		NbActionsModule,
		NbAlertModule,
		NbAuthModule,
		NbBadgeModule,
		NbButtonModule,
		NbCardModule,
		NbCheckboxModule,
		NbContextMenuModule,
		NbDatepickerModule,
		NbDialogModule,
		NbEvaIconsModule,
		NbIconModule,
		NbInputModule,
		NbLayoutModule,
		NbListModule,
		NbMenuModule,
		NbRadioModule,
		NbSelectModule,
		NbSidebarModule,
		NbSpinnerModule,
		NbTabsetModule,
		NbThemeModule,
		NbToastrModule,
		NbToggleModule,
		NbTooltipModule,
		NbUserModule,
		NbWindowModule,
		NgbModule,
		Ng2SmartTableModule,
		NgxCurrencyModule,
		NgxLoadingModule,
		NgxPrintModule,
	],
})
export class AppNebularModule { }
