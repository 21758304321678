import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Recover } from '../models/recover';

import { APPLICATION_JSON, URL_HOST } from '../../app';

import { AuthenticationService } from './authentication.service';

@Injectable({
	providedIn: 'root'
})
export class RecoverService {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Variables
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	private _url = `${URL_HOST}`;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Constructor
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	constructor(private _httpClient: HttpClient, private _authService: AuthenticationService) { }

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Principales
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Busca el modelo recaudo según los parámetros.
	 * @param id Id del recaudo.
	 * @param params Parámetros de la solicitud.
	 */
	public find(id: number, params?: any): Promise<Recover> {
		return new Promise((resolve, reject) => {
			this._authService.getToken().then(
				token => {
					const httpHeaders = new HttpHeaders({
						'Content-Type': APPLICATION_JSON,
						Authorization: token
					});
					const httpParams = new HttpParams({
						fromObject: params
					});
					this._httpClient.get<any>(`${this._url}/recover/${id}`, {headers: httpHeaders, params: httpParams}).toPromise().then(
						response => resolve(Recover.fromJson(response)),
						exception => reject(exception)
					);
				},
				error => reject(error)
			);
		});
	}

	/**
	 * Busca la lista modelo recaudo.
	 * @param params Parámetros de la solicitud.
	 */
	public findAll(params?: any): Promise<Array<Recover>> {
		return new Promise((resolve, reject) => {
			this._authService.getToken().then(
				token => {
					const httpHeaders = new HttpHeaders({
						'Content-Type': APPLICATION_JSON,
						Authorization: token
					});
					const httpParams = new HttpParams({
						fromObject: params
					});
					this._httpClient.get<Array<any>>(`${this._url}/recovers`, {headers: httpHeaders, params: httpParams}).toPromise().then(
						response => resolve(response.map(item => Recover.fromJson(item))),
						exception => reject(exception)
					);
				},
				error => reject(error)
			);
		});
	}

	/**
	 * Busca la lista modelo recaudo.
	 * @param params Parámetros de la solicitud.
	 */
	public findAllBy(params?: any): Promise<Array<Recover>> {
		return new Promise((resolve, reject) => {
			this._authService.getToken().then(
				token => {
					const httpHeaders = new HttpHeaders({
						'Content-Type': APPLICATION_JSON,
						Authorization: token
					});
					const httpParams = new HttpParams({
						fromObject: params
					});
					this._httpClient.get<Array<any>>(`${this._url}/recovers/by`, {headers: httpHeaders, params: httpParams}).toPromise().then(
						response => resolve(response.map(item => Recover.fromJson(item))),
						exception => reject(exception)
					);
				},
				error => reject(error)
			);
		});
	}

	/**
   * Crea el modelo recaudo.
   * @param model Modelo recaudo.
   */
	public create(model: Recover): Promise<number> {
		return new Promise((resolve, reject) => {
			this._authService.getToken().then(
				token => {
					const httpHeaders = new HttpHeaders({
						'Content-Type': APPLICATION_JSON,
						Authorization: token
					});
					this._httpClient.post<any>(`${this._url}/recover`, Recover.toJson(model), {headers: httpHeaders}).toPromise().then(
						response => resolve(response),
						exception => reject(exception)
					);
				},
				error => reject(error)
			);
		});
	}

	/**
	 * Actualiza el modelo recaudo.
	 * @param model Modelo recaudo.
	 */
	public update(model: Recover): Promise<number> {
		return new Promise((resolve, reject) => {
			this._authService.getToken().then(
				token => {
					const httpHeaders = new HttpHeaders({
						'Content-Type': APPLICATION_JSON,
						Authorization: token
					});
					this._httpClient.put<any>(`${this._url}/recover`, Recover.toJson(model), {headers: httpHeaders}).toPromise().then(
						response => resolve(response),
						exception => reject(exception)
					);
				},
				error => reject(error)
			);
		});
	}

	/**
	 * Actualiza el modelo recaudo.
	 * @param model Modelo recaudo.
	 * @param params Parámetros de la solicitud.
	 */
	public updateBy(model: Recover, params?: any): Promise<number> {
		return new Promise((resolve, reject) => {
			this._authService.getToken().then(
				token => {
					const httpHeaders = new HttpHeaders({
						'Content-Type': APPLICATION_JSON,
						Authorization: token
					});
					const httpParams = new HttpParams({
						fromObject: params
					});
					this._httpClient.put<any>(`${this._url}/recover/by`, Recover.toJson(model), {headers: httpHeaders, params: httpParams}).toPromise().then(
						response => resolve(response),
						exception => reject(exception)
					);
				},
				error => reject(error)
			);
		});
	}
}
