import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NbWindowRef } from '@nebular/theme';

@Component({
	templateUrl: 'window-money-calculator.component.html',
	styleUrls: ['window-money-calculator.component.scss'],
})
export class WindowMoneyCalculatorComponent implements OnInit {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	public calculatorForm: FormGroup;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Constructor
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	constructor(private _formBuilder: FormBuilder, protected _windowRef: NbWindowRef) { }

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Funciones Propias
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	ngOnInit(): void {
		this._buildForm();
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Principales
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Calcula el valor total.
	 */
	public calculate(): void {
		const cienMilAmount = this.calculatorForm.get('cienMilAmount').value;
		const cincuentaMilAmount = this.calculatorForm.get('cincuentaMilAmount').value;
		const veinteMilAmount = this.calculatorForm.get('veinteMilAmount').value;
		const diezMilAmount = this.calculatorForm.get('diezMilAmount').value;
		const cincoMilAmount = this.calculatorForm.get('cincoMilAmount').value;
		const dosMilAmount = this.calculatorForm.get('dosMilAmount').value;
		const milBilleteAmount = this.calculatorForm.get('milBilleteAmount').value;
		const milMonedaAmount = this.calculatorForm.get('milMonedaAmount').value;
		const quinientosAmount = this.calculatorForm.get('quinientosAmount').value;
		const docientosAmount = this.calculatorForm.get('docientosAmount').value;
		const cienAmount = this.calculatorForm.get('cienAmount').value;
		const cincuentaAmount = this.calculatorForm.get('cincuentaAmount').value;
		this.calculatorForm.patchValue({
			cienMilTotal: cienMilAmount * 100000,
			cincuentaMilTotal: cincuentaMilAmount * 50000,
			veinteMilTotal: veinteMilAmount * 20000,
			diezMilTotal: diezMilAmount * 10000,
			cincoMilTotal: cincoMilAmount * 5000,
			dosMilTotal: dosMilAmount * 2000,
			milBilleteTotal: milBilleteAmount * 1000,
			milMonedaTotal: milMonedaAmount * 1000,
			quinientosTotal: quinientosAmount * 500,
			docientosTotal: docientosAmount * 200,
			cienTotal: cienAmount * 100,
			cincuentaTotal: cincuentaAmount * 50,
			totalValue: (cienMilAmount * 100000) + (cincuentaMilAmount * 50000) + (veinteMilAmount * 20000) + (diezMilAmount * 10000) + (cincoMilAmount * 5000) + (dosMilAmount * 2000) +
									(milBilleteAmount * 1000) + (milMonedaAmount * 1000) + (quinientosAmount * 500) + (docientosAmount * 200) + (cienAmount * 100) + (cincuentaAmount * 50),
		});
	}

	/**
   * Cierra el diálogo.
   */
	public close(): void {
		this._windowRef.close();
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Secundarios
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Construye el formulario.
	 */
	private _buildForm(): void {
		this.calculatorForm = this._formBuilder.group({
			cienMilAmount: 0,
			cincuentaMilAmount: 0,
			veinteMilAmount: 0,
			diezMilAmount: 0,
			cincoMilAmount: 0,
			dosMilAmount: 0,
			milBilleteAmount: 0,
			milMonedaAmount: 0,
			quinientosAmount: 0,
			docientosAmount: 0,
			cienAmount: 0,
			cincuentaAmount: 0,
			cienMilValue: {value: 100000, disabled: true},
			cincuentaMilValue: {value: 50000, disabled: true},
			veinteMilValue: {value: 20000, disabled: true},
			diezMilValue: {value: 10000, disabled: true},
			cincoMilValue: {value: 5000, disabled: true},
			dosMilValue: {value: 2000, disabled: true},
			milBilleteValue: {value: 1000, disabled: true},
			milMonedaValue: {value: 1000, disabled: true},
			quinientosValue: {value: 500, disabled: true},
			docientosValue: {value: 200, disabled: true},
			cienValue: {value: 100, disabled: true},
			cincuentaValue: {value: 50, disabled: true},
			cienMilTotal: {value: 0, disabled: true},
			cincuentaMilTotal: {value: 0, disabled: true},
			veinteMilTotal: {value: 0, disabled: true},
			diezMilTotal: {value: 0, disabled: true},
			cincoMilTotal: {value: 0, disabled: true},
			dosMilTotal: {value: 0, disabled: true},
			milBilleteTotal: {value: 0, disabled: true},
			milMonedaTotal: {value: 0, disabled: true},
			quinientosTotal: {value: 0, disabled: true},
			docientosTotal: {value: 0, disabled: true},
			cienTotal: {value: 0, disabled: true},
			cincuentaTotal: {value: 0, disabled: true},
			totalValue: {value: 0, disabled: true},
		});
	}
}
