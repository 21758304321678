import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ConceptDetail } from '../models/concept-detail';
import { StateDetail } from '../models/state-detail';
import { TypeDetail } from '../models/type-detail';

import { APPLICATION_JSON, URL_HOST } from '../../app';

import { AuthenticationService } from './authentication.service';

@Injectable({
	providedIn: 'root'
})
export class BasicService {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Variables
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	private _url = `${URL_HOST}`;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Constructor
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	constructor(private _httpClient: HttpClient, private _authService: AuthenticationService) { }

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Principales
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Busca la lista modelo concepto.
	 * @param params Parámetros de la solicitud.
	 */
	public findAllConcepts(params?: any): Promise<Array<ConceptDetail>> {
		return new Promise((resolve, reject) => {
			this._authService.getToken().then(
				token => {
					const httpHeaders = new HttpHeaders({
						'Content-Type': APPLICATION_JSON,
						Authorization: token
					});
					const httpParams = new HttpParams({
						fromObject: params
					});
					this._httpClient.get<Array<any>>(`${this._url}/concepts`, {headers: httpHeaders, params: httpParams}).toPromise().then(
						response => resolve(response.map(item => ConceptDetail.fromJson(item))),
						exception => reject(exception)
					);
				},
				error => reject(error)
			);
		});
	}

	/**
	 * Busca la lista modelo estado.
	 * @param params Parámetros de la solicitud.
	 */
	public findAllStates(params?: any): Promise<Array<StateDetail>> {
		return new Promise((resolve, reject) => {
			this._authService.getToken().then(
				token => {
					const httpHeaders = new HttpHeaders({
						'Content-Type': APPLICATION_JSON,
						Authorization: token
					});
					const httpParams = new HttpParams({
						fromObject: params
					});
					this._httpClient.get<Array<any>>(`${this._url}/states`, {headers: httpHeaders, params: httpParams}).toPromise().then(
						response => resolve(response.map(item => StateDetail.fromJson(item))),
						exception => reject(exception)
					);
				},
				error => reject(error)
			);
		});
	}

	/**
	 * Busca la lista modelo tipo.
	 * @param params Parámetros de la solicitud.
	 */
	public findAllTypes(params?: any): Promise<Array<TypeDetail>> {
		return new Promise((resolve, reject) => {
			this._authService.getToken().then(
				token => {
					const httpHeaders = new HttpHeaders({
						'Content-Type': APPLICATION_JSON,
						Authorization: token
					});
					const httpParams = new HttpParams({
						fromObject: params
					});
					this._httpClient.get<Array<any>>(`${this._url}/types`, {headers: httpHeaders, params: httpParams}).toPromise().then(
						response => resolve(response.map(item => TypeDetail.fromJson(item))),
						exception => reject(exception)
					);
				},
				error => reject(error)
			);
		});
	}
}
