import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { User } from '../models/user';

import { APPLICATION_FORM, AUTH_PREFIX, CLIENT_ID, URL_HOST } from '../../app';
import { PREF_ACCESS_TOKEN, PREF_REFRESH_TOKEN, PREF_USER_ID } from '../../app';

@Injectable({
	providedIn: 'root'
})
export class AuthenticationService {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Variables
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	private _url = `${URL_HOST}/auth`;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	private _token: string;
	public get token(): string {
		if (this._token == null) { this._token = localStorage.getItem(PREF_ACCESS_TOKEN); }
		return this._token;
	}

	private _tokenRefresh: string;
	public get tokenRefresh(): string {
		if (this._tokenRefresh == null) { this._tokenRefresh = localStorage.getItem(PREF_REFRESH_TOKEN); }
		return this._tokenRefresh;
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Constructor
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	constructor(private _httpClient: HttpClient) { }

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Principales
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Obtiene el token de autorización.
	 */
	public getToken(): Promise<string> {
		if (this.isTokenExpired()) {
			const headers = new HttpHeaders({
				'Content-Type': APPLICATION_FORM,
			});
			const body = new HttpParams({
				fromObject: {
					token: `${localStorage.getItem(PREF_REFRESH_TOKEN)}`,
					cliente: CLIENT_ID,
				}
			});
			return new Promise((resolve, reject) => {
				this._httpClient.post<any>(`${this._url}/token`, body, {headers: headers}).toPromise().then(
					response => {
						this._token = response.access_token;
						localStorage.setItem(PREF_ACCESS_TOKEN, response.access_token);
						localStorage.setItem(PREF_REFRESH_TOKEN, response.refresh_token);
						resolve(`${AUTH_PREFIX} ${this._token}`);
					},
					exception => reject(exception)
				);
			});
		} else {
			return Promise.resolve(`${AUTH_PREFIX} ${this.token}`);
		}
	}

	/**
	 * Inicia la sesión del modelo usuario.
	 * @param model Modelo usuario.
	 */
	public login(model: User): Promise<void> {
		const headers = new HttpHeaders({
			'Content-Type': APPLICATION_FORM,
		});
		const body = new HttpParams({
			fromObject: {
				codigo: model.code,
				clave: model.password,
				cliente: CLIENT_ID,
			}
		});
		return new Promise((resolve, reject) => {
			this._httpClient.post<any>(`${this._url}/login`, body, {headers: headers}).toPromise().then(
				response => {
					localStorage.setItem(PREF_ACCESS_TOKEN, response.access_token);
					localStorage.setItem(PREF_REFRESH_TOKEN, response.refresh_token);
					localStorage.setItem(PREF_USER_ID, this._getTokenUserId());
					resolve();
				},
				exception => reject(exception)
			);
		});
	}

	/**
	 * Cierra la sesión del modelo usuario.
	 * @param model Modelo usuario.
	 */
	public logout(): void {
		this._token = null;
		this._tokenRefresh = null;
		localStorage.clear();
	}

	/**
	 * Cambia la contraseña del modelo usuario.
	 * @param model Modelo usuario.
	 */
	public changePassword(model: User): Promise<void> {
		return new Promise((resolve, reject) => {
			this.getToken().then(
				token => {
					const headers = new HttpHeaders({
						'Content-Type': APPLICATION_FORM,
						Authorization: token
					});
					const body = new HttpParams({
						fromObject: {
							clave_ant: model.oldPassword,
							clave_nue: model.newPassword,
						}
					});
					this._httpClient.post<any>(`${this._url}/change-password`, body, {headers: headers}).toPromise().then(
						response => resolve(response),
						exception => reject(exception),
					);
				},
				error => reject(error)
			);
		});
	}

	/**
	 * Solicita la contraseña del modelo usuario.
	 * @param model Modelo usuario.
	 */
	public requestPassword(model: User): Promise<void> {
		const headers = new HttpHeaders({
			'Content-Type': APPLICATION_FORM,
		});
		const body = new HttpParams({
			fromObject: {
				email: model.email,
				cliente: CLIENT_ID,
			}
		});
		return new Promise((resolve, reject) => {
			this._httpClient.post<any>(`${this._url}/request-password`, body, {headers: headers}).toPromise().then(
				response => resolve(response),
				exception => reject(exception),
			);
		});
	}

	/**
	 * Indica si el usuario está autenticado.
	 */
	public isAuthenticated(): boolean {
		if (!this.token) { return false; }
		const payload = JSON.parse(atob(this.token.split('.')[1]));
		if (payload != null && payload.user_name && payload.user_name.length > 0) { return true; }
		return false;
	}

	/**
	 * Indica si el token ha expirado.
	 */
	public isTokenExpired(): boolean {
		if (!this.token) { return true; }
		const date = this._getTokenExpirationDate();
		if (date == null) { return true; }
		return !(date.valueOf() > new Date().valueOf());
	}

	/**
	 * Indica si el token refresh ha expirado.
	 */
	public isTokenRefreshExpired(): boolean {
		if (!this.tokenRefresh) { return true; }
		const date = this._getTokenRefreshExpirationDate();
		if (date == null) { return true; }
		return !(date.valueOf() > new Date().valueOf());
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Funciones Secundarias
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Obtiene el id del usuario del token.
	 */
	public _getTokenUserId(): string {
		const payload = JSON.parse(atob(this.token.split('.')[1]));
		if (payload != null && payload.user_id) {
			return payload.user_id;
		}
		return '';
	}

	/**
	 * Obtiene el código del usuario del token.
	 */
	public _getTokenUserCode(): string {
		const payload = JSON.parse(atob(this.token.split('.')[1]));
		if (payload != null && payload.user_name) {
			return payload.user_name;
		}
		return '';
	}

	/**
	 * Obtiene la fecha de expiración del token.
	 */
	private _getTokenExpirationDate(): Date {
		const payload = JSON.parse(atob(this.token.split('.')[1]));
		if (payload != null && payload.exp) {
			const date = new Date(0);
			date.setUTCSeconds(payload.exp);
			return date;
		}
		return null;
	}

	/**
	 * Obtiene la fecha de expiración del token refresh.
	 */
	private _getTokenRefreshExpirationDate(): Date {
		const payload = JSON.parse(atob(this.tokenRefresh.split('.')[1]));
		if (payload != null && payload.exp) {
			const date = new Date(0);
			date.setUTCSeconds(payload.exp);
			return date;
		}
		return null;
	}
}
