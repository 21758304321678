<nb-card status="success">
	<nb-card-header>
		<div class="row m-0">
			<h5 class="title col p-0">Tarjetas</h5>
			<nb-icon class="close col-auto p-0" icon="close" (click)="close()"></nb-icon>
		</div>
	</nb-card-header>
	<nb-card-body>
		<form name="saleCardForm" [formGroup]="saleCardForm" novalidate>
			<div class="mb-2" formGroupName="vehicle">
				<label class="label">Vehículo:</label>
				<div class="row m-0">
					<div class="col-10 p-0">
						<input nbInput class="input uppercase" formControlName="fullName" type="text" fieldSize="medium" [attr.disabled]="true" fullWidth>
					</div>
					<div class="center-vertical">
						<nb-icon class="button-icon" icon="search" pack="font-awesome" (click)="showDialogVehicles()"></nb-icon>
					</div>
				</div>
				<ng-container *ngIf="isNotValid('vehicle.fullName', triedAction)">
					<p class="caption" *ngIf="saleCardForm.get('vehicle.fullName').hasError('required')">Vehículo es requerido!</p>
				</ng-container>
			</div>
			<div class="mb-2" formGroupName="driver">
				<label class="label">Conductor:</label>
				<div class="row m-0">
					<div class="col-10 p-0">
						<input nbInput class="input uppercase" formControlName="fullName" type="text" fieldSize="medium" [attr.disabled]="true" fullWidth>
					</div>
					<div class="center-vertical">
						<nb-icon class="button-icon" icon="search" pack="font-awesome" (click)="showDialogDrivers()"></nb-icon>
					</div>
				</div>
				<ng-container *ngIf="isNotValid('driver.fullName', triedAction)">
					<p class="caption" *ngIf="saleCardForm.get('driver.fullName').hasError('required')">Conductor es requerido!</p>
				</ng-container>
			</div>
			<div class="row m-0 mb-2">
				<div class="col-3 p-0">
					<label class="label">Cantidad:</label>
					<input nbInput class="input" formControlName="quantity" type="number" fieldSize="medium" oninput="if(value.length > 2) value = value.slice(0, 2)" fullWidth (keyup)="calculateTotalValue()">
				</div>
				<div class="col-4 p-0">
					<label class="label">Valor Unitario:</label>
					<input nbInput class="input" formControlName="unitValue" type="tel" fieldSize="medium" [attr.disabled]="true" currencyMask fullWidth>
				</div>
				<div class="col-5 p-0">
					<label class="label">Valor Total:</label>
					<input nbInput class="input" formControlName="totalValue" type="tel" fieldSize="medium" [attr.disabled]="true" currencyMask fullWidth>
				</div>
			</div>
			<div class="mb-2">
				<label class="label">Observación:</label>
				<textarea nbInput class="textarea" formControlName="observation" type="text" fullWidth></textarea>
			</div>
			<div class="row center-horizontal" *ngIf="saleCardForm.get('id').value == 0">
				<button nbButton class="button" type="submit" size="small" shape="round" status="primary" (click)='createSaleCard()'>
					<nb-icon icon="save"></nb-icon>Registrar
				</button>
			</div>
		</form>
	</nb-card-body>
</nb-card>
<ngx-loading [show]="loading"></ngx-loading>