import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbMenuItem } from '@nebular/theme';
import { Md5 } from 'md5-typescript';
import { Module } from 'src/app/models/module';

import { PREF_ROLE_ID, PREF_USER_ID } from 'src/app';

import { NbMenuService } from '@nebular/theme';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ModuleService } from 'src/app/services/module.service';
import { UserService } from 'src/app/services/user.service';

@Component({
	selector: 'sts-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Variables
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	private _modules: Array<Module>;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	public loading = false;
	public navigation = Array<NbMenuItem>();

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Constructor
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	constructor(private _router: Router, private _menuService: NbMenuService, private _authService: AuthenticationService,
							private _moduleService: ModuleService, private _userService: UserService) {
		if (this._authService.isAuthenticated()) {
			this.loading = true;
			let idRol = +localStorage.getItem(PREF_ROLE_ID);
			if (idRol === 0) {
				this._userService.find(+localStorage.getItem(PREF_USER_ID), {
					this: 'activo, exclude',
					rol: 'id, nombre, include',
				}).then(
					model => {
						idRol = model.role.id;
						localStorage.setItem(PREF_ROLE_ID, idRol.toString());
						this._findModules(idRol);
					},
				);
			} else {
				this._findModules(idRol);
			}
		}
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Propios
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	ngOnInit() {
		this._menuService.onItemClick().subscribe(({item}) => {
			if (!isNaN(item.data.id)) {
				this._selectMenu(item.data);
				this._router.navigate([item.link], {replaceUrl: true});
			}
		});
		// Md5.init(data);
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Secundarios
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Busca la lista modelo módulo según los parámetros.
	 * @param idRole Id del rol.
	 */
	private _findModules(idRole: number) {
		this._moduleService.findAllBy({
			role_id: idRole,
			type: 'WEB',
			this: 'codigo, exclude',
			menus: 'tipo, exclude',
		}).then(
			models => {
				this.loading = false;
				this.navigation = Array();
				this._modules = models;
				this._modules.forEach((module) => {
					this.navigation.push({
						title: module.name,
						group: true,
					});
					this._addMenus(module);
				});
			}
		);
	}

	/**
   * Agrega los menús a partir del módulo.
   * @param model Modelo módulo.
   */
	private _addMenus(model: Module): void {
		model.menus.forEach((menu) => {
			if (menu.submenus == null) {
				this.navigation.push({
					title: menu.name,
					link: menu.path,
					icon: {icon: menu.icon, pack: 'font-awesome'},
					data: {id: menu.id, path: menu.path, type: 'menu'},
					skipLocationChange: false,
				});
			} else {
				this.navigation.push({
					title: menu.name,
					icon: {icon: menu.icon, pack: 'font-awesome'},
					children: menu.submenus.map((item) => {
						const menuItem = new NbMenuItem();
						menuItem.title = item.name;
						menuItem.link = item.path;
						menuItem.data = {id: item.id, path: item.path, type: 'submenu'};
						return menuItem;
					}),
					skipLocationChange: false,
				});
			}
		});
	}

	/**
	 * Selecciona el menú de la navegación.
	 * @param data Datos adicionales.
	 */
	private _selectMenu(data?: any) {
		for (const menu of this.navigation) {
			if (!menu.group) {
				if (menu.children != null) {
					for (const submenu of menu.children) {
						submenu.selected = submenu.data.id === data.id && data.type === 'submenu';
					}
				} else {
					menu.selected = menu.data.id === data.id && data.type === 'menu';
				}
			}
		}
	}
}
