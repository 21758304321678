import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { BearingDetail } from 'src/app/models/bearing-detail';
import { StateDetail } from 'src/app/models/state-detail';

import { DialogDriverListComponent } from '../driver-list/dialog-driver-list.component';
import { DialogVehicleListComponent } from '../vehicle-list/dialog-vehicle-list.component';

import { NbDialogService, NbToastrService } from '@nebular/theme';
import { BasicService } from 'src/app/services/basic.service';
import { BearingService } from 'src/app/services/bearing.service';
import { DriverService } from 'src/app/services/driver.service';
import { VehicleService } from 'src/app/services/vehicle.service';

@Component({
	templateUrl: 'dialog-bearing-detail.component.html',
	styleUrls: ['dialog-bearing-detail.component.scss'],
})
export class DialogBearingDetailComponent implements OnInit {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	public loading = false;
	public triedAction = false;
	public bearingDetailForm: FormGroup;
	public states: Array<StateDetail>;

	@Input() public bearingDetail: BearingDetail;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Constructor
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	constructor(private _formBuilder: FormBuilder, protected _dialogRef: NbDialogRef<DialogBearingDetailComponent>,
				private _dialogService: NbDialogService, private _toastrService: NbToastrService,
				private _basicService: BasicService, private _bearingService: BearingService,
				private _driverService: DriverService, private _vehicleService: VehicleService) {
		this.states = new Array<StateDetail>();
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Propios
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	ngOnInit(): void {
		this._buildForm();
		this._basicService.findAllStates({
			catalog_code: 'ROD',
			this: 'activo, exclude',
		}).then(
			response => {
				this.states = response;
				setTimeout(() => {
					this._fillForm();
				}, 100);
			},
			exception => this._handleFailure(exception)
		);
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Principales
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Obtiene el estado del control del formulario.
	 * @param controlName Nombre del control.
	 */
	public getStatus(controlName: string, triedAction?: boolean): string {
		const control = this.bearingDetailForm.get(controlName);
		if (!triedAction) {
			return control.dirty ? (control.invalid  ? 'danger' : 'basic') : 'basic';
		} else {
			return control.invalid ? 'danger' : 'basic';
		}
	}

	/**
   * Actualiza el modelo detalle.
   */
	public updateBearingDetail(): void {
		this.triedAction = true;
		if (!this.bearingDetailForm.invalid) {
			this.loading = true;
			this._setDataModel();
			this._bearingService.updateDetail(this.bearingDetail).then(
				_response => {
					this.loading = false;
					this.triedAction = false;
					this.close(this.bearingDetail);
					this._handleMessage('Actualización exitosa!', 'info');
				},
				exception => this._handleFailure(exception),
			);
		}
	}

	/**
   * Muestra el diálogo de la lista modelo conductor.
   */
	public showDialogDrivers(): void {
		this.loading = true;
		this._driverService.findActiveAll({
			this: 'activo, exclude',
			empresa: 'id, nombre, include',
		}).then(
			models => {
				this.loading = false;
				this._dialogService.open(DialogDriverListComponent, {
					context: {
						drivers: models,
					},
					closeOnBackdropClick: false,
				}).onClose.subscribe(model => {
					if (model != null) {
						this.bearingDetail.driver = model;
						this.bearingDetailForm.patchValue({
							driver: {
								id: this.bearingDetail.driver.id,
								fullName: this.bearingDetail.driver.names + ' ' + this.bearingDetail.driver.surnames,
							}
						});
					}
				});
			},
			exception => this._handleFailure(exception),
		);
	}

	/**
   * Muestra el diálogo de la lista modelo vehículo.
   */
	public showDialogVehicles(): void {
		this.loading = true;
		this._vehicleService.findActiveAll({
			this: 'activo, exclude',
			empresa: 'id, nombre, include',
		}).then(
			models => {
				this.loading = false;
				this._dialogService.open(DialogVehicleListComponent, {
					context: {
						vehicles: models,
					},
					closeOnBackdropClick: false,
				}).onClose.subscribe(model => {
					if (model != null) {
						this.bearingDetail.vehicle = model;
						this.bearingDetailForm.patchValue({
							vehicle: {
								id: this.bearingDetail.vehicle.id,
								internalNumber: this.bearingDetail.vehicle.internalNumber,
								plaque: this.bearingDetail.vehicle.plaque,
								companyName: this.bearingDetail.vehicle.company.name,
							}
						});
					}
				});
			},
			exception => this._handleFailure(exception),
		);
	}

	/**
   * Cierra el diálogo.
   */
	public close(model?: BearingDetail): void {
		this._dialogRef.close(model);
	}

	/**
	 * Indica si no es válido el control del formulario.
	 * @param controlName Nombre del control.
	 */
	public isNotValid(controlName: string, triedAction?: boolean): boolean {
		const control = this.bearingDetailForm.get(controlName);
		if (!triedAction) {
			return control.dirty && control.invalid;
		} else {
			return control.invalid;
		}
	}

	/**
	 * Indica si es válido el estado del detalle.
	 */
	public isStateValid() {
		const estado = this.bearingDetailForm.get('stateCode').value;
		return estado !== 'PAG';
	}

	/**
	 * Indica si no es válido el estado del detalle.
	 */
	public isStateNotValid(code: string) {
		return code === 'VIA' || code === 'PAG';
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Secundarios
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Cambia los datos del modelo detalle.
	 */
	private _setDataModel(): void {
		this.bearingDetail.time = this.bearingDetailForm.get('time').value;
		this.bearingDetail.stateCode = this.bearingDetailForm.get('stateCode').value;
		this.bearingDetail.observation = this.bearingDetailForm.get('observation').value;
		for (const model of this.states) {
			if (model.code === this.bearingDetail.stateCode) {
				this.bearingDetail.stateName = model.name;
				break;
			}
		}
	}

	/**
	 * Construye el formulario.
	 */
	private _buildForm(): void {
		this.bearingDetailForm = this._formBuilder.group({
			id: 0,
			time: ['', [Validators.required]],
			stateCode: ['', [Validators.required]],
			observation: ['', [Validators.required]],
			vehicle: this._formBuilder.group({
				id: [0, [this._checkIdSize]],
				internalNumber: [{value: '', disabled: true}],
				plaque: [{value: '', disabled: true}],
				companyName: [{value: '', disabled: true}],
			}),
			driver: this._formBuilder.group({
				id: [0, [this._checkIdSize]],
				fullName: [{value: '', disabled: true}],
			}),
		});
	}

	/**
	 * Llena el formulario.
	 */
	private _fillForm(): void {
		this.bearingDetailForm.setValue({
			id: this.bearingDetail.id,
			time: this.bearingDetail.time,
			stateCode: this.bearingDetail.stateCode,
			observation: '',
			vehicle: {
				id: this.bearingDetail.vehicle.id,
				internalNumber: this.bearingDetail.vehicle.internalNumber,
				plaque: this.bearingDetail.vehicle.plaque,
				companyName: this.bearingDetail.vehicle.company.name,
			},
			driver: {
				id: this.bearingDetail.driver.id,
				fullName: this.bearingDetail.driver.names + ' ' + this.bearingDetail.driver.surnames,
			},
		});
		if (this.bearingDetail.stateCode === 'PAG') {
			this.bearingDetailForm.get('time').disable();
			this.bearingDetailForm.get('stateCode').disable();
			this.bearingDetailForm.get('observation').disable();
		}
	}

	/**
	 * Maneja el mensaje que se va a mostrar.
	 * @param type Tipo de mensaje.
	 */
	private _handleMessage(message: any, type: any) {
		this._toastrService.show(message, 'Detalle Rodamiento', {
			status: type,
			duration: 3000,
		});
	}

	/**
	 * Maneja la excepción que se ha generado.
	 */
	private _handleFailure(exception: any): void {
		this.loading = false;
		this._toastrService.show(exception.error, 'Detalle Rodamiento', {
			status: 'danger',
			duration: 3000,
		});
	}

	/**
	 * Chequea el tamaño del id.
	 */
	private _checkIdSize(control: FormControl) {
		const value: number = control.value;
		if (!value || value === 0) {
			return {
				idSize: true
			};
		}
		return null;
	}
}
