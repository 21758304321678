<nb-card status="success">
	<nb-card-header>
		<div class="row m-0">
			<h5 class="title col p-0">Cerrar Turno</h5>
			<nb-icon class="close col-auto p-0" icon="close" (click)="close()"></nb-icon>
		</div>
	</nb-card-header>
	<nb-card-body>
		<form name="turnForm" [formGroup]="turnForm" novalidate>
			<div class="mb-2">
				<label class="label">Base:</label>
				<input nbInput class="input" formControlName="baseValue" type="tel" fieldSize="medium" currencyMask fullWidth>
			</div>
			<div class="mb-2" *ngIf="type === 'CE'">
				<label class="label">Tarjetas:</label>
				<input nbInput class="input" formControlName="cardsAmount" type="number" fieldSize="medium" fullWidth>
			</div>
			<div class="mb-2">
				<label class="label">Efectivo:</label>
				<input nbInput class="input" formControlName="effectiveValue" type="tel" fieldSize="medium" currencyMask fullWidth>
			</div>
			<div class="mb-2" *ngIf="turnForm.get('balanceValue').value != 0">
				<label class="label">Saldo:</label>
				<input nbInput class="input" formControlName="balanceValue" type="tel" fieldSize="medium" currencyMask fullWidth>
			</div>
			<div class="center-horizontal mt-3">
				<button nbButton class="button" type="submit" size="small" shape="round" status="primary" (click)='closeTurn()'>
					<nb-icon icon="save"></nb-icon>Cerrar
				</button>
			</div>
		</form>
	</nb-card-body>
</nb-card>
<ngx-loading [show]="loading"></ngx-loading>