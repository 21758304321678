<nb-card status="success">
	<nb-card-header>
		<div class="row m-0">
			<h5 class="title col p-0">Bonos de Estudiante</h5>
			<nb-icon class="close col-auto p-0" icon="close" (click)="close()"></nb-icon>
		</div>
	</nb-card-header>
	<nb-card-body>
		<form name="voucherForm" [formGroup]="voucherForm" novalidate>
			<div class="row m-0 mb-2">
				<div class="col-4 p-0 pr-2">
					<label class="label">Código de Barras:</label>
					<input nbInput class="input uppercase" formControlName="barcode" type="text" fieldSize="medium" fullWidth (keyup)="validateBarcode(barcode.value)" #barcode>
				</div>
				<div class="col-3 p-0 pr-2">
					<label class="label">Consecutivo:</label>
					<input nbInput class="input uppercase" formControlName="number" type="number" fieldSize="medium" fullWidth>
					<ng-container *ngIf="isNotValid('number', triedAction)">
						<p class="caption" *ngIf="voucherForm.get('number').hasError('required')">Requerido!</p>
					</ng-container>
				</div>
				<div class="col-2 p-0 pr-2">
					<label class="label">Municipio:</label>
					<input nbInput class="input uppercase" formControlName="prefix" type="text" fieldSize="medium" [attr.disabled]="true" fullWidth>
					<ng-container *ngIf="isNotValid('prefix', triedAction)">
						<p class="caption" *ngIf="voucherForm.get('prefix').hasError('required')">Requerido!</p>
					</ng-container>
				</div>
				<div class="col-3 p-0">
					<label class="label">Valor:</label>
					<input nbInput class="input uppercase" formControlName="value" type="tel" fieldSize="medium" [attr.disabled]="true" currencyMask fullWidth>
					<ng-container *ngIf="isNotValid('value', triedAction)">
						<p class="caption" *ngIf="voucherForm.get('value').hasError('required') || voucherForm.get('value').value == 0">Requerido!</p>
					</ng-container>
				</div>
			</div>
			<div class="col mb-2 p-0" formGroupName="line">
				<label class="label">Linea:</label>
				<nb-select formControlName="id" [status]="getStatus('line.id', triedAction)" fullWidth>
					<nb-option [value]="line.id" *ngFor="let line of lines">{{line.route.name}} ({{line.time}})</nb-option>
				</nb-select>
				<ng-container *ngIf="isNotValidId('line.id', triedAction)">
					<p class="caption" *ngIf="voucherForm.get('line.id').value == 0">Requerida!</p>
				</ng-container>
			</div>
			<div class="mb-2">
				<label class="label">Observación:</label>
				<textarea nbInput class="input" formControlName="observation" type="text" [status]="getStatus('observation', triedAction)" fullWidth></textarea>
				<ng-container *ngIf="isNotValid('observation', triedAction)">
					<p class="caption" *ngIf="voucherForm.get('observation').hasError('required')">Observación es requerida!</p>
				</ng-container>
			</div>
			<div class="center-horizontal mb-2">
				<button nbButton class="button" type="submit" size="small" shape="round" status="primary" (click)="removeData()">
					<nb-icon icon="trash"></nb-icon>Limpiar
				</button>
				<button nbButton class="button" type="submit" size="small" shape="round" status="primary" (click)="createVoucher()" *ngIf="lines.length > 0">
					<nb-icon icon="save"></nb-icon>Registar
				</button>
			</div>
			<div class="mb-2">
				<label class="label">Bonos Actuales:</label>
				<ng2-smart-table [source]="source" [settings]="settings"></ng2-smart-table>
			</div>
			<div class="center-horizontal">
				<button nbButton class="button" type="submit" size="small" shape="round" status="primary" (click)="finishVouchers()" *ngIf="vouchers.length > 0">
					<nb-icon icon="checkmark-circle-2-outline"></nb-icon>Finalizar
				</button>
			</div>
		</form>
	</nb-card-body>
</nb-card>
<ngx-loading [show]="loading"></ngx-loading>