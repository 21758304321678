<nb-card>
	<nb-card-header>
		<h5 class="title col p-0">{{title}}</h5>
	</nb-card-header>
	<nb-card-body>
		<div class="mb-3">
			<label class="label text-justify m-0">{{message}}</label>
		</div>
		<div class="center-horizontal">
			<button nbButton class="button" type="submit" size="small" shape="round" status="primary" (click)='close()'>
				<nb-icon icon="checkmark"></nb-icon>{{textButton}}
			</button>
		</div>
	</nb-card-body>
</nb-card>