import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { RecoverPoint } from 'src/app/models/recover-point';
import { Turn } from 'src/app/models/turn';

import { PREF_USER_ID } from 'src/app';

import { NbToastrService } from '@nebular/theme';
import { RecoverPointService } from 'src/app/services/recover-point.service';
import { TurnService } from 'src/app/services/turn.service';

@Component({
	templateUrl: 'dialog-turn-open.component.html',
	styleUrls: ['dialog-turn-open.component.scss'],
})
export class DialogTurnOpenComponent implements OnInit {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	public loading = false;
	public triedAction = false;
	public turnForm: FormGroup;
	public points: Array<RecoverPoint>;

	@Input() public turn: Turn;
	@Input() public type: string;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Constructor
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	constructor(private _formBuilder: FormBuilder, protected _dialogRef: NbDialogRef<DialogTurnOpenComponent>,
				private _toastrService: NbToastrService, private _pointService: RecoverPointService, private _turnService: TurnService) { }

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Propios
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	ngOnInit(): void {
		this._buildForm();
		this.loading = true;
		this.turn = new Turn();
		this._pointService.findAllBy({
			type: this.type,
			this: 'activo, exclude',
		}).then(
			models => {
				this.points = models;
				this._pointService.findBy({
					user_id: +localStorage.getItem(PREF_USER_ID)
				}).then(
					model => {
						this.loading = false;
						this.turn.recoverPoint = model != null ? model : new RecoverPoint();
						setTimeout(() => {
							this._fillForm();
						}, 100);
					},
					_exception => this.loading = false
				);
			},
			exception => this._handleFailure(exception)
		);
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Eventos
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	public onSelectPoint(code: string): void {
		for (const model of this.points) {
			if (model.code === code) {
				this.turn.recoverPoint = model;
				break;
			}
		}
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Principales
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Obtiene el estado del control del formulario.
	 * @param controlName Nombre del control.
	 */
	public getStatus(controlName: string, triedAction?: boolean): string {
		const control = this.turnForm.get(controlName);
		if (!triedAction) {
			return control.dirty ? (control.invalid  ? 'danger' : 'basic') : 'basic';
		} else {
			return control.invalid ? 'danger' : 'basic';
		}
	}

	/**
   * Crea el modelo turno.
   */
	public createTurn(): void {
		this.triedAction = true;
		if (!this.turnForm.invalid) {
			this.loading = true;
			this._setDataModel();
			this._turnService.create(this.turn).then(
				response => {
					this._turnService.find(response).then(
						model => {
							this.loading = false;
							this.close(model);
						},
						exception => this._handleFailure(exception)
					);
				},
				exception => this._handleFailure(exception)
			);
		}
	}

	/**
   * Cierra el diálogo.
   */
	public close(model?: Turn): void {
		this._dialogRef.close(model);
	}

	/**
	 * Indica si no es válido el control del formulario.
	 * @param controlName Nombre del control.
	 */
	public isNotValid(controlName: string, triedAction?: boolean): boolean {
		const control = this.turnForm.get(controlName);
		if (!triedAction) {
			return control.dirty && control.invalid;
		} else {
			return control.invalid;
		}
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Secundarios
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Cambia los datos del modelo detalle.
	 */
	private _setDataModel(): void {
		this.turn.openCardsAmount = this.turnForm.get('cardsAmount').value;
		this.turn.baseValue = this.turnForm.get('baseValue').value;
		this.turn.effectiveValue = 0;
	}

	/**
	 * Construye el formulario.
	 */
	private _buildForm(): void {
		this.turnForm = this._formBuilder.group({
			pointCode: ['', [Validators.required]],
			cardsAmount: [0, [Validators.required]],
			baseValue: [0, [Validators.required]],
		});
	}

	/**
	 * Llena el formulario.
	 */
	private _fillForm(): void {
		this.turnForm.setValue({
			pointCode: this.turn.recoverPoint.code,
			cardsAmount: 0,
			baseValue: 0,
		});
	}

	/**
	 * Maneja el mensaje que se va a mostrar.
	 * @param type Tipo de mensaje.
	 */
	private _handleMessage(message: any, type: any) {
		this._toastrService.show(message, 'Turno', {
			status: type,
			duration: 3000,
		});
	}

	/**
	 * Maneja la excepción que se ha generado.
	 */
	private _handleFailure(exception: any): void {
		this.loading = false;
		this._toastrService.show(exception.error, 'Turno', {
			status: 'danger',
			duration: 3000,
		});
	}
}
