import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { LocalDataSource } from 'ng2-smart-table';
import { Line } from 'src/app/models/line';
import { Recover } from 'src/app/models/recover';
import { Voucher } from 'src/app/models/voucher';

import pdfMake from 'pdfmake/build/pdfmake';
import { PdfAssetsLoader } from 'pdfmake-utils';

import { NbToastrService } from '@nebular/theme';
import { VoucherService } from 'src/app/services/voucher.service';
import { RecoverService } from 'src/app/services/recover.service';
import { DialogQuestionComponent } from '../question/dialog-question.component';

@Component({
	templateUrl: './dialog-voucher-list.component.html',
	styleUrls: ['./dialog-voucher-list.component.scss'],
})
export class DialogVoucherListComponent implements OnInit {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	private _voucher: Voucher;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	public loading = false;
	public triedAction = false;
	public settings: any;
	public source = new LocalDataSource();
	public voucherForm: FormGroup;
	public lines: Array<Line>;

	@Input() public recover: Recover;
	@Input() public vouchers: Array<Voucher>;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Constructor
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	constructor(private _formBuilder: FormBuilder, private _currencyPipe: CurrencyPipe, protected _dialogRef: NbDialogRef<DialogVoucherListComponent>,
				private _dialogService: NbDialogService, private _toastrService: NbToastrService, private _recoverService: RecoverService, private _voucherService: VoucherService) {
		this._voucher = new Voucher();
		this._voucher.line = new Line();
		this.lines = new Array();
		this.settings = {
			columns: {
				time: {
					title: 'Hora',
					type: 'text',
					width: '5%',
				},
				prefix: {
					title: 'Municipio',
					type: 'text',
					width: '5%',
				},
				number: {
					title: 'Consecutivo',
					type: 'text',
					width: '5%',
				},
				value: {
					title: 'Valor',
					type: 'text',
					width: '10%',
				},
			},
			actions: {
				columnTitle: '',
				position: 'right',
				add: false,
				edit: false,
				delete: false,
			},
			edit: {
				editButtonContent: '<i class="fa fa-pen fa-xs" title="Editar"></i>',
			},
			delete: {
				deleteButtonContent: '<i class="fa fa-trash fa-xs" title="Eliminar"></i>',
			},
			attr: {
				class: 'table table-bordered'
			},
			pager: {
				perPage: 3,
			},
			mode: 'external',
			noDataMessage: '',
			defaultStyle: false,
			hideSubHeader: true,
		};
		const assetsLoader = new PdfAssetsLoader();
		assetsLoader.registerFont({name: 'Courier', fileName: 'Courier', URL: 'assets/fonts/Courier.afm', styles: ['normal']});
		assetsLoader.registerFont({name: 'Courier', fileName: 'Courier-Bold', URL: 'assets/fonts/Courier-Bold.afm', styles: ['bold']});
		assetsLoader.load().then(() => {
			assetsLoader.configurePdfMake(pdfMake);
		}).catch((_error: any) => {
			assetsLoader.configurePdfMake(pdfMake);
		});
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Propios
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	ngOnInit(): void {
		this._buildForm();
		this.recover.details.forEach((model) => {
			this.lines.push(model.line);
		});
		this._loadData();
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Principales
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Obtiene el estado del control del formulario.
	 * @param controlName Nombre del control.
	 */
	public getStatus(controlName: string, triedAction?: boolean): string {
		const control = this.voucherForm.get(controlName);
		if (!triedAction) {
			return control.dirty ? (control.invalid  ? 'danger' : 'basic') : 'basic';
		} else {
			return control.invalid ? 'danger' : 'basic';
		}
	}

	/**
	 * Crea el modelo bono.
	 */
	public createVoucher(): void {
		this.triedAction = true;
		if (!this.voucherForm.invalid) {
			const consecutive = this.voucherForm.get('number').value;
			this._showDialogQuestion('Registro de Bonos', `¿Desea registrar el bono de estudiante # ${consecutive}? 
													  	   Tenga en cuenta que no se podrá eliminar.`, (result) => {
				if(result) {
					this.loading = true;
					this._setDataModel();
					this._voucherService.create(this._voucher).then(
						_ => {
							this._voucherService.findAllBy({
								recover_id: this.recover.id,
							}).then(
								models => {
									this.loading = false;
									this.vouchers = models;
									this.removeData();
									this._loadData();
									this._handleMessage('Registro exitoso!', 'info');
								},
								exception => this._handleFailure(exception),
							);
						},
						exception => this._handleFailure(exception),
					);
				}
			}, 'Registrar', 'Cancelar');
		}
	}

	/**
	 * Finaliza el registro de bonos.
	 */
	public finishVouchers(): void {
		this._showDialogQuestion('Registro de Bonos', '¿Desea finalizar el registro de bonos de estudiante? ' +
													  'Tenga en cuenta que no se podrá registrar más.', (result) => {
			if(result) {
				this.loading = true;
				this.recover.stateVoucherCode = 'FCB';
				const params = {
					type: 'stateVoucherCode',
				};
				this._recoverService.updateBy(this.recover, params).then(
					_ => {
						this.loading = false;
						this.close();
					},
					exception => this._handleFailure(exception)
				);
			}
		}, 'Finalizar', 'Cancelar');
	}

	/**
	 * Valida el código de barras ingresado,
	 * @param barcode Código de barras.
	 */
	public validateBarcode(barcode: string): void {
		this.loading = true;
		setTimeout(() => {
			try {
				this.loading = false;
				let texts = barcode.split("'");
				if(texts.length > 1) {
					let prefix = texts[0];
					let value = parseInt(texts[1]);
					
					if(prefix.length === 2) {
						this._voucher.prefix = prefix;
						this._voucher.value = value;
					} else {
						this._voucher.prefix = '';
						this._voucher.value = 0;
					}
					this.voucherForm.patchValue({
						prefix: this._voucher.prefix,
						value: this._voucher.value,
					});
				}
			} catch(exception) {
				console.log(exception.message);
			}
		}, 500);
	}

	/**
	 * Elimina los datos.
	 */
	public removeData(): void {
		this.triedAction = false;
		this._clearForm();
	}

	/**
	 * Cierra el diálogo.
	 */
	public close(): void {
		this._dialogRef.close(this.recover);
	}

	/**
	 * Indica si no es válido el control del formulario.
	 * @param controlName Nombre del control.
	 */
	public isNotValid(controlName: string, triedAction?: boolean): boolean {
		const control = this.voucherForm.get(controlName);
		if (!triedAction) {
			return control.dirty && control.invalid;
		} else {
			return control.invalid;
		}
	}

	/**
	 * Indica si es valido el id del control del formulario.
	 * @param controlName Nombre del control.
	 */
	 public isNotValidId(controlName: string, triedAction?: boolean): any {
		const control = this.voucherForm.get(controlName);
		const value: number = control.value;
		if (triedAction) {
			if (!value || value === 0) {
				return {
					idSize: true
				};
			}
			return control.invalid;
		} else {
			return control.dirty && control.invalid;
		}
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Secundarios
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Cambia los datos del modelo ingreso.
	 */
	private _setDataModel(): void {
		this._voucher.idRecover = this.recover.id;
		this._voucher.number = this.voucherForm.get('number').value;
		this._voucher.barcode = this.voucherForm.get('barcode').value;
		this._voucher.observation = this.voucherForm.get('observation').value;
		this._voucher.line.id = this.voucherForm.get('line.id').value;
		if (this._voucher.barcode != null) {
			if (this._voucher.barcode.trim().length === 0) {
				this._voucher.barcode = null;
			}
		}
		if (this._voucher.observation != null) {
			if (this._voucher.observation.trim().length === 0) {
				this._voucher.observation = null;
			}
		}
	}

	/**
	 * Construye el formulario.
	 */
	private _buildForm(): void {
		this.voucherForm = this._formBuilder.group({
			id: 0,
			barcode: '',
			prefix: ['', [Validators.required]],
			number: ['', [Validators.required]],
			value: [0, [Validators.required]],
			observation: '',
			line: this._formBuilder.group({
				id: [0, [this._checkIdSize]],
			}),
		});
	}

	/**
	 * Limpia el formulario.
	 */
	private _clearForm(): void {
		this._voucher.id = 0;
		this._voucher.line.id = 0;
		this.voucherForm.reset({
			id: 0,
			barcode: '',
			prefix: '',
			number: '',
			value: 0,
			observation: '',
			line: {
				id: 0,
			},
		});
	}

	/**
	 * Carga la fuente de datos de la tabla.
	 */
	private _loadData(): void {
		const items = Array<any>();
		this.vouchers.forEach((model) => {
			items.push({
				id: model.id,
				prefix: model.prefix,
				number: model.number,
				time: model.line.time,
				value: this.toCurrency(model.value),
			});
		});
		this.source.load(items);
	}

	/**
	 * Muestra el diálogo de la pregunta.
	 * @param title Titulo del mensaje.
	 * @param content Contenido del mensaje.
	 * @param textButtonAccept Texto del botón aceptar.
	 * @param textButtonCancel Texto del botón cancelar.
	 */
	 private _showDialogQuestion(title: string, content: string, callback: (result: boolean) => void, textButtonAccept?: string, textButtonCancel?: string): void {
		this._dialogService.open(DialogQuestionComponent, {
			context: {
				title: title,
				message: content,
				textButtonAccept: textButtonAccept,
				textButtonCancel: textButtonCancel,
			},
			autoFocus: false,
			closeOnEsc: true,
			closeOnBackdropClick: false,
		}).onClose.subscribe(result => callback(result));
	}

	/**
	 * Maneja el mensaje que se va a mostrar.
	 * @param type Tipo de mensaje.
	 */
	private _handleMessage(message: any, type: any) {
		this._toastrService.show(message, 'Bonos', {
			status: type,
			duration: 3000,
		});
	}

	/**
	 * Maneja la excepción que se ha generado.
	 */
	private _handleFailure(exception: any): void {
		this.loading = false;
		const mensaje = exception.status > 0 ? exception.error : exception.message;
		this._toastrService.show(mensaje, 'Bonos', {
			status: 'danger',
			duration: 5000,
		});
	}

	/**
	 * Convierte un valor en texto moneda.
	 * @param value Valor a transformar.
	 */
	private toCurrency(value: number): string {
		return `$ ${this._currencyPipe.transform(value, '$', 'symbol-narrow', '1.0-0').replace('$', '').trim()}`;
	}

	/**
	 * Chequea el tamaño del id.
	 */
	private _checkIdSize(control: FormControl): any {
		const value: number = control.value;
		if (!value || value === 0) {
			return {
				idSize: true
			};
		}
		return null;
	}
}
