import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { ConceptDetail } from 'src/app/models/concept-detail';
import { Egress } from 'src/app/models/egress';
import { Turn } from 'src/app/models/turn';

import pdfMake from 'pdfmake/build/pdfmake';
import { PdfAssetsLoader } from 'pdfmake-utils';

import { DialogThirdListComponent } from 'src/app/dialogs/third-list/dialog-third-list.component';

import { NbDialogService, NbToastrService } from '@nebular/theme';
import { BasicService } from 'src/app/services/basic.service';
import { EgressService } from 'src/app/services/egress.service';
import { PrintService } from 'src/app/services/print.service';
import { ThirdService } from 'src/app/services/third.service';

@Component({
	templateUrl: 'dialog-egress.component.html',
	styleUrls: ['dialog-egress.component.scss'],
})
export class DialogEgressComponent implements OnInit {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Variables
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	private _egress: Egress;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	public loading = false;
	public triedAction = false;
	public egressForm: FormGroup;
	public concepts: Array<ConceptDetail>;

	@Input() public egress: Egress;
	@Input() public turn: Turn;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Constructor
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	constructor(private _formBuilder: FormBuilder, protected _dialogRef: NbDialogRef<DialogEgressComponent>,
							private _dialogService: NbDialogService, private _toastrService: NbToastrService,
							private _basicService: BasicService, private _egressService: EgressService,
							private _printService: PrintService, private _thirdService: ThirdService) {
		this._egress = new Egress();
		this.concepts = new Array();
		const assetsLoader = new PdfAssetsLoader();
		assetsLoader.registerFont({name: 'Courier', fileName: 'Courier', URL: 'assets/fonts/Courier.afm', styles: ['normal']});
		assetsLoader.registerFont({name: 'Courier', fileName: 'Courier-Bold', URL: 'assets/fonts/Courier-Bold.afm', styles: ['bold']});
		assetsLoader.load().then(() => {
			assetsLoader.configurePdfMake(pdfMake);
		}).catch((_error: any) => {
			assetsLoader.configurePdfMake(pdfMake);
		});
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Propios
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	ngOnInit(): void {
		this._buildForm();
		this.loading = true;
		this._basicService.findAllConcepts({
			code: 'EGR',
			this: 'activo, exclude',
		}).then(
			models => {
				this.loading = false;
				this.concepts = models;
				if (this.egress != null) {
					setTimeout(() => {
						this._fillForm();
					}, 100);
				}
			},
			exception => this._handleFailure(exception)
		);
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Eventos
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	public onSelectConcept(): void {
		this.egressForm.patchValue({
			third: {
				id: 0,
				fullName: '',
			},
		});
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Principales
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Obtiene el estado del control del formulario.
	 * @param controlName Nombre del control.
	 */
	public getStatus(controlName: string, triedAction?: boolean): string {
		const control = this.egressForm.get(controlName);
		if (!triedAction) {
			return control.dirty ? (control.invalid  ? 'danger' : 'basic') : 'basic';
		} else {
			return control.invalid ? 'danger' : 'basic';
		}
	}

	/**
   * Crea el modelo egreso.
   */
	public createEgress(): void {
		this.triedAction = true;
		if (!this.egressForm.invalid) {
			this.loading = true;
			this._setDataModel();
			this._egressService.create(this._egress).then(
				response => {
					this._printService.printEgress(response, {
						point_id: this.turn.recoverPoint.id,
					}).then(
						reporte => {
							this.loading = false;
							this.triedAction = false;
							if (reporte.length > 0) {
								const documento = {
									content: {
										text: reporte,
										lineHeight: 1.5,
										preserveLeadingSpaces: true,
									},
									defaultStyle: {
										font: 'Courier',
										fontSize: 12,
										bold: true,
									},
									pageMargins: [10, 30, 0, 0],
								};
								pdfMake.createPdf(documento).open();
							}
							if (this.egress == null) {
								this._egress = new Egress();
								this._resetForm();
							} else {
								this.close(true);
							}
							this._handleMessage('Registro exitoso!', 'info');
						},
						exception => this._handleFailure(exception),
					);
				},
				exception => this._handleFailure(exception),
			);
		}
	}

	/**
   * Muestra el diálogo de la lista modelo tercero.
   */
	public showDialogThirds(): void {
		let tipo = '';
		this.loading = true;
		const codConcepto = this.egressForm.get('conceptCode').value;

		if (codConcepto === 'FCA') {
			tipo = 'USU';
		} else if (codConcepto !== 'OTR') {
			tipo = 'CON';
		}
		this._thirdService.findAllBy({
			type: tipo,
			this: 'activo, exclude',
		}).then(
			models => {
				this.loading = false;
				this._dialogService.open(DialogThirdListComponent, {
					context: {
						thirds: models,
					},
					closeOnBackdropClick: false,
				}).onClose.subscribe(model => {
					if (model != null) {
						this._egress.third = model;
						this.egressForm.patchValue({
							third: {
								id: this._egress.third.id,
								fullName: this._egress.third.names + ' ' + this._egress.third.surnames,
							}
						});
					}
				});
			},
			exception => this._handleFailure(exception),
		);
	}

	/**
   * Cierra el diálogo.
   */
	public close(result?: boolean): void {
		this._dialogRef.close(result);
	}

	/**
	 * Indica si no es válido el control del formulario.
	 * @param controlName Nombre del control.
	 */
	public isNotValid(controlName: string, triedAction?: boolean): boolean {
		const control = this.egressForm.get(controlName);
		if (!triedAction) {
			return control.dirty && control.invalid;
		} else {
			return control.invalid;
		}
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Secundarios
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Cambia los datos del modelo egreso.
	 */
	private _setDataModel(): void {
		this._egress.conceptCode = this.egressForm.get('conceptCode').value;
		this._egress.description = this.egressForm.get('description').value;
		this._egress.value = this.egressForm.get('value').value;
		this._egress.turn = this.turn;
	}

	/**
	 * Construye el formulario.
	 */
	private _buildForm(): void {
		this.egressForm = this._formBuilder.group({
			id: 0,
			conceptCode: ['', [Validators.required]],
			description: ['', [Validators.required]],
			value: [0, [Validators.required]],
			third: this._formBuilder.group({
				id: [0, [this._checkIdSize]],
				fullName: ['', [Validators.required]],
			}),
		});
	}

	/**
	 * Llena el formulario.
	 */
	private _fillForm(): void {
		this.egressForm.setValue({
			id: 0,
			conceptCode: this.egress.conceptCode,
			description: '',
			value: this.egress.value,
			third: {
				id: 0,
				fullName: '',
			}
		});
		this.egressForm.get('conceptCode').disable();
		this.egressForm.get('value').disable();
	}

	/**
	 * Reinicia el formulario.
	 */
	private _resetForm(): void {
		this.egressForm.reset({
			id: 0,
			conceptCode: '',
			description: '',
			value: '',
			third: {
				id: 0,
				fullName: '',
			},
		});
	}

	/**
	 * Maneja el mensaje que se va a mostrar.
	 * @param type Tipo de mensaje.
	 */
	private _handleMessage(message: any, type: any) {
		this._toastrService.show(message, 'Egreso', {
			status: type,
			duration: 3000,
		});
	}

	/**
	 * Maneja la excepción que se ha generado.
	 */
	private _handleFailure(exception: any): void {
		this.loading = false;
		const mensaje = exception.status > 0 ? exception.error : exception.message;
		this._toastrService.show(mensaje, 'Egreso', {
			status: 'danger',
			duration: 5000,
		});
	}

	/**
	 * Chequea el tamaño del id.
	 */
	private _checkIdSize(control: FormControl) {
		const value: number = control.value;
		if (!value || value === 0) {
			return {
				idSize: true
			};
		}
		return null;
	}
}
