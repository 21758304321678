import { DatePipe } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { LocalDataSource } from 'ng2-smart-table';
import { Fuel } from 'src/app/models/fuel';

@Component({
	templateUrl: './dialog-fuel-list.component.html',
	styleUrls: ['./dialog-fuel-list.component.scss'],
})
export class DialogFuelListComponent implements OnInit {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	public settings: any;
	public source = new LocalDataSource();

	@Input() public fuels: Array<Fuel>;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Constructor
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	constructor(private _datepipe: DatePipe, protected _dialogRef: NbDialogRef<DialogFuelListComponent>) {
		this.settings = {
			columns: {
				number: {
					title: 'Número',
					type: 'text',
					width: '20%',
				},
				time: {
					title: 'Hora',
					type: 'text',
					width: '10%',
				},
				driverFullName: {
					title: 'Conductor',
					type: 'text',
				},
				vehicleNumber: {
					title: 'Vehiculo',
					type: 'text',
					width: '20%',
				}
			},
			actions: {
				add: false,
				edit: false,
				delete: false,
			},
			attr: {
				class: 'table table-bordered'
			},
			pager: {
				perPage: 10,
			},
			noDataMessage: '',
			defaultStyle: false,
			hideSubHeader: true,
		};
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Propios
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	ngOnInit(): void {
		this._loadData();
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Principales
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
   * Filtra la lista modelo combustible.
   * @param text Texto a buscar.
   */
	public filterFuels(text: string = ''): void {
		this.source.setFilter([
			{
				field: 'number',
				search: text
			},
			{
				field: 'time',
				search: text
			},
			{
				field: 'driverFullName',
				search: text
			},
			{
				field: 'vehicleNumber',
				search: text
			},
		], text.length === 0);
	}

	/**
   * Selecciona el modelo combustible según la fila.
	 * @param row Fila selecionada.
   */
	public selectFuel(row: any): void {
		const fuel = this.fuels.find((model) => model.id === row.data.id);
		this._dialogRef.close(fuel);
	}

	/**
   * Cierra el diálogo.
   */
	public close(): void {
		this._dialogRef.close();
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Secundarios
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Carga la fuente de datos de la tabla.
	 */
	private _loadData(): void {
		const items = Array<any>();
		this.fuels.forEach((model) => {
			items.push({
				id: model.id,
				number: model.number,
				time: this._datepipe.transform(model.dateTime, 'HH:mm').toUpperCase(),
				driverFullName: model.driver.names + ' ' + model.driver.surnames,
				vehicleNumber: model.vehicle.internalNumber + ' - ' + model.vehicle.plaque,
			});
		});
		this.source.load(items);
	}
}
