import { Component, OnInit, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { LocalDataSource } from 'ng2-smart-table';
import { Route } from 'src/app/models/route';

@Component({
	templateUrl: './dialog-route-list.component.html',
	styleUrls: ['./dialog-route-list.component.scss'],
})
export class DialogRouteListComponent implements OnInit {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	public settings: any;
	public source = new LocalDataSource();

	@Input() public routes: Array<Route>;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Constructor
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	constructor(protected _dialogRef: NbDialogRef<DialogRouteListComponent>) {
		this.settings = {
			columns: {
				name: {
					title: 'Nombre',
					type: 'string',
				},
			},
			actions: {
				add: false,
				edit: false,
				delete: false,
			},
			attr: {
				class: 'table table-bordered'
			},
			pager: {
				perPage: 10,
			},
			noDataMessage: '',
			defaultStyle: false,
			hideSubHeader: true,
		};
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Propios
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	ngOnInit(): void {
		this._loadData();
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Principales
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
   * Filtra la lista modelo ruta.
   * @param text Texto a buscar.
   */
	public filterRoutes(text: string = ''): void {
		this.source.setFilter([
			{
				field: 'name',
				search: text
			},
		], text.length === 0);
	}

	/**
   * Selecciona el modelo ruta según la fila.
	 * @param row Fila selecionada.
   */
	public selectRoute(row: any): void {
		const route = this.routes.find((model) => model.id === row.data.id);
		this._dialogRef.close(route);
	}

	/**
   * Cierra el diálogo.
   */
	public close(): void {
		this._dialogRef.close();
	}

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Secundarios
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Carga la fuente de datos de la tabla.
	 */
	private _loadData(): void {
		const items = Array<any>();
		this.routes.forEach((model) => {
			items.push({
				id: model.id,
				name: model.name,
			});
		});
		this.source.load(items);
	}
}
