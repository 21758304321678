import { Serializable, JsonProperty, deserialize, serialize } from 'typescript-json-serializer';
import { BenefitDetail } from './benefit-detail';
import { Driver } from './driver';
import { Turn } from './turn';
import { Vehicle } from './vehicle';

/**
 * Clase que administra los datos del beneficio.
 */
@Serializable()
export class Benefit {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/** Id */
	@JsonProperty('id')
	public id: number;

	/** Número */
	@JsonProperty('numero')
	public number: number;

	/** Fecha y hora */
	@JsonProperty('fecha_hora')
	public dateTime: string;

	/** Fecha y hora de anulación */
	@JsonProperty('fecha_hora_anu')
	public cancelDateTime: string;

	/** Prefijo */
	@JsonProperty('prefijo')
	public prefix: string;

	/** Observación */
	@JsonProperty('observacion')
	public observation: string;

	/** Observación de anulación */
	@JsonProperty('observacion_anu')
	public cancelObservation: string;

	/** Conductor */
	@JsonProperty('conductor')
	public driver: Driver;

	/** Vehículo */
	@JsonProperty('vehiculo')
	public vehicle: Vehicle;

	/** Turno */
	@JsonProperty('turno')
	public turn: Turn;

	/** Turno de anulación */
	@JsonProperty('turno_anu')
	public cancelTurn: Turn;

	/** Lista detalle */
	@JsonProperty({name: 'detalles', type: BenefitDetail})
	public details: Array<BenefitDetail>;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Convierte un mapa json en un objeto de la clase.
	 */
	public static fromJson(json: any): Benefit {
		return deserialize(json, Benefit);
	}

	/**
	 * Convierte el objeto de la clase en un mapa json.
	 */
	public static toJson(model: Benefit): any {
		return serialize(model);
	}
}
