import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { User } from '../models/user';

import { APPLICATION_JSON, URL_HOST } from '../../app';

import { AuthenticationService } from './authentication.service';

@Injectable({
	providedIn: 'root'
})
export class UserService {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Variables
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	private _url = `${URL_HOST}`;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Constructor
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	constructor(private _httpClient: HttpClient, private _authService: AuthenticationService) { }

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Principales
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
   * Busca el modelo usuario según los parámetros.
	 * @param id Id del usuario.
	 * @param params Parámetros de la solicitud.
   */
	public find(id: number, params?: any): Promise<User> {
		return new Promise((resolve, reject) => {
			this._authService.getToken().then(
				token => {
					const httpHeaders = new HttpHeaders({
						'Content-Type': APPLICATION_JSON,
						Authorization: token,
					});
					const httpParams = new HttpParams({
						fromObject: params
					});
					this._httpClient.get<any>(`${this._url}/user/${id}`, {headers: httpHeaders, params: httpParams}).toPromise().then(
						response => resolve(User.fromJson(response)),
						exception => reject(exception)
					);
				},
				error => reject(error)
			);
		});
	}

	/**
   * Busca el modelo usuario.
	 * @param params Parámetros de la solicitud.
   */
	public findBy(params?: any): Promise<User> {
		return new Promise((resolve, reject) => {
			this._authService.getToken().then(
				token => {
					const httpHeaders = new HttpHeaders({
						'Content-Type': APPLICATION_JSON,
						Authorization: token,
					});
					const httpParams = new HttpParams({
						fromObject: params
					});
					this._httpClient.get<any>(`${this._url}/user/by`, {headers: httpHeaders, params: httpParams}).toPromise().then(
						response => resolve(User.fromJson(response)),
						exception => reject(exception)
					);
				},
				error => reject(error)
			);
		});
	}

	/**
	 * Busca la lista modelo usuario.
	 * @param params Parámetros de la solicitud.
	 */
	public findAll(params?: any): Promise<Array<User>> {
		return new Promise((resolve, reject) => {
			this._authService.getToken().then(
				token => {
					const httpHeaders = new HttpHeaders({
						'Content-Type': APPLICATION_JSON,
						Authorization: token,
					});
					const httpParams = new HttpParams({
						fromObject: params
					});
					this._httpClient.get<Array<any>>(`${this._url}/users/all`, {headers: httpHeaders, params: httpParams}).toPromise().then(
						response => resolve(response.map(item => User.fromJson(item))),
						exception => reject(exception)
					);
				},
				error => reject(error)
			);
		});
	}

	/**
	 * Busca la lista modelo usuario.
	 * @param params Parámetros de la solicitud.
	 */
	public findActiveAll(params?: any): Promise<Array<User>> {
		return new Promise((resolve, reject) => {
			this._authService.getToken().then(
				token => {
					const httpHeaders = new HttpHeaders({
						'Content-Type': APPLICATION_JSON,
						Authorization: token
					});
					const httpParams = new HttpParams({
						fromObject: params
					});
					this._httpClient.get<Array<any>>(`${this._url}/users`, {headers: httpHeaders, params: httpParams}).toPromise().then(
						response => resolve(response.map(item => User.fromJson(item))),
						exception => reject(exception)
					);
				},
				error => reject(error)
			);
		});
	}

	/**
	 * Busca la lista modelo usuario.
	 * @param params Parámetros de la solicitud.
	 */
	public findCompanyNotInActiveAll(params?: any): Promise<Array<User>> {
		return new Promise((resolve, reject) => {
			this._authService.getToken().then(
				token => {
					const httpHeaders = new HttpHeaders({
						'Content-Type': APPLICATION_JSON,
						Authorization: token
					});
					const httpParams = new HttpParams({
						fromObject: params
					});
					this._httpClient.get<Array<any>>(`${this._url}/users-not-in-companies`, {headers: httpHeaders, params: httpParams}).toPromise().then(
						response => resolve(response.map(item => User.fromJson(item))),
						exception => reject(exception)
					);
				},
				error => reject(error)
			);
		});
	}

	/**
	 * Busca la lista modelo usuario.
	 * @param params Parámetros de la solicitud.
	 */
	public findRecoverPointNotInActiveAll(params?: any): Promise<Array<User>> {
		return new Promise((resolve, reject) => {
			this._authService.getToken().then(
				token => {
					const httpHeaders = new HttpHeaders({
						'Content-Type': APPLICATION_JSON,
						Authorization: token
					});
					const httpParams = new HttpParams({
						fromObject: params
					});
					this._httpClient.get<Array<any>>(`${this._url}/users-not-in-recover-points`, {headers: httpHeaders, params: httpParams}).toPromise().then(
						response => resolve(response.map(item => User.fromJson(item))),
						exception => reject(exception)
					);
				},
				error => reject(error)
			);
		});
	}

	/**
   * Crea el modelo usuario.
   * @param model Modelo usuario.
   */
	public create(model: User): Promise<number> {
		return new Promise((resolve, reject) => {
			this._authService.getToken().then(
				token => {
					const httpHeaders = new HttpHeaders({
						'Content-Type': APPLICATION_JSON,
						Authorization: token
					});
					this._httpClient.post<any>(`${this._url}/user`, User.toJson(model), {headers: httpHeaders}).toPromise().then(
						response => resolve(response),
						exception => reject(exception)
					);
				}
			);
		});
	}

	/**
   * Actualiza el modelo usuario.
   * @param model Modelo usuario.
   */
	public update(model: User): Promise<number> {
		return new Promise((resolve, reject) => {
			this._authService.getToken().then(
				token => {
					const httpHeaders = new HttpHeaders({
						'Content-Type': APPLICATION_JSON,
						Authorization: token
					});
					this._httpClient.put<any>(`${this._url}/user`, User.toJson(model), {headers: httpHeaders}).toPromise().then(
						response => resolve(response),
						exception => reject(exception)
					);
				}
			);
		});
	}

	/**
   * Actualiza la lista modelo usuario según los parámetros.
   * @param models Lista modelo usuario.
	 * @param params Parámetros de la solicitud.
   */
	public updateAll(models: Array<User>, params?: any): Promise<number> {
		return new Promise((resolve, reject) => {
			this._authService.getToken().then(
				token => {
					const httpHeaders = new HttpHeaders({
						'Content-Type': APPLICATION_JSON,
						Authorization: token
					});
					const httpParams = new HttpParams({
						fromObject: params
					});
					this._httpClient.put<any>(`${this._url}/users`, models.map(model => User.toJson(model)), {headers: httpHeaders, params: httpParams}).toPromise().then(
						response => resolve(response),
						exception => reject(exception)
					);
				},
				error => reject(error)
			);
		});
	}
}
