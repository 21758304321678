import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { URL_HOST, TEXT_PLAIN } from '../../app';

import { AuthenticationService } from './authentication.service';

@Injectable({
	providedIn: 'root'
})
export class PrintService {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Variables
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	private _url = `${URL_HOST}`;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Constructor
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	constructor(private _httpClient: HttpClient, private _authService: AuthenticationService) { }

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos Principales
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Imprime la planilla de viaje según los parámetros.
	 * @param id Id del detalle.
	 * @param params Parámetros de la solicitud.
	 */
	public printBearingDetail(id: number, params?: any): Promise<string> {
		return new Promise((resolve, reject) => {
			this._authService.getToken().then(
				token => {
					const httpHeaders = new HttpHeaders({
						'Content-Type': TEXT_PLAIN,
						Authorization: token
					});
					const httpParams = new HttpParams({
						fromObject: params
					});
					this._httpClient.get<any>(`${this._url}/print-bearing-detail/${id}`, {headers: httpHeaders, params: httpParams, responseType: 'text' as 'json'}).toPromise().then(
						response => resolve(response),
						exception => reject(exception)
					);
				},
				error => reject(error)
			);
		});
	}

	/**
	 * Imprime el cupón de alimentación según los parámetros.
	 * @param id Id del beneficio.
	 * @param params Parámetros de la solicitud.
	 */
	public printBenefit(id: number, params?: any): Promise<any> {
		return new Promise((resolve, reject) => {
			this._authService.getToken().then(
				token => {
					const httpHeaders = new HttpHeaders({
						'Content-Type': TEXT_PLAIN,
						Authorization: token
					});
					const httpParams = new HttpParams({
						fromObject: params
					});
					this._httpClient.get<any>(`${this._url}/print-benefit/${id}`, {headers: httpHeaders, params: httpParams, responseType: 'text' as 'json'}).toPromise().then(
						response => resolve(response),
						exception => reject(exception)
					);
				},
				error => reject(error)
			);
		});
	}

	/**
	 * Imprime el comprobande de egreso según los parámetros.
	 * @param id Id del egreso.
	 * @param params Parámetros de la solicitud.
	 */
	public printEgress(id: number, params?: any): Promise<string> {
		return new Promise((resolve, reject) => {
			this._authService.getToken().then(
				token => {
					const httpHeaders = new HttpHeaders({
						'Content-Type': TEXT_PLAIN,
						Authorization: token
					});
					const httpParams = new HttpParams({
						fromObject: params
					});
					this._httpClient.get<any>(`${this._url}/print-egress/${id}`, {headers: httpHeaders, params: httpParams, responseType: 'text' as 'json'}).toPromise().then(
						response => resolve(response),
						exception => reject(exception)
					);
				},
				error => reject(error)
			);
		});
	}

	/**
	 * Imprime la orde de servicio de combustible según los parámetros.
	 * @param id Id del combustible.
	 * @param params Parámetros de la solicitud.
	 */
	public printFuel(id: number, params?: any): Promise<string> {
		return new Promise((resolve, reject) => {
			this._authService.getToken().then(
				token => {
					const httpHeaders = new HttpHeaders({
						'Content-Type': TEXT_PLAIN,
						Authorization: token
					});
					const httpParams = new HttpParams({
						fromObject: params
					});
					this._httpClient.get<any>(`${this._url}/print-fuel/${id}`, {headers: httpHeaders, params: httpParams, responseType: 'text' as 'json'}).toPromise().then(
						response => resolve(response),
						exception => reject(exception)
					);
				},
				error => reject(error)
			);
		});
	}

	/**
	 * Imprime el recibo de caja según los parámetros.
	 * @param id Id del ingreso.
	 * @param params Parámetros de la solicitud.
	 */
	public printIngress(id: number, params?: any): Promise<string> {
		return new Promise((resolve, reject) => {
			this._authService.getToken().then(
				token => {
					const httpHeaders = new HttpHeaders({
						'Content-Type': TEXT_PLAIN,
						Authorization: token
					});
					const httpParams = new HttpParams({
						fromObject: params
					});
					this._httpClient.get<any>(`${this._url}/print-ingress/${id}`, {headers: httpHeaders, params: httpParams, responseType: 'text' as 'json'}).toPromise().then(
						response => resolve(response),
						exception => reject(exception)
					);
				},
				error => reject(error)
			);
		});
	}

	/**
	 * Imprime el recibo de emisión según los parámetros.
	 * @param id Id de la emisión.
	 * @param params Parámetros de la solicitud.
	 */
	public printIssuance(id: number, params?: any): Promise<string> {
		return new Promise((resolve, reject) => {
			this._authService.getToken().then(
				token => {
					const httpHeaders = new HttpHeaders({
						'Content-Type': TEXT_PLAIN,
						Authorization: token
					});
					const httpParams = new HttpParams({
						fromObject: params
					});
					this._httpClient.get<any>(`${this._url}/print-issuance/${id}`, {headers: httpHeaders, params: httpParams, responseType: 'text' as 'json'}).toPromise().then(
						response => resolve(response),
						exception => reject(exception)
					);
				},
				error => reject(error)
			);
		});
	}

	/**
	 * Imprime el recibo de recaudo según los parámetros.
	 * @param id Id del recaudo.
	 * @param params Parámetros de la solicitud.
	 */
	public printRecover(id: number, params?: any): Promise<string> {
		return new Promise((resolve, reject) => {
			this._authService.getToken().then(
				token => {
					const httpHeaders = new HttpHeaders({
						'Content-Type': TEXT_PLAIN,
						Authorization: token
					});
					const httpParams = new HttpParams({
						fromObject: params
					});
					this._httpClient.get<any>(`${this._url}/print-recover/${id}`, {headers: httpHeaders, params: httpParams, responseType: 'text' as 'json'}).toPromise().then(
						response => resolve(response),
						exception => reject(exception)
					);
				},
				error => reject(error)
			);
		});
	}

	/**
	 * Imprime la venta de tarjeta según los parámetros.
	 * @param id Id de la venta.
	 * @param params Parámetros de la solicitud.
	 */
	public printSaleCard(id: number, params?: any): Promise<string> {
		return new Promise((resolve, reject) => {
			this._authService.getToken().then(
				token => {
					const httpHeaders = new HttpHeaders({
						'Content-Type': TEXT_PLAIN,
						Authorization: token
					});
					const httpParams = new HttpParams({
						fromObject: params
					});
					this._httpClient.get<any>(`${this._url}/print-sale-card/${id}`, {headers: httpHeaders, params: httpParams, responseType: 'text' as 'json'}).toPromise().then(
						response => resolve(response),
						exception => reject(exception)
					);
				},
				error => reject(error)
			);
		});
	}

	/**
	 * Imprime el cierre de turno según los parámetros.
	 * @param id Id del turno.
	 * @param params Parámetros de la solicitud.
	 */
	public printTurn(id: number, params?: any): Promise<string> {
		return new Promise((resolve, reject) => {
			this._authService.getToken().then(
				token => {
					const httpHeaders = new HttpHeaders({
						'Content-Type': TEXT_PLAIN,
						Authorization: token
					});
					const httpParams = new HttpParams({
						fromObject: params
					});
					this._httpClient.get<any>(`${this._url}/print-turn/${id}`, {headers: httpHeaders, params: httpParams, responseType: 'text' as 'json'}).toPromise().then(
						response => resolve(response),
						exception => reject(exception)
					);
				},
				error => reject(error)
			);
		});
	}

	/**
	 * Imprime el cierre de turno según los parámetros.
	 * @param id Id del turno.
	 * @param params Parámetros de la solicitud.
	 */
	 public printTurn2(id: number, params?: any): Promise<string> {
		return new Promise((resolve, reject) => {
			this._authService.getToken().then(
				token => {
					const httpHeaders = new HttpHeaders({
						'Content-Type': TEXT_PLAIN,
						Authorization: token
					});
					const httpParams = new HttpParams({
						fromObject: params
					});
					this._httpClient.get<any>(`${this._url}/print-turn-2/${id}`, {headers: httpHeaders, params: httpParams, responseType: 'text' as 'json'}).toPromise().then(
						response => resolve(response),
						exception => reject(exception)
					);
				},
				error => reject(error)
			);
		});
	}
}
