import { Serializable, JsonProperty, deserialize, serialize } from 'typescript-json-serializer';
import { Line } from './line';

/**
 * Clase que administra los datos del bono.
 */
@Serializable()
export class Voucher {
	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Propiedades
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/** Id */
	@JsonProperty('id')
	public id: number;

	/** Id del recaudo */
	@JsonProperty('id_recaudo')
	public idRecover: number;

	/** Número */
	@JsonProperty('numero')
	public number: number;

	/** Fecha y hora */
	@JsonProperty('fecha_hora')
	public dateTime: string;

	/** Código de barras */
	@JsonProperty('cod_barras')
	public barcode: string;

	/** Prefijo */
	@JsonProperty('prefijo')
	public prefix: string;

	/** Observación */
	@JsonProperty('observacion')
	public observation: string;

	/** Valor */
	@JsonProperty('valor')
	public value: number;

	/** Línea */
	@JsonProperty('linea')
	public line: Line;

	// ------------------------------------------------------------------------------------------------------------------------------------------------------
	// Métodos
	// ------------------------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Convierte un mapa json en un objeto de la clase.
	 */
	public static fromJson(json: any): Voucher {
		return deserialize(json, Voucher);
	}

	/**
	 * Convierte el objeto de la clase en un mapa json.
	 */
	public static toJson(model: Voucher): any {
		return serialize(model);
	}
}
